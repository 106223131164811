import React from 'react';
import Loader from 'react-loader-spinner';
import { Button } from '@mantine/core';

export default function BasicButton({
  primary,
  secondary,
  variant,
  loading,
  style,
  onClick,
  disabled,
  text,
  type,
  color,
  size,
  leftIcon,
}) {
  const loaderConfig = {
    type: 'ThreeDots',
    height: 10,
    width: 10,
    color: !secondary && !primary ? 'var(--primaryColor500)' : '#fff',
  };

  return (
    <Button
      style={style}
      size={size ? size : 'sm'}
      color={color ? color : 'blue'}
      variant={variant ? variant : 'light'}
      onClick={onClick}
      disabled={disabled || loading}
      type={type}
      loading={loading}
      leftIcon={leftIcon}
    >
      {loading ? <Loader {...loaderConfig} /> : text}
    </Button>
  );
}

BasicButton.defaultProps = {
  disabled: false,
  loading: false,
  primary: false,
  secondary: false,
  negative: false,
  text: 'Basic',
  style: {},
  type: 'button',
};
