import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
  font-family: inherit;
  padding: 11px;
  border-radius: 5px;
  font-size: 1em;
  border: 2px solid transparent;
  outline: none;
  margin-bottom: 1em;

  &:focus {
    border-color: var(--primaryColor500);
  }
`;

export default function SearchInput({ value, onChange, placeholder, style }) {
  return (
    <Input
      style={style}
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}

SearchInput.defaultProps = {
  value: '',
  placeholder: 'Start searching...',
};
