import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled(Link)`
  padding: 8px 14px;
  line-height: 1.2;
  border-radius: 4px;
  border: 1px solid var(--primaryColor500);
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  font-family: inherit;
`;

export default function LinkButton({ text, to }) {
  if (!to) return 'no to';

  return <Wrapper to={to}>{text}</Wrapper>;
}

LinkButton.defaultProps = {
  text: 'Link',
  to: '',
};
