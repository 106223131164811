import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaFilm } from 'react-icons/fa';
import { ThemeIcon, Text, Group, Card, Tabs, Flex } from '@mantine/core';
import { OfferingTable } from '@components/Offering';

import {
  ProjectForm,
  ProjectOfferings,
  ProjectAssets,
} from '@components/Project';
import { Title, ViewError } from '@components/shared';

export default function ProjectDetail({ match }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [project, setProject] = useState(populateProject());

  const tabs = [{ text: 'Details' }, { text: 'Offerings' }, { text: 'Assets' }];

  const { id } = match.params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/projects/${id}/`)
      .then(({ data }) => {
        setProject(populateProject(data.response[0]));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  return (
    <div>
      <Title
        page
        text={project.name}
        subtitle={
          <Group mb="xl">
            <ThemeIcon color="cyan">
              <FaFilm size={16} />
            </ThemeIcon>
            <Text ff="Open Sans, sans-serif" size="lg" color="cyan">
              Project
            </Text>
          </Group>
        }
      />
      <Tabs defaultValue={tabs[0].text} variant="default">
        <Tabs.List mb="md">
          {tabs.map((m) => (
            <Tabs.Tab value={m.text} pt="xs" pb="xs">
              <Text ff="Open Sans, sans-serif" size="md" fw="lighter" p={0}>
                {m.text}
              </Text>
            </Tabs.Tab>
          ))}
        </Tabs.List>
        <Tabs.Panel value="Details">
          <ProjectForm
            name={project.name}
            startDate={project.start_date}
            endDate={project.end_date}
            onSuccess={() => fetchData()}
            id={id}
          />
        </Tabs.Panel>
        <Tabs.Panel value="Offerings">
          <ProjectOfferings
            projectId={id}
            offerings={project.offerings}
            onUpdate={() => fetchData()}
          />
        </Tabs.Panel>
        <Tabs.Panel value="Assets">
          <ProjectAssets
            assets={project.assets}
            fetchData={() => fetchData()}
            projectId={id}
          />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}

function populateProject(d = {}) {
  return {
    name: d.name || '',
    start_date: d.start_date || null,
    end_date: d.end_date || null,
    offerings: d.offerings || [],
    orders: d.orders || [],
    assets: d.assets || [],
  };
}
