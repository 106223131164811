import React from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { FaRegThumbsUp, FaRegThumbsDown } from 'react-icons/fa';

import { ApprovalIcon } from '@components/Offering';
import DecisionButtons from './DecisionButtons';
import { ColorBlock } from '@components/shared';
import { penniesToDollars } from '@util/formatters';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .meta {
    text-align: center;

    h5 {
      margin: 0;
    }

    h2 {
      margin: 5px 0;
    }
  }

  .content {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .variety {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      margin-bottom: 1em;

      .title {
        margin: 0;
      }

      svg {
        margin-right: 8px;
        font-size: 18px;
      }
    }

    .text-variety {
      font-size: 1.85em;
    }
  }

  .decision-info {
    padding: 1em 0;
    text-align: center;
    margin-bottom: 1.25em;

    svg {
      font-size: 24px;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.15em;
    }
  }

  .decision-btns {
    button {
      width: 50%;
    }
  }
`;

export default function ActiveItem({
  answeredAt,
  approvalId,
  approved,
  approvalItem,
  itemName,
  onDecisionSuccess,
  rejectionReason,
  required,
}) {
  if (!approvalItem) return <p>Please continue.</p>;

  function onDecisionClick(approvalInfo) {
    const req = {
      ...approvalInfo,
      approval_id: approvalId,
    };

    axios
      .post(`/approval/${approvalId}/decision/`, req)
      .then(() => {
        toast.success('Saved!');
        onDecisionSuccess();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <Wrapper>
      <div className="meta">
        <h2>{itemName}</h2>
        <small>your response is {required ? 'required' : 'appreciated'}</small>
      </div>
      <div className="content">
        <div className="variety">
          <ApprovalIcon
            variety={approvalItem.variety}
            color="var(--primaryColor500)"
          />
          <h3 className="title">{approvalItem.variety_formatted}</h3>
        </div>
        <React.Fragment>
          {[4, 5].includes(approvalItem.variety) && (
            <React.Fragment>
              {approvalItem.assets.map((asset) => (
                <img key={approvalId} src={asset.filename_url} />
              ))}
            </React.Fragment>
          )}
          {approvalItem.variety === 1 && (
            <React.Fragment>
              <ColorBlock
                height={`250px`}
                width={`100%`}
                value={approvalItem.color_value}
              />
              <p style={{ marginTop: '1em' }}>
                <b>{approvalItem.color}</b>
              </p>
            </React.Fragment>
          )}
          {[2, 3, 6].includes(approvalItem.variety) && (
            <React.Fragment>
              <p className="text-variety">
                {approvalItem.variety === 2 && `${approvalItem.quantity}`}
                {approvalItem.variety === 3 &&
                  `$${penniesToDollars(approvalItem.price_in_pennies)}`}
                {approvalItem.variety === 6 && `${approvalItem.description}`}
              </p>
            </React.Fragment>
          )}
        </React.Fragment>
      </div>
      {answeredAt && (
        <div className="decision-info">
          {approved ? (
            <FaRegThumbsUp color="var(--primaryColor500)" />
          ) : (
            <FaRegThumbsDown color="var(--negative)" />
          )}
          <p>
            <b>{approved ? 'approved' : 'rejected'}</b>{' '}
            {new Date(answeredAt).toLocaleString()}
          </p>
          {!approved && rejectionReason && <p>{rejectionReason}</p>}
        </div>
      )}
      {!answeredAt && (
        <div className="decision-btns">
          <DecisionButtons
            onConfirm={() => onDecisionClick({ approve: true })}
            onReject={(e) =>
              onDecisionClick({ approve: false, rejection_reason: e })
            }
          />
        </div>
      )}
    </Wrapper>
  );
}

ActiveItem.defaultProps = {
  approvalItem: null,
  required: false,
};
