import React from 'react';
import Fade from 'react-reveal/Fade';
import './index.scss';

export default function Message({ error, success }) {
  return (
    <Fade top>
      <div className="service-message">
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
      </div>
    </Fade>
  );
}
