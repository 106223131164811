import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Progress } from 'semantic-ui-react';
import { Icon } from '@components/shared';
import { Card, Group, Text } from '@mantine/core';

export default function OfferingChecklist({ path, checklistData }) {
  if (!checklistData) return null;

  const items = [
    {
      text: 'Add approval manager',
      complete: checklistData.has_approval_manager,
      url: `${path}`,
    },
    {
      text: 'Add offering manager',
      complete: checklistData.has_offering_manager,
      url: `${path}`,
    },
    {
      text: 'Add item',
      complete: checklistData.has_item,
      url: `${path}/items`,
    },
    {
      text: 'Add things to approve',
      complete: checklistData.has_approval_items,
      url: `${path}/items`,
    },
    {
      text: 'Assign approval responsibilities',
      complete: checklistData.has_approval_responsibilties,
      url: `${path}`,
    },
  ].map((m, i) => ({ ...m, key: i + 1 }));

  const getPercent =
    100 * (items.filter((f) => f.complete).length / items.length);

  function getColor() {
    if (getPercent < 20) {
      return 'grey';
    } else if (getPercent < 40) {
      return 'red';
    } else if (getPercent < 60) {
      return 'yellow';
    } else if (getPercent < 80) {
      return 'blue';
    } else if (getPercent < 100) {
      return 'teal';
    } else {
      return 'green';
    }
  }

  return (
    <div>
      <Progress
        percent={getPercent}
        size="small"
        color={getColor()}
        label="Pre-Approval Progress"
      />
      <Group spacing="xs">
        {items.map((item) => (
          <Card key={item.key} radius={9} p="sm">
            <Link to={item.url}>
              <Group>
                <Icon.Check
                  size={18}
                  color={item.complete ? 'dodgerblue' : '#000'}
                  style={{ opacity: item.complete ? 1 : 0.15 }}
                />
                <Text
                  ff="Open Sans, sans-serif"
                  fw="bold"
                  size="md"
                  color="#333"
                >
                  {item.text}
                </Text>
              </Group>
            </Link>
          </Card>
        ))}
      </Group>
    </div>
  );
}

OfferingChecklist.defaultProps = {
  checklistData: {
    has_approval_manager: false,
    has_offering_manager: false,
    has_item: false,
    has_approval_items: false,
    has_approval_responsibilties: false,
  },
};

const StyledChecklist = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    font-size: 1.05em;
    background: #eee;
    border-radius: 8px;
    border: 1px solid #e7e3e3;
    padding: 8px 8px;

    a {
      color: inherit;
      /* color: #fff; */
      font-weight: bold;
    }

    svg {
      margin-right: 8px;
    }

    & + li {
      margin-left: 1em;
    }
  }
`;
