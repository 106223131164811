import { fileParams } from './authInfo';

const imageWidths = {
  full: 800,
  medium: 400,
  small: 150,
};

function buildSrcSet(image) {
  return `${image.thumb_filename}${fileParams} ${imageWidths.small}w, ${image.medium_filename}${fileParams} ${imageWidths.medium}w`;
  // return `/api/assets/${image.thumb_filename}${fileParams} ${imageWidths.small}w, /api/assets/${image.medium_filename}${fileParams} ${imageWidths.medium}w`;
}

function buildSizes() {
  return `(max-width: 600px) ${imageWidths.small}px, ${imageWidths.medium}px`;
}

export { imageWidths, buildSizes, buildSrcSet };
