import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import axios from 'axios';
import toast from 'react-hot-toast';

import { CurrencyInput } from '@components/shared';
import { BasicButton } from '@components/Button';

import { dollarsToPennies } from '@util/formatters';
import { varieties } from './approvalItemOptions';

import {
  formattedAlignments,
  formattedLocations,
  formattedImprints,
} from '@components/Placement/helpers';

export default function ApprovalItemForm({
  color,
  description,
  id,
  offeringItemId,
  onSuccess,
  placementData,
  price,
  quantity,
  variety,
}) {
  const [varietyValue, setVarietyValue] = useState(null);
  const [descriptionValue, setDescriptionValue] = useState('');
  const [colorValue, setColorValue] = useState('');
  const [priceValue, setPriceValue] = useState('');
  const [quantityValue, setQuantityValue] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState(null);
  const [alignments, setAlignments] = useState([]);
  const [imprint, setImprint] = useState(null);
  const [dimensions, setDimensions] = useState('');

  console.log({ varietyValue: varietyValue, variety: variety });

  useEffect(() => {
    setVarietyValue(variety);
  }, [variety]);

  useEffect(() => {
    setColorValue(color);
  }, [color]);

  useEffect(() => {
    setDescriptionValue(description);
  }, [description]);

  useEffect(() => {
    setQuantityValue(quantity);
  }, [quantity]);

  useEffect(() => {
    setPriceValue(price);
  }, [price]);

  function onSaveClick() {
    if ([varieties.mockup, varieties.art].includes(varietyValue)) {
      return onAssetUpload();
    }

    const req = {
      offering_item_id: offeringItemId,
      price_in_pennies: dollarsToPennies(priceValue),
      description: descriptionValue,
      color: colorValue,
      quantity: quantityValue,
      variety: varietyValue,
    };

    if (id) {
      return onUpdate(req);
    }

    axios
      .post(`/approval-items/`, req)
      .then(() => {
        onSuccess();
        toast.success('Created!');
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onAssetUpload() {
    setLoading(true);

    const req = {
      offering_item_id: offeringItemId,
      variety: varietyValue,
    };

    if (location) {
      req.placement_data = JSON.stringify({
        location,
        alignments,
        dimensions,
        imprint,
      });
    }

    axios
      .post(`/approval-items/`, req)
      .then(({ data }) => {
        uploadAsset(data.response[0].id);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function uploadAsset(newApprovalItemId) {
    if (!newApprovalItemId) return toast.error('No approval item id!');
    const formData = new FormData();
    formData.append('tempfile', file);
    formData.append('variety', varietyValue);
    formData.append('approval_item_id', newApprovalItemId);

    if (location) {
      formData.append(
        'placement_data',
        JSON.stringify({ location, alignments, dimensions, imprint }),
      );
    }

    axios
      .post(`/asset-upload/`, formData)
      .then(() => {
        onSuccess();
        setLoading(false);
        toast.success('Added!');
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onUpdate(reqInfo) {
    const req = {
      ...reqInfo,
      id,
    };

    axios
      .put(`/approval-items/${id}/`, req)
      .then(() => {
        onSuccess();
        toast.success('Updated!');
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  const { items: placements } = placementData;

  const locationOptions = placements
    ? placements.map((m) => ({
        text: formattedLocations[m.location - 1],
        value: m.location,
      }))
    : [];

  const imprintOptions = placements
    ? placements.map((m) => ({
        text: formattedImprints[m.imprint - 1],
        value: m.imprint,
      }))
    : [];

  const alignmentOptions =
    location &&
    placements &&
    placements.find((f) => f.location === location && f.imprint === imprint)
      ? placements
          .find((f) => f.location === location)
          .alignments.map((m) => ({
            value: m,
            text: formattedAlignments[m - 1],
          }))
      : [];

  return (
    <Form>
      {varietyValue === varieties.art && (
        <React.Fragment>
          <Form.Select
            label="Imprint"
            placeholder="Select one..."
            options={imprintOptions}
            onChange={(e, d) => {
              setImprint(d.value);
              setAlignments([]);
            }}
            value={imprint}
            search
          />
          <Form.Select
            label="Location"
            placeholder="Select one..."
            options={locationOptions}
            onChange={(e, d) => {
              setLocation(d.value);
              setAlignments([]);
            }}
            value={location}
            search
          />
          <Form.Select
            label="Alignment"
            search
            multiple
            placeholder="Select one..."
            options={alignmentOptions}
            onChange={(e, d) => setAlignments(d.value)}
            value={alignments}
          />
          <Form.TextArea
            label="Dimensions"
            placeholder="Enter dimensions..."
            onChange={(e, d) => {
              setDimensions(d.value);
            }}
            value={dimensions}
          />
        </React.Fragment>
      )}
      {varietyValue === varieties.other && (
        <Form.TextArea
          label="Description"
          value={descriptionValue}
          onChange={(e) => setDescriptionValue(e.target.value)}
        />
      )}
      {varietyValue === varieties.item_color && (
        <Form.Input
          label="Color"
          value={colorValue}
          onChange={(e) => setColorValue(e.target.value)}
        />
      )}
      {varietyValue === varieties.imprint_color && (
        <Form.Input
          label="Color"
          value={colorValue}
          onChange={(e) => setColorValue(e.target.value)}
        />
      )}
      {varietyValue === varieties.quantity && (
        <Form.Input
          label="Minimum agreed to quantity"
          value={quantityValue}
          onChange={(e) => setQuantityValue(e.target.value)}
        />
      )}
      {[varieties.art, varieties.mockup].includes(varietyValue) && (
        <div style={{ marginBottom: '1em' }}>
          <AssetUploader onChange={(e) => setFile(e)} />
        </div>
      )}
      <BasicButton
        text="Save"
        primary
        loading={loading}
        onClick={onSaveClick}
        disabled={
          loading ||
          !varietyValue ||
          (variety === varieties.art && !location) ||
          (variety === varieties.art && alignments.length === 0) ||
          (variety === varieties.art && !imprint) ||
          (variety === varieties.art && !dimensions) ||
          (variety === varieties.price && !priceValue) ||
          (varietyValue === varieties.other && !descriptionValue) ||
          (varietyValue === varieties.color && !colorValue) ||
          (varietyValue === varieties.quantity && !quantityValue) ||
          ([varieties.art, varieties.mockup].includes(varietyValue) && !file)
        }
      />
    </Form>
  );
}

ApprovalItemForm.defaultProps = {
  color: '',
  description: '',
  id: null,
  offeringItemId: null,
  placementData: {
    items: [],
  },
  price: '',
  quantity: '',
  variety: null,
};

const AssetUploader = ({ onChange }) => {
  return (
    <Form.Field>
      <label>File</label>
      <input type="file" onChange={(e) => onChange(e.target.files[0])} />
    </Form.Field>
  );
};
