import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Flex, Group } from '@mantine/core';

import { SearchInput } from '@components/shared';
import { CustomerTable } from './';

export default function CustomerSearch() {
  const [loading, setLoading] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    fetchCustomers();
  }, [searchValue]);

  function fetchCustomers() {
    setLoading(true);

    const cancel = cancelRef.current;
    if (cancel) {
      cancel();
    }

    axios
      .post(`/retrieve-users/`, { search_value: searchValue })
      .then(({ data }) => {
        setLoading(false);
        setCustomers(data.response);
      })
      .catch((err) => {
        setLoading(false);
        setCustomers([]);
      });
  }

  return (
    <Flex direction="column">
      <Group w={100} mb="xs">
        <SearchField value={searchValue} onChange={(e) => setSearchValue(e)} />
      </Group>
      <CustomerTable data={customers} loading={loading} />
    </Flex>
  );
}

const SearchField = ({ value, onChange }) => {
  return <SearchInput value={value} onChange={(e) => onChange(e)} />;
};
