import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'semantic-ui-react';

import AddressForm from './AddressForm';
import AddressNameplate from './AddressNameplate';
import { BasicButton } from '@components/Button';
import { Flex } from '@mantine/core';

export default function AddressManagement({
  userId,
  vendorId,
  manufacturerId,
  distributorId,
  addresses,
  title,
  onUpdate,
}) {
  const [items, setItems] = useState([]);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setItems(addresses.filter((f) => f.status !== 2 && f.status !== 3));
  }, [JSON.stringify(addresses)]);

  function onClose() {
    setOpen(false);
  }

  const formattedItems = items
    .filter((f) => f.status === 1)
    .sort((a, b) => b.primary - a.primary);

  return (
    <div>
      <BasicButton
        text="Add Address"
        onClick={() => setOpen(true)}
        primary
        color="green"
        variant="filled"
      />
      <Flex columns={3} mt="lg" direction="column">
        {formattedItems.map((m) => (
          <AddressNameplate
            id={m.id}
            key={m.id}
            vendorId={m.vendor_id}
            userId={m.user_id}
            manufacturerId={m.manufacturer_id}
            distributorId={m.distributor_id}
            address1={m.address_1}
            address2={m.address_2}
            city={m.city}
            state={m.state}
            zip={m.zip}
            country={m.country}
            onSuccess={() => onUpdate()}
            fullAddress={m.full_address}
            createdAt={m.created_at}
            entityName={m.entity_name}
            variety={m.variety}
            varietyFormatted={m.variety_formatted}
            deletable
            onUpdate={() => onUpdate()}
          />
        ))}
      </Flex>
      <div style={{ marginTop: '2em' }}>
        <BasicButton text="Add Address" onClick={() => setOpen(true)} primary />
      </div>
      <Modal open={isOpen} onClose={onClose} size="large">
        <Modal.Header>New Address</Modal.Header>
        <Modal.Content style={{ padding: '55px' }}>
          <AddressForm
            userId={userId}
            vendorId={vendorId}
            onSuccess={() => {
              setOpen(false);
              onUpdate();
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button content="Close" basic size="small" onClick={onClose} />
        </Modal.Actions>
      </Modal>
    </div>
  );
}

AddressManagement.defaultProps = {
  userId: null,
  vendorId: null,
  addresses: [],
  title: 'Addresss',
};

const alignStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 20px',
  cursor: 'pointer',
  fontSize: '16px',
  fontWeight: 'bold',
};
