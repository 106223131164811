import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Yup from 'yup';

import { BasicForm } from '@components/shared';

const countries = [
  { text: 'United States of America', value: 'US' },
  { text: 'Canada', value: 'CA' },
];

const varieties = [
  { value: 1, text: 'shipping' },
  { value: 2, text: 'mailing' },
  { value: 3, text: 'invoice' },
  { value: 4, text: 'business' },
];

const usStates = [
  {
    text: 'Alabama',
    value: 'AL',
    key: 'AL',
  },
  {
    text: 'Alaska',
    value: 'AK',
    key: 'AK',
  },
  {
    text: 'Arizona',
    value: 'AZ',
    key: 'AZ',
  },
  {
    text: 'Arkansas',
    value: 'AR',
    key: 'AR',
  },
  {
    text: 'California',
    value: 'CA',
    key: 'CA',
  },
  {
    text: 'Colorado',
    value: 'CO',
    key: 'CO',
  },
  {
    text: 'Connecticut',
    value: 'CT',
    key: 'CT',
  },
  {
    text: 'Delaware',
    value: 'DE',
    key: 'DE',
  },
  {
    text: 'Florida',
    value: 'FL',
    key: 'FL',
  },
  {
    text: 'Georgia',
    value: 'GA',
    key: 'GA',
  },
  {
    text: 'Hawaii',
    value: 'HI',
    key: 'HI',
  },
  {
    text: 'Idaho',
    value: 'ID',
    key: 'ID',
  },
  {
    text: 'Illinois',
    value: 'IL',
    key: 'IL',
  },
  {
    text: 'Indiana',
    value: 'IN',
    key: 'IN',
  },
  {
    text: 'Iowa',
    value: 'IA',
    key: 'IA',
  },
  {
    text: 'Kansas',
    value: 'KS',
    key: 'KS',
  },
  {
    text: 'Kentucky',
    value: 'KY',
    key: 'KY',
  },
  {
    text: 'Louisiana',
    value: 'LA',
    key: 'LA',
  },
  {
    text: 'Maine',
    value: 'ME',
    key: 'ME',
  },
  {
    text: 'Maryland',
    value: 'MD',
    key: 'MD',
  },
  {
    text: 'Massachusetts',
    value: 'MA',
    key: 'MA',
  },
  {
    text: 'Michigan',
    value: 'MI',
    key: 'MI',
  },
  {
    text: 'Minnesota',
    value: 'MN',
    key: 'MN',
  },
  {
    text: 'Mississippi',
    value: 'MS',
    key: 'MS',
  },
  {
    text: 'Missouri',
    value: 'MO',
    key: 'MO',
  },
  {
    text: 'Montana',
    value: 'MT',
    key: 'MT',
  },
  {
    text: 'Nebraska',
    value: 'NE',
    key: 'NE',
  },
  {
    text: 'Nevada',
    value: 'NV',
    key: 'NV',
  },
  {
    text: 'New Hampshire',
    value: 'NH',
    key: 'NH',
  },
  {
    text: 'New Jersey',
    value: 'NJ',
    key: 'NJ',
  },
  {
    text: 'New Mexico',
    value: 'NM',
    key: 'NM',
  },
  {
    text: 'New York',
    value: 'NY',
    key: 'NY',
  },
  {
    text: 'North Carolina',
    value: 'NC',
    key: 'NC',
  },
  {
    text: 'North Dakota',
    value: 'ND',
    key: 'ND',
  },
  {
    text: 'Ohio',
    value: 'OH',
    key: 'OH',
  },
  {
    text: 'Oklahoma',
    value: 'OK',
    key: 'OK',
  },
  {
    text: 'Oregon',
    value: 'OR',
    key: 'OR',
  },
  {
    text: 'Pennsylvania',
    value: 'PA',
    key: 'PA',
  },
  {
    text: 'Rhode Island',
    value: 'RI',
    key: 'RI',
  },
  {
    text: 'South Carolina',
    value: 'SC',
    key: 'SC',
  },
  {
    text: 'South Dakota',
    value: 'SD',
    key: 'SD',
  },
  {
    text: 'Tennessee',
    value: 'TN',
    key: 'TN',
  },
  {
    text: 'Texas',
    value: 'TX',
    key: 'TX',
  },
  {
    text: 'Utah',
    value: 'UT',
    key: 'UT',
  },
  {
    text: 'Vermont',
    value: 'VT',
    key: 'VT',
  },
  {
    text: 'Virginia',
    value: 'VA',
    key: 'VA',
  },
  {
    text: 'Washington',
    value: 'WA',
    key: 'WA',
  },
  {
    text: 'West Virginia',
    value: 'WV',
    key: 'WV',
  },
  {
    text: 'Wisconsin',
    value: 'WI',
    key: 'WI',
  },
  {
    text: 'Wyoming',
    value: 'WY',
    key: 'WY',
  },
  {
    text: 'Alberta',
    value: 'AB',
  },
  {
    text: 'British Columbia',
    value: 'BC',
  },
  {
    text: 'Manitoba',
    value: 'MB',
  },
  {
    text: 'New Brunswick',
    value: 'NB',
  },
  {
    text: 'Newfoundland and Labrador',
    value: 'NL',
  },
  {
    text: 'Nova Scotia',
    value: 'NS',
  },
  {
    text: 'Ontario',
    value: 'ON',
  },
  {
    text: 'Prince Edward Island',
    value: 'PE',
  },
  {
    text: 'Quebec',
    value: 'QC',
  },
  {
    text: 'Saskatchewan',
    value: 'SK',
  },
].map((m) => ({ ...m, key: m.value }));

export default function AddressForm({
  id,
  onSuccess,
  vendorId,
  userId,
  manufacturerId,
  distributorId,
  address1,
  address2,
  city,
  state,
  zip,
  country,
  variety,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fields = [
    {
      name: 'address_1',
      label: 'Address',
      initialValue: address1,
      addressLookup: true,
      required: true,
      schema: () => Yup.string().required('Required!'),
    },
    {
      name: 'address_2',
      label: 'Address Line 2 (Ste, Apt, etc.)',
      initialValue: address2,
      schema: () => null,
    },
    {
      name: 'city',
      label: 'City',
      initialValue: city,
      required: true,
      schema: () => Yup.string().required('Required!'),
    },
    {
      name: 'state_province',
      label: 'State/Province',
      initialValue: state,
      select: true,
      options: usStates,
      required: true,
      schema: () => Yup.string().nullable().required('Required!'),
    },
    {
      name: 'zip',
      label: 'Zip/Postal Code',
      initialValue: zip,
      required: true,
      schema: () => Yup.string().required('Required!'),
    },
    {
      name: 'country',
      label: 'Country',
      initialValue: country,
      select: true,
      options: countries,
      required: true,
      schema: () => Yup.string().nullable().required('Required!'),
    },
    {
      name: 'variety',
      label: 'Variety',
      initialValue: variety,
      select: true,
      options: varieties,
      required: true,
      schema: () => Yup.string().nullable().required('Required!'),
    },
  ];

  function onSubmit(formData) {
    setError(null);
    setLoading(true);

    const formatted = {
      ...formData,
    };

    if (vendorId) {
      formatted.vendor_id = vendorId;
    }

    if (userId) {
      formatted.user_id = userId;
    }

    if (distributorId) {
      formatted.distributor_id = distributorId;
    }

    if (manufacturerId) {
      formatted.manufacturer_id = manufacturerId;
    }

    if (id) return onUpdate(formatted);

    return onCreate(formatted);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
    };

    axios
      .post(`/addresses/`, req)
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      id,
    };

    axios
      .put(`/addresses/${id}/`, req)
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  return (
    <BasicForm
      error={error}
      fields={fields}
      loading={loading}
      onSubmit={onSubmit}
    />
  );
}

AddressForm.defaultProps = {
  vendorId: null,
  userId: null,
  id: null,
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
};
