import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Switch, Route } from 'react-router-dom';
import { FaShoppingCart } from 'react-icons/fa';
import { ThemeIcon, Text, Group, Card, Tabs, Flex } from '@mantine/core';
import toast from 'react-hot-toast';

import { FaExclamationTriangle } from 'react-icons/fa';
import { BasicButton } from '@components/Button';
import { ItemList } from '@components/Item';
import { VendorForm, PartnerAssets } from '@components/Partner';
import {
  Icon,
  ContactManagement,
  Title,
  ViewError,
  AddressManagement,
} from '@components/shared';

export default function VendorDetail({ match }) {
  const [vendor, setVendor] = useState(populateVendor());
  const [error, setError] = useState(null);

  const { id } = match.params;

  const tabs = [
    { text: 'Details' },
    { text: 'Contacts' },
    { text: 'Street Address' },
    { text: 'Items' },
    { text: 'Pricing' },
    { text: 'Catalog' },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/vendors/${id}/`)
      .then(({ data }) => {
        setVendor(populateVendor(data.response[0]));
      })
      .catch((err) => {
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  function onDelete() {
    axios
      .get(`/vendors/${id}/toggle-status/`)
      .then(() => {
        toast.success('Deleted!');
        window.location = '/partners';
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <div p="lg">
      <Title
        page
        text={vendor.name}
        subtitle={
          <Group mb="xl">
            <ThemeIcon color="indigo" size="lg" p={2}>
              <FaShoppingCart size={22} />
            </ThemeIcon>
            <Text
              ff="Open Sans, sans-serif"
              fw="lighter"
              size="lg"
              color="indigo"
            >
              Vendor
            </Text>
            {/* <ThemeIcon color="red">
              <FaExclamationTriangle />
            </ThemeIcon> */}
          </Group>
        }
        icon={<Icon.Vendor />}
      />
      <Tabs defaultValue={tabs[0].text} variant="default">
        <Tabs.List mb="md">
          {tabs.map((m) => (
            <Tabs.Tab value={m.text} pt="xs" pb="xs">
              <Text ff="Open Sans, sans-serif" fw="lighter" size="md" p={0}>
                {m.text}
              </Text>
            </Tabs.Tab>
          ))}
        </Tabs.List>
        <Tabs.Panel value="Details">
          <VendorForm
            id={id}
            name={vendor.name}
            variety={vendor.variety}
            websiteUrl={vendor.website_url}
            varietyFormatted={vendor.variety_formatted}
            onSuccess={fetchData}
          />
          <BasicButton
            variant="subtle"
            color="red"
            text="Delete this vendor"
            onClick={() => onDelete()}
          />
        </Tabs.Panel>
        <Tabs.Panel value="Contacts">
          <ContactManagement
            contacts={vendor.contact_methods}
            vendorId={vendor.id}
            onUpdate={() => fetchData()}
          />
        </Tabs.Panel>
        <Tabs.Panel value="Street Address">
          <AddressManagement
            addresses={vendor.addresses}
            vendorId={vendor.id}
            onUpdate={fetchData}
          />
        </Tabs.Panel>
        <Tabs.Panel value="Items">
          <React.Fragment>
            <ItemList items={vendor.items.filter((f) => f.status === 1)} />
          </React.Fragment>
        </Tabs.Panel>
        <Tabs.Panel value="Pricing">
          <React.Fragment>
            <PartnerAssets
              title="Pricing"
              fetchData={() => fetchData()}
              assets={vendor.assets.filter((f) => f.variety === 9)}
              vendorId={vendor.id}
              variety={9}
            />
          </React.Fragment>
        </Tabs.Panel>
        <Tabs.Panel value="Catalog">
          <React.Fragment>
            <PartnerAssets
              title="Catalog"
              fetchData={() => fetchData()}
              assets={vendor.assets.filter((f) => f.variety === 10)}
              vendorId={vendor.id}
              variety={10}
            />
          </React.Fragment>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}

function populateVendor(d = {}) {
  return {
    id: d.id || null,
    name: d.name || '',
    items: d.items ? d.items : [],
    status: d.status || null,
    variety: d.variety || null,
    variety_formatted: d.variety_formatted || null,
    contact_methods: d.contact_methods || [],
    addresses: d.addresses || [],
    website_url: d.website_url || '',
    assets: d.assets || [],
  };
}
