import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import './index.scss';
import { BasicForm } from '@components/shared';

export default function UpdatePasswordForm({ onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setError(null);
    }, 5000);
  }, [error]);

  function onPasswordUpdate(formData) {
    setLoading(true);

    let postData = {
      ...formData,
    };

    axios
      .post(`/admin-change-password/`, postData)
      .then(() => setLoading(false))
      .then(() => onSuccess())
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  let fields = [
    {
      name: 'password',
      label: 'New Password',
      required: true,
      password: true,
      initialValue: '',
      schema: () => Yup.string().required('Required'),
    },
    {
      name: 'confirmed_password',
      label: 'Confirm New Password',
      initialValue: '',
      password: true,
      required: true,
      schema: () => Yup.string().required('Required'),
    },
  ];

  return (
    <div>
      <BasicForm
        error={error}
        fields={fields}
        loading={loading}
        onSubmit={onPasswordUpdate}
      />
    </div>
  );
}

UpdatePasswordForm.defaultProps = {};
