import React, { useState } from 'react';
import axios from 'axios';

import { SimpleGrid, Text, Form, Card, TextInput, Button } from '@mantine/core';

import { Modal } from 'semantic-ui-react';

import { Title } from '@components/shared';
import { BasicButton } from '@components/Button';

export default function SizeTagList({
  sizeTags,
  itemId,
  onUpdate,
  currentSizes,
  hideTitle,
  viewOnly,
  offeringItemId,
}) {
  const [selectedSize, setSelectedSize] = useState({
    id: null,
    name: null,
  });
  const [quantity, setQuantity] = useState(null);
  const [open, setOpen] = useState(false);

  const currentSizeIds = currentSizes.filter((f) => f.tag).map((s) => s.tag.id);

  function onClick(size) {
    if (viewOnly) return;
    if (offeringItemId) {
      setOpen(true);
      setSelectedSize(size);
    } else {
      const req = {
        tag_id: size.id,
        item_id: itemId,
      };

      axios
        .post(`/items/${itemId}/sizes/`, req)
        .then(() => {
          onUpdate();
        })
        .catch((err) => {});
    }
  }

  function setSizeQuantity() {
    const req = {
      quantity: quantity,
      size: selectedSize.name,
      offering_item_id: offeringItemId,
      variety: 2,
    };

    axios
      .post(`/approval-items/`, req)
      .then(() => {
        onUpdate();
      })
      .catch((err) => {});
  }

  function onClose() {
    setSelectedSize({
      id: null,
      name: null,
    });
    setOpen(false);
    setQuantity(null);
  }

  return (
    <div>
      {!hideTitle && <Title text="Sizes" />}
      <SimpleGrid cols={3} spacing="xs" verticalSpacing={9}>
        {sizeTags.map((s) => (
          <BasicButton
            text={
              <Text ff="Open Sans, sans-serif" size="xl">
                {s.name}
              </Text>
            }
            key={s.id}
            onClick={() => onClick(s)}
            color={selectedSize.id === s.id ? 'green' : 'orange'}
            disabled={currentSizeIds.includes(s.id) ? false : true}
            variant={currentSizeIds.includes(s.id) ? 'filled' : 'light'}
          />
        ))}
      </SimpleGrid>
      <Modal size="small" onClose={onClose} open={open}>
        <Modal.Content>
          <Text>{selectedSize.name}</Text>
          <TextInput
            placeholder="Quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
          <Button
            primary
            onClick={() => {
              setSizeQuantity();
              onClose();
            }}
            style={{ marginTop: '1em' }}
          >
            Confirm
          </Button>
        </Modal.Content>
      </Modal>
    </div>
  );
}

SizeTagList.defaultProps = {
  hideTitle: false,
  taggings: [],
  viewOnly: false,
};
