import React, { useState } from 'react';
import { Flex, Title, Group, ActionIcon } from '@mantine/core';

import { ProjectForm, ProjectSearch } from '@components/Project';
import { FormWrapper, Icon } from '@components/shared';

export default function ProjectIndex({ history }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <Flex direction="column">
      <Group mb="md">
        <Title ff="Open Sans, sans-serif" size={35} fw="lighter">
          Projects
        </Title>
        <ActionIcon
          variant="transparent"
          onClick={() => setOpen(!isOpen)}
          isOpen={isOpen}
        >
          <Icon.Create />
        </ActionIcon>
      </Group>
      {!isOpen && <ProjectSearch />}
      {isOpen && (
        <FormWrapper>
          <ProjectForm
            onSuccess={(id) => {
              setOpen(false);
              history.push(`/projects/${id}`);
            }}
          />
        </FormWrapper>
      )}
    </Flex>
  );
}
