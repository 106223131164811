import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import toast from 'react-hot-toast';

import { BasicButton, IconButton, RemoveButton } from '@components/Button';
import { Icon, SearchInput, EmptyText, Title } from '@components/shared';

import { ApprovalIcon, OfferingSendButton } from './';

import UserForm from './UserForm';

export default function OfferingAuthManagement({
  authorities,
  managers,
  offeringId,
  onUpdate,
  offeringStatus,
}) {
  return (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <AuthBlock
        isAuthority
        offeringId={offeringId}
        onUpdate={onUpdate}
        people={authorities}
        offeringStatus={offeringStatus}
        title="Approval Managers"
      />
      <AuthBlock
        isManager
        offeringId={offeringId}
        onUpdate={onUpdate}
        people={managers}
        offeringStatus={offeringStatus}
        title="Offering Managers"
      />
    </div>
  );
}

OfferingAuthManagement.defaultProps = {
  authorities: [],
  managers: [],
};

const Block = styled.div`
  padding: 0 15px;
  width: 50%;
`;

function AuthBlock({
  offeringId,
  title,
  isManager,
  isAuthority,
  onUpdate,
  people,
  offeringStatus,
}) {
  const [isOpen, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [showSearchResults, setShowSearchResults] = useState(false);

  function onPersonCreate(formData) {
    const req = { ...formData };

    axios
      .post(`/users/`, req)
      .then(({ data }) =>
        isAuthority
          ? createAuthority(data.response[0].id)
          : createManager(data.response[0].id),
      )
      .catch((err) => {
        toast.error(err);
        setError(err);
      });
  }

  function createAuthority(personId) {
    const req = {
      user_id: personId,
      offering_id: offeringId,
    };

    axios
      .post(`/create-offering-authority/`, req)
      .then(() => {
        setOpen(false);
        onUpdate();
        setSearchValue('');
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
        setError(err);
        setSearchValue('');
      });
  }

  function createManager(personId) {
    const req = {
      user_id: personId,
      offering_id: offeringId,
    };

    axios
      .post(`/create-offering-manager/`, req)
      .then(() => {
        setOpen(false);
        onUpdate();
        setSearchValue('');
      })
      .catch((err) => {
        toast.error(err);
        setSearchValue('');
        setError(err);
      });
  }

  return (
    <Block>
      <Title text={title} />
      {!isOpen && (
        <React.Fragment>
          <PersonSearch
            setShowSearchResults={(e) => setShowSearchResults(e)}
            isAuthority={isAuthority}
            isManager={isManager}
            onUpdate={() => {
              onUpdate();
              setSearchValue('');
            }}
            offeringId={offeringId}
            disabledIds={people.filter((f) => f.user).map((p) => p.user.id)}
            searchValue={searchValue}
            setOpen={() => setOpen(!isOpen)}
            isOpen={isOpen}
          />
          {searchValue === '' && (
            <People
              people={people}
              offeringId={offeringId}
              isManager={isManager}
              isAuthority={isAuthority}
              onUpdate={onUpdate}
              offeringStatus={offeringStatus}
            />
          )}
          {people.length === 0 && !searchValue && (
            <EmptyText text="Add some people!" />
          )}
        </React.Fragment>
      )}
      {isOpen && (
        <React.Fragment>
          <div
            onClick={() => setOpen(!isOpen)}
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '10px 0',
              cursor: 'pointer',
            }}
          >
            <p
              style={{
                margin: '0 5px 0 0',
                fontWeight: 'bold',
                fontSize: '1.35em',
              }}
            >
              Nevermind
            </p>
            <IconButton
              icon={<Icon.Create isOpen={isOpen} fontSize="25px" />}
            />
          </div>
          <UserForm onSubmit={onPersonCreate} />
        </React.Fragment>
      )}
    </Block>
  );
}

AuthBlock.defaultProps = {
  people: [],
};

const People = ({
  isAuthority,
  isManager,
  offeringId,
  onUpdate,
  people,
  offeringStatus,
}) => {
  if (!people.length) return null;

  return (
    <ul style={{ margin: '20px 0px', padding: 0 }}>
      {people
        .sort((a, b) => (a.user.full_name > b.user.full_name ? 1 : -1))
        .map((p) => (
          <Person
            approvalRequired={p.approval_required}
            artRequired={p.art}
            colorRequired={p.color}
            isAuthority={isAuthority}
            isManager={isManager}
            joinId={p.id}
            key={p.id}
            mockupRequired={p.mockup}
            name={p.user ? p.user.full_name : 'Unknown User'}
            offeringId={offeringId}
            offeringStatus={offeringStatus}
            onUpdate={onUpdate}
            otherRequired={p.other}
            phone={p.user && p.user.primary_phone ? p.user.primary_phone : null}
            priceRequired={p.price}
            quantityRequired={p.quantity}
          />
        ))}
    </ul>
  );
};

People.defaultProps = {
  people: [],
  offeringStatus: false,
};

export const StyledPerson = styled.li`
  padding: 8px;
  list-style-type: none;
  margin-top: 0px;
  display: flex;
  align-items: center;
  width: 95%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #eee;

  p {
    flex-grow: 1;
    color: black;
    font-size: 1.15em;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    margin: 0;

    span {
      font-weight: 400;
      font-size: 0.95em;
    }
  }

  button + button {
    margin-left: 2px;
  }

  svg {
    font-size: 18px;
  }

  & + & {
    margin-top: 5px;
  }
`;

const Person = ({
  approvalRequired,
  artRequired,
  colorRequired,
  isAuthority,
  isManager,
  joinId,
  mockupRequired,
  name,
  offeringId,
  offeringStatus,
  onUpdate,
  otherRequired,
  personId,
  phone,
  priceRequired,
  quantityRequired,
  setSearchValue,
}) => {
  const onRemoveClick = () => {
    const req = {
      join_id: joinId,
    };

    axios
      .post(isManager ? `/delete-manager/` : `/delete-authority/`, req)
      .then(() => {
        onUpdate();
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const approvalButtons = [
    {
      title: 'Quantity',
      icon: (
        <ApprovalIcon
          variety={2}
          color={quantityRequired ? 'indigo' : '#ccc'}
        />
      ),
      onClick: () => onAuthorityChangeClick({ quantity: !quantityRequired }),
    },
    {
      title: 'Color',
      icon: (
        <ApprovalIcon variety={1} color={colorRequired ? 'indigo' : '#ccc'} />
      ),
      onClick: () => onAuthorityChangeClick({ color: !colorRequired }),
    },
    {
      title: 'Price',
      icon: (
        <ApprovalIcon variety={3} color={priceRequired ? 'indigo' : '#ccc'} />
      ),
      onClick: () => onAuthorityChangeClick({ price: !priceRequired }),
    },
    {
      title: 'Art',
      icon: (
        <ApprovalIcon variety={4} color={artRequired ? 'indigo' : '#ccc'} />
      ),
      onClick: () => onAuthorityChangeClick({ art: !artRequired }),
    },
    {
      title: 'Mockup',
      icon: (
        <ApprovalIcon variety={5} color={mockupRequired ? 'indigo' : '#ccc'} />
      ),
      onClick: () => onAuthorityChangeClick({ mockup: !mockupRequired }),
    },
    {
      title: 'Other',
      icon: (
        <ApprovalIcon variety={6} color={otherRequired ? 'indigo' : '#ccc'} />
      ),
      onClick: () => onAuthorityChangeClick({ other: !otherRequired }),
    },
  ].map((m, i) => ({ ...m, key: i }));

  const onAuthorityChangeClick = (additionalOpts) => {
    if (!additionalOpts) return;
    axios
      .post(`/update-authority-requirement/`, {
        art: artRequired,
        color: colorRequired,
        join_id: joinId,
        mockup: mockupRequired,
        price: priceRequired,
        quantity: quantityRequired,
        required: approvalRequired,
        other: otherRequired,
        ...additionalOpts,
      })
      .then(() => {
        onUpdate();
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const onAddClick = () => {
    const req = {
      user_id: personId,
      offering_id: offeringId,
    };

    axios
      .post(
        isAuthority
          ? `/create-offering-authority/`
          : `/create-offering-manager/`,
        req,
      )
      .then(() => {
        toast.success('Added!');
        onUpdate();
        setSearchValue('');
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <StyledPerson>
      <p>
        {name}
        {phone && <span>{phone.number}</span>}
      </p>
      {joinId && isAuthority && (
        <React.Fragment>
          {approvalButtons.map((m) => (
            <ApprovalButton
              key={m.key}
              title={m.title}
              onClick={m.onClick}
              icon={m.icon}
            />
          ))}
        </React.Fragment>
      )}
      {isAuthority && joinId && (
        <IconButton
          title={`Approval Required`}
          onClick={() =>
            onAuthorityChangeClick({ required: !approvalRequired })
          }
          icon={<Icon.Check color={approvalRequired ? 'dodgerblue' : '#ccc'} />}
        />
      )}
      {joinId && offeringStatus === 2 && <OfferingSendButton joinId={joinId} />}
      {joinId && offeringStatus !== 2 && (
        <RemoveButton onClick={onRemoveClick} title="Remove" />
      )}
      {!joinId && <BasicButton text="Add" primary onClick={onAddClick} />}
    </StyledPerson>
  );
};

Person.defaultProps = {
  approvalRequired: false,
  isAuthority: false,
  isManager: false,
  joinId: null,
  name: '',
  offeringId: null,
  personId: null,
  phone: null,
};

const PersonSearch = ({
  disabledIds,
  onUpdate,
  offeringId,
  isManager,
  isAuthority,
  setOpen,
  isOpen,
}) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    onSearch();
  }, [searchValue]);

  function onSearch() {
    if (!searchValue.length) return setResults([]);
    setLoading(true);

    axios
      .post(`/retrieve-users/`, {
        search_value: searchValue,
      })
      .then(({ data }) => {
        setResults(data.response.filter((f) => !disabledIds.includes(f.id)));
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  return (
    <div style={{ margin: '10px 0px !important' }}>
      <div style={{ marginBottom: '20px' }}>
        <SearchInput onChange={(e) => setSearchValue(e)} value={searchValue} />
      </div>
      {searchValue && !loading && (
        <div
          onClick={() => setOpen(!isOpen)}
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '10px 0',
            cursor: 'pointer',
          }}
        >
          <p
            style={{
              margin: '0 5px 0 0',
              fontWeight: 'bold',
              fontSize: '1.35em',
            }}
          >
            Dont see who you need? Create them now!
          </p>
          <IconButton icon={<Icon.Create isOpen={isOpen} fontSize="25px" />} />
        </div>
      )}
      {results.length === 0 && searchValue && !loading && <EmptyText />}
      {results.map((r) => (
        <Person
          key={r.id}
          name={r.full_name}
          personId={r.id}
          offeringId={offeringId}
          isManager={isManager}
          isAuthority={isAuthority}
          onUpdate={onUpdate}
          setOpen={setOpen}
          isOpen={isOpen}
          phone={r.primary_phone}
          setSearchValue={(e) => setSearchValue(e)}
        />
      ))}
    </div>
  );
};

PersonSearch.defaultProps = {
  disabledIds: [],
};

const ApprovalButton = ({ onClick, icon, title }) => {
  return <IconButton title={title} onClick={onClick} icon={icon} />;
};
