import React from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CustomerApproval from '@components/CustomerApproval';

export default function UserApp() {
  return (
    <React.Fragment>
      <Toaster />
      <Router>
        <Switch>
          <Route path="/offerings/:offeringId" component={CustomerApproval} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

const NotFound = () => (
  <div>
    <h1>Not Found</h1>
  </div>
);
