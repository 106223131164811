import React, { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { FaMobileAlt } from 'react-icons/fa';

import { IconButton } from '@components/Button';
import { Icon } from '@components/shared';

export default function OfferingSendButton({ joinId }) {
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    const req = {
      join_id: joinId,
    };

    setLoading(true);

    axios
      .post(`/resend-approval-notification/`, req)
      .then(() => {
        toast.success('Resent!');
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  return (
    <IconButton
      icon={<FaMobileAlt color={`var(--primaryColor500)`} />}
      title="Send Text Link"
      primary
      onClick={onClick}
    />
  );
}
