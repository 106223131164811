import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Flex, Group } from '@mantine/core';

import { SearchInput } from '@components/shared';
import { PartnerTable } from '.';

export default function PartnerSearch() {
  const [loading, setLoading] = useState([]);
  const [partners, setPartners] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    fetchPartners();
  }, []);

  useEffect(() => {
    fetchPartners();
  }, [searchValue]);

  function fetchPartners() {
    setLoading(true);

    axios
      .post(`/retrieve-partners/`, { search_value: searchValue })
      .then(({ data }) => {
        setLoading(false);
        setPartners(data.response);
      })
      .catch((err) => {
        setLoading(false);
        setPartners([]);
      });
  }

  return (
    <Flex direction="column">
      <Group w={100} mb="xs">
        <SearchInput onChange={(e) => setSearchValue(e)} value={searchValue} />
      </Group>
      <PartnerTable data={partners} loading={loading} />
    </Flex>
  );
}

const SearchField = ({ value, onChange }) => {
  return <SearchInput value={value} onChange={(e) => onChange(e)} />;
};
