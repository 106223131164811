import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BasicButton } from '@components/Button';
import { ThemeIcon, Text, Group, Tabs } from '@mantine/core';

import { AdminForm } from '@components/Admin';
import { Icon, ContactManagement, Title, ViewError } from '@components/shared';

export default function AdminDetail({ match }) {
  const [admin, setAdmin] = useState(populateAdmin());
  const [error, setError] = useState(null);

  const { id } = match.params;

  const tabs = [{ text: 'Details' }, { text: 'Contacts' }];

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/admins/${id}/`)
      .then(({ data }) => {
        setAdmin(populateAdmin(data.response[0]));
      })
      .catch((err) => {
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  function onDelete() {
    axios
      .post(`/delete-admin/`, { admin_id: id })
      .then(() => {
        window.location = '/admin';
      })
      .catch((err) => {
        fetchData();
      });
  }

  return (
    <div>
      <Title
        page
        text={admin.full_name}
        subtitle={
          <Group mb="xl">
            <ThemeIcon color="red">
              <Icon.Admin size={16} />
            </ThemeIcon>
            <Text ff="Open Sans, sans-serif" fw="lighter" size="lg" color="red">
              Admin
            </Text>
          </Group>
        }
        icon={<Icon.Admin />}
      />
      <Tabs defaultValue={tabs[0].text} variant="default">
        <Tabs.List mb="md">
          {tabs.map((m) => (
            <Tabs.Tab value={m.text} pt="xs" pb="xs">
              <Text ff="Open Sans, sans-serif" fw="lighter" size="md" p={0}>
                {m.text}
              </Text>
            </Tabs.Tab>
          ))}
        </Tabs.List>
        <Tabs.Panel value="Details">
          <AdminForm
            id={id}
            firstName={admin.first_name}
            lastName={admin.last_name}
            email={admin.email}
            onSuccess={fetchData}
          />
          <BasicButton
            variant="subtle"
            color="red"
            text="Delete this admin"
            onClick={() => onDelete()}
          />
        </Tabs.Panel>
        <Tabs.Panel value="Contacts">
          <ContactManagement
            contactName={admin.full_name}
            contacts={admin.contact_methods}
            adminId={id}
            onUpdate={fetchData}
          />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}

function populateAdmin(d = {}) {
  return {
    first_name: d.first_name || '',
    full_name: d.full_name || '',
    last_name: d.last_name || '',
    email: d.email || '',
    offerings: d.offerings ? d.offerings : [],
    phone: d.phone || '',
    contact_methods: d.contact_methods || [],
  };
}
