import React, { useState } from 'react';
import axios from 'axios';
import { BasicButton } from '@components/Button';
import { SimpleGrid, Text } from '@mantine/core';

export default function TagList({
  taggings,
  tags,
  associated,
  offeringItemId,
  itemId,
  onUpdate,
  clone,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function onRemoveClick(id) {
    setLoading(true);

    axios
      .post(`/delete-tagging/`, { tagging_id: id })
      .then(() => onUpdate())
      .then(() => toast.success('Removed!'))
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  function onAssociateClick(tagId, tagName) {
    const req = {
      tag_id: tagId,
      clone,
      variety: 1,
      name: tagName,
    };

    if (itemId) {
      req.item_id = itemId;
    }

    if (offeringItemId) {
      req.offering_item_id = offeringItemId;
    }

    axios
      .post(`/tags/`, req)
      .then(() => toast.success('Added!'))
      .then(() => {
        onUpdate();
      })
      .catch((err) => {});
  }

  if (!associated) {
    return (
      <SimpleGrid cols={5}>
        {tags.map((t) => (
          <BasicButton
            key={t.id}
            variant="outline"
            text={
              <Text ff="Open Sans, sans-serif" size="lg">
                {t.name}
              </Text>
            }
            onClick={() => onAssociateClick(t.id, t.name)}
          />
        ))}
      </SimpleGrid>
    );
  }

  return (
    <SimpleGrid cols={5}>
      {taggings.map((tagging) => (
        <BasicButton
          style={{ margin: '0 4px', width: '100%' }}
          margin={15}
          key={tagging.id}
          color="orange"
          text={
            <Text ff="Open Sans, sans-serif" size="lg">{`${tagging.tag.name}${
              tagging.clone ? ' (clone)' : ''
            }`}</Text>
          }
          onClick={() => onRemoveClick(tagging.id)}
        />
      ))}
    </SimpleGrid>
  );
}

TagList.defaultProps = {
  associated: true,
  tags: [],
  taggings: [],
  clone: false,
};
