import React, { useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  height: 80px;
  width: 60px;
  font-size: 65px;
  font-weight: bold;
  font-family: inherit;
  padding: 10px;
  appearance: none;
  margin: 0;
  border: 2px solid var(--grey100);
  border-radius: 2px;
  color: var(--textColor500);
  outline: none;
  -moz-appearance: textfield;

  & + & {
    margin-left: 8px;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const inputs = [0, 1, 2, 3, 4];

export default function CodeInput({ value, onChange }) {
  useEffect(() => {
    if (!value.length && document.getElementById(`input${inputs[0]}`)) {
      document.getElementById(`input${inputs[0]}`).focus();
    }
  }, [value]);

  function onInputChange(val, idx) {
    let valArray = Array.from(value);
    valArray[idx] = val;
    onChange(valArray.join(''));

    const nextInput = document.getElementById(`input${idx + 1}`);

    if (nextInput) {
      nextInput.focus();
    }
  }

  return (
    <Wrapper>
      {inputs.map((input) => (
        <Input
          key={input}
          type="number"
          maxLength={1}
          id={`input${input}`}
          value={value[input] || ''}
          onChange={(e) => {
            onInputChange(e.target.value, input);
          }}
        />
      ))}
    </Wrapper>
  );
}
