import React from 'react';
import { Link } from 'react-router-dom';
import { VscChecklist } from 'react-icons/vsc';

import { SimpleGrid, Text, Group, Flex, Card } from '@mantine/core';

import { Icon, Grid, ObjectBlock } from '@components/shared';

export default function ItemList({ select, onSelect, items, asLink }) {
  if (!items.length) return null;

  const cardStyle = {
    border: '1px solid #ccc',
    borderRadius: '7px',
    background: '#fff',
  };

  if (!asLink)
    return (
      <SimpleGrid cols={3} pb={500}>
        {items.map((item, i) => (
          <Card
            key={i}
            component={Link}
            to={`/items/${item.id}`}
            style={cardStyle}
          >
            <Flex direction="column" align="center">
              <div style={{ minHeight: '500px' }}>
                <img src={item.display_medium_url} />
              </div>
              <Text ff="Open Sans, sans-serif" size={25} mt="xs">
                {item.name}
              </Text>
              {item.price_formatted && <Text>{item.price_formatted}</Text>}
              {item.order_count && <Text>{item.order_count}</Text>}
              {item.approval_info && <Text>{item.approval_info}</Text>}
              {item.item_number && (
                <Text>
                  <b>SKU/Item Number:</b> {item.item_number}
                </Text>
              )}
            </Flex>
          </Card>
        ))}
      </SimpleGrid>
    );

  if (asLink)
    return (
      <SimpleGrid cols={3} pb={500}>
        {items.map((item, i) => (
          <Card
            key={i}
            component={Link}
            to={item.url ? item.url : `/items/${item.id}`}
            style={cardStyle}
          >
            <Flex direction="column" align="center">
              <div style={{ minHeight: '500px' }}>
                <img src={item.display_medium_url} />
              </div>
              <Text ff="Open Sans, sans-serif" size={25} mt="xs">
                {item.name}
              </Text>
              {item.price_formatted && <Text>{item.price_formatted}</Text>}
              {item.order_count && <Text>{item.order_count}</Text>}
              {item.approval_info && <Text>{item.approval_info}</Text>}
              {item.item_number && (
                <Text>
                  <b>SKU/Item Number:</b> {item.item_number}
                </Text>
              )}
            </Flex>
          </Card>
        ))}
      </SimpleGrid>
    );
}

ItemList.defaultProps = {
  asLink: true,
  items: [],
  select: false,
};
