import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import {
  Button,
  Text,
  Flex,
  Card,
  Badge,
  Group,
  ActionIcon,
} from '@mantine/core';
import { Title } from '@components/shared';
import { Select } from 'semantic-ui-react';
import { FaMinus, FaPlus, FaEye, FaEyeSlash } from 'react-icons/fa';

export default function OfferingVendorItemSelect({
  offeringItemId,
  fetchData,
  offeringVendorItems,
}) {
  const [vendorItems, setVendorItems] = useState([]);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    retrieveVendorItems();
  }, []);

  const selectData =
    vendorItems.length > 0
      ? [
          {
            key: '',
            text: ``,
            value: null,
          },
        ].concat(
          vendorItems
            .filter(
              (f) =>
                !offeringVendorItems
                  .map((m) => m.vendor_item_id)
                  .includes(f.id),
            )
            .map((m) => {
              return {
                key: m.id,
                text: `${m.vendor_name} - ${m.price ? m.price : 'NO PRICE'}`,
                value: m.id,
                disabled: m.status == 2,
              };
            }),
        )
      : [];

  function retrieveVendorItems() {
    axios
      .get(`/offering-items/${offeringItemId}/retrieve-vendors/`)
      .then(({ data }) => {
        setVendorItems(data.response);
      })
      .catch((err) => {
        setVendorItems([]);
        setError(err);
      });
  }

  function createOfferingVendorItem() {
    axios
      .post(`/offering-vendor-items/`, {
        vendor_item_id: value,
        offering_item_id: offeringItemId,
      })
      .then(() => {
        toast.success('Selected!');
        setValue(null);
        fetchData();
      })
      .catch((err) => {
        setValue(null);
        setError(err);
      });
  }

  function setOfferingVendorItemStatus(offeringVendorItemId) {
    axios
      .get(`/offering-vendor-items/${offeringVendorItemId}/toggle-status/`, {
        vendor_item_id: value,
        offering_item_id: offeringItemId,
      })
      .then(() => {
        toast.success('Selected!');
        setValue(null);
        fetchData();
      })
      .catch((err) => {
        setValue(null);
        setError(err);
      });
  }

  const filteredVendorItems = show
    ? offeringVendorItems
    : offeringVendorItems.filter((f) => f.status == 1);

  return (
    <Flex direction="column" mt="xl" gap={30}>
      {offeringVendorItems.filter((f) => f.status == 1).length === 0 && (
        <div>
          <Title text="Vendor Select" />
          {vendorItems.filter((f) => f.price == null).length > 1 && (
            <Text c="red" size="md" mb="sm" ff="Open Sans, sans-serif">
              You have vendors with this item without a set price!
            </Text>
          )}
          <Select
            clearable
            options={selectData}
            placeholder="Select a vendor"
            value={value}
            onChange={(e, d) => {
              setValue(d.value);
            }}
          />
          {value && (
            <Button
              mt="xl"
              mb="xl"
              ml="sm"
              color="green"
              onClick={() => createOfferingVendorItem()}
            >
              Use this vendor
            </Button>
          )}
        </div>
      )}
      {offeringVendorItems.length > 0 && (
        <div>
          <Title text={`Selected Vendor(s)`} />
          <Button
            leftIcon={show ? <FaEyeSlash size={16} /> : <FaEye size={16} />}
            variant="subtle"
            fw="normal"
            ff="Open Sans, sans-serif"
            color="gray"
            onClick={() => setShow(!show)}
          >
            {show ? 'Hide' : 'Show'} inactive vendors
          </Button>
          {filteredVendorItems
            .sort((a, b) => a.status - b.status)
            .map((m, i) => {
              return (
                <Card
                  mt="md"
                  mb="md"
                  opacity={m.status == 1 ? 1 : 0.65}
                  key={i}
                >
                  <Group>
                    <Text
                      ff="Open Sans, sans-serif"
                      size={18}
                      opacity={m.status == 1 ? 1 : 0.35}
                      c="dimmed"
                    >
                      {m.vendor_name}
                    </Text>
                    <Badge
                      c="white"
                      size="lg"
                      variant="gradient"
                      radius="md"
                      pl="xl"
                      pr="xl"
                      gradient={{ from: 'teal', to: 'green', deg: 90 }}
                    >
                      {m.active_price}
                    </Badge>
                    {m.status == 1 && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          flexGrow: 1,
                        }}
                      >
                        <Text ff="Open Sans, sans-serif" c="red">
                          Remove
                        </Text>
                        <ActionIcon
                          ml="md"
                          color="red"
                          variant="light"
                          onClick={() => setOfferingVendorItemStatus(m.id)}
                        >
                          <FaMinus size={16} />
                        </ActionIcon>
                      </div>
                    )}
                    {m.status == 2 && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          flexGrow: 1,
                        }}
                      >
                        <Text ff="Open Sans, sans-serif" c="teal">
                          Re-add to Offering Item
                        </Text>
                        <ActionIcon
                          ml="md"
                          color="teal"
                          variant="light"
                          onClick={() => setOfferingVendorItemStatus(m.id)}
                        >
                          <FaPlus size={16} />
                        </ActionIcon>
                      </div>
                    )}
                  </Group>
                </Card>
              );
            })}
        </div>
      )}
    </Flex>
  );
}
