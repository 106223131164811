import React, { useState } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Modal } from 'semantic-ui-react';
import { Button, Flex } from '@mantine/core';
import { FaTrashAlt } from 'react-icons/fa';

import { BasicForm, Icon } from '@components/shared';
import { IconButton } from '@components/Button';

export default function OfferingForm({
  destroy,
  id,
  name,
  onSuccess,
  projectId,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function onSubmit(formData) {
    setLoading(true);
    setError(null);

    if (id) {
      return onUpdate(formData);
    }

    return onCreate(formData);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
      project_id: projectId,
    };

    axios
      .post(`/offerings/`, req)
      .then(({ data }) => {
        window.location = `/offerings/${data.response[0].id}`;
        // toast.success('Offering Created!');
        // setLoading(false);
        // onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      id,
    };

    axios
      .put(`/offerings/${id}/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
        toast.success('Offering Updated!');
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onDelete() {
    setLoading(true);

    axios
      .get(`/offerings/${id}/delete`)
      .then(() => {
        toast.success(`Deleted!`);
        setLoading(false);
        window.location = '/offerings';
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  const fields = [
    {
      name: 'name',
      label: 'Name',
      initialValue: name,
      required: true,
      schema: () => Yup.string().required('Required'),
    },
  ];

  return (
    <Flex direction="column">
      <BasicForm
        error={error}
        fields={fields}
        loading={loading}
        onSubmit={onSubmit}
      />
      {destroy && (
        <Button
          onClick={() => onDelete()}
          color="red"
          variant="light"
          leftIcon={<FaTrashAlt size={16} />}
        >
          Delete this offering
        </Button>
      )}
    </Flex>
  );
}

OfferingForm.defaultProps = {
  endDate: null,
  name: '',
  startDate: null,
};

export const OfferingFormModal = ({ onSuccess, name, id }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  return (
    <React.Fragment>
      <IconButton
        icon={
          <Icon.Edit size={22} style={{ fill: 'var(--primaryColor500)' }} />
        }
        onClick={() => setOpen(true)}
        title="Edit Offering"
      />
      <Modal open={isOpen} onClose={onClose} size="small">
        <Modal.Content>
          <OfferingForm
            id={id}
            name={name}
            onSuccess={() => {
              setOpen(false);
              onSuccess();
            }}
            destroy
          />
        </Modal.Content>
      </Modal>
    </React.Fragment>
  );
};
