import React, { useState } from 'react';

import { OfferingTable, OfferingForm } from '@components/Offering';

import { IconButton } from '@components/Button';
import { Title, Icon } from '@components/shared';

export default function ProjectOfferings({ projectId, offerings, onUpdate }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Title text="Offerings" page>
        <IconButton
          onClick={() => setOpen(!isOpen)}
          icon={<Icon.Create isOpen={isOpen} />}
        />
      </Title>
      {!isOpen && <OfferingTable offerings={offerings} fetchData={onUpdate} />}
      {isOpen && (
        <OfferingForm
          projectId={projectId}
          onSuccess={() => {
            setOpen(false);
            onUpdate();
          }}
        />
      )}
    </div>
  );
}

ProjectOfferings.defaultProps = {
  offerings: [],
};
