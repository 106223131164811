import React, { useState } from 'react';
import axios from 'axios';
import './index.scss';

import { Form, Button } from 'semantic-ui-react';
import { ViewError } from '@components/shared';

export default function PasswordForm({ adminId, codeId }) {
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  function onSubmit(e) {
    setLoading(true);

    const req = {
      admin_id: adminId,
      remote_code_id: codeId,
      password: password,
      confirmed_password: confirmedPassword,
    };

    axios
      .post(`/admin-create-password/`, req)
      .then(({ data }) => {
        setLoading(false);
        window.location = '/admin';
      })
      .catch((err) => {
        setError([]);
        setLoading(false);
      });
  }

  if (error) {
    return (
      <div style={{ padding: '50px' }}>
        <ViewError text={error} />
      </div>
    );
  }

  return (
    <Form
      size="large"
      style={{
        paddingTop: '100px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Form.Group widths="equal">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '0 auto',
          }}
        >
          <input
            style={{ margin: '20px' }}
            type="password"
            name="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            style={{ margin: '20px' }}
            type="password"
            name="confirmedPassword"
            placeholder="Enter Password Again"
            value={confirmedPassword}
            onChange={(e) => setConfirmedPassword(e.target.value)}
          />
        </div>
      </Form.Group>
      <Button
        color="blue"
        style={{ margin: '0px auto' }}
        disabled={
          !password || !confirmedPassword || password !== confirmedPassword
        }
        onClick={() => onSubmit()}
      >
        Submit
      </Button>
    </Form>
  );
}
