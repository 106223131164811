import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';
import {
  Card,
  Group,
  Text,
  Flex,
  ThemeIcon,
  ActionIcon,
  FileButton,
  Button,
  SimpleGrid,
} from '@mantine/core';
import { FaRegFileImage, FaFileAlt } from 'react-icons/fa';
import { FaStar, FaTrashAlt } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { Title } from '@components/shared';

import { PlacementText } from '@components/Placement';
import { buildSrcSet, buildSizes } from '@util/images';

const FocusedWrapper = styled.div`
  width: 97% !important;
  height: 97% !important;
  margin: 1em auto;
  border-radius: 4px;

  img {
    max-height: 800px !important;
    min-width: 90%;
    max-width: 100%;
  }
`;

export const Focused = ({ asset }) => {
  if (!asset || !asset.large_filename) return null;

  return (
    <FocusedWrapper>
      <img src={asset.large_filename} />
    </FocusedWrapper>
  );
};

export const List = ({
  assets,
  fetchData,
  hideUpload,
  isFeatured,
  isOffering,
  isSizeChart,
  isStock,
  itemId,
  onDelete,
  onFocusedClick,
  title,
  viewOnly,
}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [pdfFilenameUrl, setPdfFilenameUrl] = useState(null);
  const [pdfFilename, setPdfFilename] = useState(null);

  useEffect(() => {
    if (!pdfFilenameUrl) return;

    setOpen(true);
  }, [pdfFilenameUrl]);

  function onUpload(file) {
    const formData = new FormData();
    formData.append('tempfile', file);

    if (itemId) {
      if (isOffering) {
        formData.append('offering_item_id', itemId);
        formData.append('variety', 1);
      } else {
        formData.append('item_id', itemId);
        formData.append('variety', 1);
      }
    }

    if (isStock) {
      formData.append('variety', 1);
      formData.append('clone', true);
      formData.append('image_only', true);
    }

    if (isFeatured) {
      formData.append('variety', 1);
      formData.append('image_only', true);
    }

    if (isSizeChart) {
      formData.append('variety', 4);
    }

    setLoading(true);

    axios
      .post(`/asset-upload/`, formData)
      .then(() => {
        setLoading(false);
        toast.success('Uploaded!');
      })
      .then(() => fetchData())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onDeleteClick(id) {
    if (onDelete) {
      return onDelete(id);
    }

    setLoading(true);

    axios
      .post(`/delete-asset/${id}/`)
      .then(() => {
        setLoading(false);
        toast.success('Deleted!');
      })
      .then(() => fetchData())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onFeaturedClick(id) {
    setLoading(true);

    axios
      .post(`/assets/${id}/mark-featured/`)
      .then(() => {
        setLoading(false);
        toast.success('Marked as featured');
        fetchData();
      })
      .then(() => fetchData())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function setPdf(asset) {
    setPdfFilename(asset.filename);
    setPdfFilenameUrl(asset.filename_url);
  }

  function closeModal() {
    setOpen(false);
    setPdfFilename(null);
    setPdfFilenameUrl(null);
  }

  return (
    <Flex direction="column" wrap="wrap" justify="center">
      <Modal
        onClose={() => closeModal()}
        onOpen={() => setOpen(true)}
        open={open}
        size="large"
      >
        <Modal.Content>
          <Text ff="Open Sans, sans-serif" size="xl" align="center" mb="md">
            {pdfFilename}
          </Text>
          <object width="100%" height="900" data={pdfFilenameUrl}></object>
        </Modal.Content>
      </Modal>
      {viewOnly && (
        <Text ff="Open Sans, sans-serif" size="xl" mb="xs" mt="xs">
          {title}
        </Text>
      )}
      {!hideUpload && (
        <React.Fragment>
          <Group mb="xs" mt="md">
            <Title text={title} />
            <FileButton onChange={(e) => onUpload(e)}>
              {(props) => (
                <Button
                  loading={loading}
                  disabled={loading}
                  {...props}
                  variant="light"
                  color="orange"
                  leftIcon={
                    isSizeChart ? (
                      <FaFileAlt size={16} />
                    ) : (
                      <FaRegFileImage size={16} />
                    )
                  }
                  size="xs"
                >
                  Add {title}
                </Button>
              )}
            </FileButton>
          </Group>
        </React.Fragment>
      )}
      {assets.length > 0 && (
        <SimpleGrid cols={3}>
          {assets
            .sort((a, b) => b.featured - a.featured)
            .map((asset) => (
              <Card key={asset.id} mr="xs" align="center">
                {asset.featured && (
                  <ThemeIcon
                    variant="light"
                    color="yellow"
                    style={{ position: 'absolute', top: '5px', left: '5px' }}
                  >
                    <FaStar size={16} />
                  </ThemeIcon>
                )}
                <Flex direction="column" align="center" justify="center">
                  {asset.content_type !== 'application/pdf' && (
                    <img
                      onClick={() => onFocusedClick(asset)}
                      src={asset.thumb_url}
                      sizes={buildSizes()}
                    />
                  )}
                  {asset.content_type === 'application/pdf' && (
                    <Flex direction="column">
                      <Button
                        onClick={() => setPdf(asset)}
                        color="gray"
                        variant="subtle"
                        size="xs"
                        mb="xs"
                      >
                        view
                      </Button>
                      <object
                        width="100"
                        height="75"
                        data={asset.filename_url}
                      ></object>
                    </Flex>
                  )}
                  {asset.placement_data && asset.placement_data !== '{}' && (
                    <PlacementText json={asset.placement_data} />
                  )}
                  {!viewOnly && (
                    <ItemActions
                      isOffering={isOffering}
                      onDeleteClick={() => onDeleteClick(asset.id)}
                      onFeaturedClick={() => onFeaturedClick(asset.id)}
                      featured={asset.featured}
                      spec={isSizeChart}
                    />
                  )}
                </Flex>
              </Card>
            ))}
        </SimpleGrid>
      )}
    </Flex>
  );
};

List.defaultProps = {
  hideUpload: false,
  isFeatured: false,
  isOffering: false,
  isSizeChart: false,
  isStock: false,
  title: 'Images',
};

function ItemActions({
  onDeleteClick,
  onFeaturedClick,
  featured,
  spec,
  isOffering,
}) {
  return (
    <Group>
      {!featured && !spec && !isOffering && (
        <ActionIcon
          size="md"
          variant="light"
          title="Mark Featured"
          onClick={onFeaturedClick}
          color="gray"
        >
          <FaStar size={14} />
        </ActionIcon>
      )}
      <ActionIcon
        size="md"
        title="Move to trash"
        onClick={onDeleteClick}
        color="red"
        variant="light"
      >
        <FaTrashAlt size={14} />
      </ActionIcon>
    </Group>
  );
}
