import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Modal, Table } from 'semantic-ui-react';
import toast from 'react-hot-toast';
import { ActionIcon } from '@mantine/core';
import { FaTrashAlt } from 'react-icons/fa';

import { Icon, Title } from '@components/shared';
import { IconButton, BasicButton, RemoveButton } from '@components/Button';
import {
  locations,
  alignments,
  formattedAlignments,
  formattedLocations,
  imprints,
  formattedImprints,
} from './helpers';

import { getUuid } from '@util/getUuid';

export default function PlacementConfig({ itemId, placementData, fetchData }) {
  const [isOpen, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [config, setConfig] = useState({
    items: [],
  });

  useEffect(() => {
    if ('{}' !== JSON.stringify(placementData)) {
      setItems(setConfig(placementData));
    }
  }, [JSON.stringify(placementData)]);

  function onClose() {
    setOpen(false);
  }

  function updateConfig(newConfig) {
    axios
      .put(`/items/${itemId}/`, {
        placement_data: JSON.stringify(newConfig),
      })
      .then(() => fetchData());
  }

  return (
    <div>
      <Title text="Placements">
        {itemId && (
          <IconButton
            icon={<Icon.Create isOpen={isOpen} color="orange" />}
            onClick={() => setOpen(!isOpen)}
          />
        )}
      </Title>
      <PlacementItems
        items={config.items.sort((a, b) => (a.imprint > b.imprint ? 1 : -1))}
        itemId={itemId}
        onRemoveClick={(uuid) => {
          setConfig({
            ...config,
            items: [...config.items].filter((f) => f.uuid !== uuid),
          });
          updateConfig({
            ...config,
            items: [...config.items].filter((f) => f.uuid !== uuid),
          });
        }}
      />
      <Modal
        open={isOpen}
        onClose={onClose}
        size="large"
        style={{ padding: '50px 70px' }}
      >
        <Modal.Content>
          <PlacementForm
            disabledImprintLocations={config.items.map((m) => {
              return { imprint: m.imprint, location: m.location };
            })}
            onAdd={(e) => {
              setConfig({ ...config, items: [...config.items, e] });
              updateConfig({ ...config, items: [...config.items, e] });
              onClose();
            }}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
}

PlacementConfig.defaultProps = {
  placementData: {},
};

const PlacementForm = ({ disabledImprintLocations, onAdd }) => {
  const [formValues, setFormValues] = useState({
    imprint: '',
    location: '',
    alignments: [],
    dimensions: '',
  });

  function onSubmit() {
    onAdd({
      imprint: formValues.imprint,
      location: formValues.location,
      alignments: formValues.alignments,
      dimensions: formValues.dimensions,
      uuid: getUuid(),
    });
    toast.success('Added!');
    setFormValues({
      imprint: '',
      location: '',
      alignments: [],
      dimensions: '',
    });
  }

  return (
    <Form>
      <Form.Select
        label="Imprint Method"
        value={formValues.imprint}
        onChange={(e, d) => setFormValues({ ...formValues, imprint: d.value })}
        placeholder="Select one..."
        options={imprints}
        search
      />
      <Form.Select
        label="Location"
        value={formValues.location}
        onChange={(e, d) => setFormValues({ ...formValues, location: d.value })}
        placeholder="Select one..."
        options={locations
          .map((m) => ({
            ...m,
            disabled:
              disabledImprintLocations.filter(
                (f) =>
                  f.imprint === formValues.imprint && f.location === m.value,
              ).length > 0,
            description: !formValues.imprint
              ? 'Select an imprint method first'
              : disabledImprintLocations.filter(
                  (f) =>
                    f.imprint === formValues.imprint && f.location === m.value,
                ).length > 0
              ? 'Already added'
              : '',
          }))
          .sort((a, b) => (a.disabled > b.disabled ? 1 : 1))}
        search
      />
      <Form.Select
        label="Alignment(s)"
        value={formValues.alignments}
        onChange={(e, d) =>
          setFormValues({ ...formValues, alignments: d.value })
        }
        placeholder="Select multiple..."
        options={alignments}
        search
        multiple
      />
      <Form.TextArea
        label="Dimensions"
        value={formValues.dimensions}
        onChange={(e, d) =>
          setFormValues({ ...formValues, dimensions: d.value })
        }
        placeholder="Art dimensions"
      />
      <BasicButton
        text="Save"
        primary
        onClick={onSubmit}
        disabled={
          formValues.imprint.length === 0 ||
          formValues.alignments.length === 0 ||
          formValues.location.length === 0
        }
      />
    </Form>
  );
};

PlacementForm.defaultProps = {
  disabledImprintLocations: [],
};

const PlacementItems = ({ items, itemId, onRemoveClick }) => {
  return (
    <Table size="large">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="Imprint Method" />
          <Table.HeaderCell content="Location" />
          <Table.HeaderCell content="Alignment" />
          <Table.HeaderCell content="Dimensions" />
          {itemId && <Table.HeaderCell content="Manage" />}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((item) => (
          <Table.Row key={item.uuid}>
            <Table.Cell
              content={formattedImprints[item.imprint - 1]}
              style={{ fontWeight: '600', color: '#2aace0' }}
            />
            <Table.Cell
              content={formattedLocations[item.location - 1]}
              style={{ fontSize: '14px' }}
            />
            <Table.Cell
              style={{ fontSize: '14px' }}
              title={new Intl.ListFormat('en', {
                style: 'short',
                type: 'conjunction',
              }).format(item.alignments.map((m) => formattedAlignments[m - 1]))}
              content={new Intl.ListFormat('en', {
                style: 'short',
                type: 'conjunction',
              }).format(item.alignments.map((m) => formattedAlignments[m - 1]))}
            />
            <Table.Cell
              content={item.dimensions}
              style={{ fontSize: '14px' }}
            />
            {itemId && (
              <Table.Cell
                style={{ fontSize: '14px' }}
                content={
                  <ActionIcon
                    onClick={() => onRemoveClick(item.uuid)}
                    color="red"
                    variant="light"
                    title="Delete"
                  >
                    <FaTrashAlt size={14} />
                  </ActionIcon>
                }
              />
            )}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

PlacementItems.defaultProps = {
  items: [],
  itemId: null,
};
