import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Switch, Route } from 'react-router-dom';
import { FaWarehouse, FaLink } from 'react-icons/fa';
import { ThemeIcon, Text, Group, Button } from '@mantine/core';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

import { BasicButton } from '@components/Button';
import { ItemList } from '@components/Item';
import { ManufacturerForm } from '@components/Partner';
import { Icon, ContactManagement, Title, ViewError } from '@components/shared';

export default function ManufacturerDetail({ match }) {
  const [manufacturer, setManufacturer] = useState(populateManufacturer());
  const [error, setError] = useState(null);

  const { id } = match.params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/manufacturers/${id}/`)
      .then(({ data }) => {
        setManufacturer(populateManufacturer(data.response[0]));
      })
      .catch((err) => {
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  function onDelete() {
    axios
      .get(`/manufacturers/${id}/toggle-status/`)
      .then(() => {
        toast.success('Deleted!');
        window.location = '/partners';
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <div p="lg">
      <Title
        page
        text={manufacturer.name}
        subtitle={
          <Group mb="xl">
            <ThemeIcon color="orange">
              <FaWarehouse size={16} />
            </ThemeIcon>
            <Text ff="Open Sans, sans-serif" size="lg" color="orange">
              Manufacturer
            </Text>
            <a href={`https://${manufacturer.website_url}`} target="_blank">
              <Button variant="subtle" size="xs" leftIcon={<FaLink />}>
                Visit
              </Button>
            </a>
          </Group>
        }
        icon={<Icon.Manufacturer />}
      />
      <Switch>
        <Route path={match.path} exact>
          <React.Fragment>
            <ManufacturerForm
              id={id}
              name={manufacturer.name}
              variety={manufacturer.variety}
              varietyFormatted={manufacturer.variety_formatted}
              websiteUrl={manufacturer.website_url}
              onSuccess={fetchData}
            />
            <ContactManagement
              contacts={manufacturer.contact_methods}
              manufacturerId={manufacturer.id}
              onUpdate={() => fetchData()}
            />
            {manufacturer.items.length > 0 && (
              <div style={{ margin: '25px 0' }}>
                <Title text="Manufacturer Items" icon={<Icon.Item />} />
                <ItemList items={manufacturer.items} />
              </div>
            )}
            <BasicButton
              color="red"
              text="Delete this manufacturer"
              onClick={() => onDelete()}
            />
          </React.Fragment>
        </Route>
      </Switch>
    </div>
  );
}

function populateManufacturer(d = {}) {
  return {
    id: d.id || null,
    name: d.name || '',
    items: d.items ? d.items : [],
    status: d.status || null,
    variety: d.variety || null,
    variety_formatted: d.variety_formatted || null,
    contact_methods: d.contact_methods || [],
    website_url: d.website_url || '',
  };
}
