import React, { Component } from 'react';
import ReactTable from 'react-table';
import axios from 'axios';
import PropTypes from 'prop-types';

class BasicTable extends Component {
  state = {
    loading: false,
    tableData: [],
  };

  componentDidMount = () => {
    if (this.props.url) {
      return this.fetchData();
    }

    return this.setState({
      loading: false,
    });
  };

  componentDidUpdate = (prevProps) => {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.setState({
        tableData: this.props.data,
        loading: false,
      });
    }
  };

  fetchData = () => {
    if (!this.props.fetch || !this.props.url)
      return this.setState({ loading: false });

    this.setState({ loading: true });

    this.axiosCancelSource = axios.CancelToken.source();

    axios
      .get(this.props.url, { cancelToken: this.axiosCancelSource.token })
      .then(({ data }) => {
        let tableData = [...data];

        if (this.props.keyName) {
          tableData = data[this.props.keyName];
        }

        this.setState({
          loading: false,
          tableData,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          tableData: [],
        });
      });
  };

  // componentWillUnmount = () => {
  //   if (this.axiosCancelSource) {
  //     this.axiosCancelSource.cancel('Cancelled Request');
  //   }
  // };

  getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      return {
        style: {
          background: rowInfo.row.bool == false ? '#e8b6b6' : 'whitesmoke',
        },
      };
    }
    return {};
  };

  render() {
    const { columns, entityName, defaultPageSize } = this.props;
    const { loading, tableData } = this.state;
    return (
      <ReactTable
        getTrProps={this.getTrProps}
        data={tableData}
        className={this.state.className}
        columns={columns}
        defaultPageSize={defaultPageSize}
        loading={loading || this.props.loading}
        loadingText={`Loading ${entityName}`}
        minRows={5}
        multiSort={false}
        noDataText={`No ${entityName} found`}
        rowsText={entityName}
      />
    );
  }
}

BasicTable.defaultProps = {
  columns: [],
  data: [],
  defaultPageSize: 25,
  entityName: `Rows`,
  loading: false,
  keyName: `data`,
  url: null,
};

BasicTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  defaultPageSize: PropTypes.number,
  entityName: PropTypes.string,
  keyName: PropTypes.string,
  url: PropTypes.string,
};

export default BasicTable;
