import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import toast from 'react-hot-toast';
import { TextInput, Button, Loader, Card } from '@mantine/core';
import { BasicForm } from '@components/shared';
import { Rte } from '@components/shared';

export default function ItemForm({ id, name, description, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [itemName, setItemName] = useState('');
  const [desc, setDesc] = useState('<p><br></p>');

  useEffect(() => {
    if (desc === '<p><br></p>' && description.length > 1) {
      setDesc(description);
    }
    if (!itemName.length > 1 && name.length > 1) {
      setItemName(name);
    }
  }, [description, name]);

  function onSubmit() {
    setLoading(true);
    setError(null);

    if (id) {
      return onUpdate();
    }

    onCreate();
  }

  function onCreate() {
    const req = { name: itemName, description: desc };

    axios
      .post(`/items/`, req)
      .then(({ data }) => {
        setLoading(false);
        toast.success('Item Created!');
        onSuccess(data.response[0].id);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  function onUpdate() {
    const req = { name: itemName, item_id: id, description: desc };

    axios
      .put(`/items/${id}/`, req)
      .then(() => {
        setLoading(false);
        toast.success('Item Updated!');
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  return (
    <Card>
      <TextInput
        label="Item Name"
        placeholder="name"
        size="lg"
        value={itemName ? itemName : name}
        onChange={(e) => setItemName(e.target.value)}
        mb="sm"
      />
      <div style={{ minHeight: '200px' }}>
        {!loading && (
          <Rte
            value={`<p>${description}</p>`}
            onChange={(e) => setDesc(e)}
            style={{ minHeight: '200px' }}
          />
        )}
        {loading && (
          <div style={{ margin: '20px auto', width: 'fit-content' }}>
            <Loader size="lg" color="teal" />
          </div>
        )}
      </div>
      <Button color="green" variant="light" mt="lg" onClick={() => onSubmit()}>
        Submit
      </Button>
    </Card>
  );
}

ItemForm.defaultProps = {
  id: null,
  name: '',
  description: '',
};
