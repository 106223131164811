import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { FaStar, FaPhoneAlt, FaMobileAlt, FaEnvelope } from 'react-icons/fa';
import { Flex } from '@mantine/core';

import PhoneForm from './PhoneForm';
import EmailForm from './EmailForm';
import ContactNameplate from './ContactNameplate';
import { BasicButton, IconButton } from '@components/Button';

export default function ContactManagement({
  adminId,
  userId,
  vendorId,
  manufacturerId,
  distributorId,
  contacts,
  contactName,
  onUpdate,
}) {
  const [items, setItems] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [create, setCreate] = useState(false);
  const [variety, setVariety] = useState(1);
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    setItems(contacts.filter((f) => f.status !== 2));
  }, [JSON.stringify(contacts)]);

  function onClose() {
    setOpen(false);
    setCreate(false);
  }

  const formattedItems = items.sort((a, b) => b.primary - a.primary);

  return (
    <div>
      <BasicButton
        text="Add Contact"
        onClick={() => setCreate(true)}
        primary
        color="green"
        variant="filled"
      />
      <Flex direction="column" mt="md">
        {formattedItems.map((n) => (
          <ContactNameplate
            id={n.id}
            key={n.id}
            contactName={n.name}
            deletable={formattedItems.length > 1}
            description={n.description ? n.description : ''}
            contactString={n.contact_string}
            status={n.status}
            mobile={n.mobile}
            carrierName={n.phone ? n.phone.carrier_name : ''}
            callerName={n.phone ? n.phone.caller_name : ''}
            lastVerified={n.phone ? n.phone.last_verified_at : ''}
            primary={n.primary}
            onDelete={() => deleteNumber(n.id)}
            hasPrimary={contacts.find((n) => n.primary === true)}
            onUpdate={onUpdate}
            phoneId={n.phone_id}
            emailId={n.email_address_id}
            setEditData={() => {
              setEditData({
                description: n.description,
                value: n.raw_string,
                name: n.name,
                phone: n.phone,
                id: n.id,
              });
              setOpen(true);
            }}
          />
        ))}
      </Flex>
      <Modal open={create} onClose={onClose} size="medium">
        <Modal.Content>
          <div style={alignStyle}>
            <Button
              color={variety == 1 ? 'blue' : 'grey'}
              onClick={() => setVariety(1)}
              style={{
                ...alignStyle,
                opacity: variety == 1 ? 1 : 0.5,
              }}
            >
              <FaPhoneAlt
                size={22}
                fill="white"
                style={{ marginRight: '6px' }}
              />
              <p>Phone Number</p>
            </Button>
            <Button
              color={variety == 2 ? 'blue' : 'grey'}
              onClick={() => setVariety(2)}
              style={{
                ...alignStyle,
                opacity: variety == 2 ? 1 : 0.5,
              }}
            >
              <FaEnvelope
                size={22}
                fill="white"
                style={{ marginRight: '6px' }}
              />
              <p>Email Address</p>
            </Button>
          </div>
          {variety == 1 && (
            <PhoneForm
              contactName={contactName}
              adminId={adminId}
              userId={userId}
              vendorId={vendorId}
              distributorId={distributorId}
              manufacturerId={manufacturerId}
              onSuccess={() => {
                setOpen(false);
                setCreate(false);
                onUpdate();
              }}
            />
          )}
          {variety == 2 && (
            <EmailForm
              contactName={contactName}
              adminId={adminId}
              userId={userId}
              vendorId={vendorId}
              distributorId={distributorId}
              manufacturerId={manufacturerId}
              onSuccess={() => {
                setOpen(false);
                setCreate(false);
                onUpdate();
              }}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button content="Close" basic size="small" onClick={onClose} />
        </Modal.Actions>
      </Modal>

      <Modal open={isOpen} onClose={onClose} size="medium">
        {editData && (
          <Modal.Content>
            {editData.phone && (
              <PhoneForm
                id={editData.id}
                value={editData.value}
                contactName={editData.name}
                description={editData.description}
                adminId={adminId}
                userId={userId}
                vendorId={vendorId}
                distributorId={distributorId}
                manufacturerId={manufacturerId}
                onSuccess={() => {
                  setOpen(false);
                  onUpdate();
                  setEditData(null);
                }}
              />
            )}
            {!editData.phone && (
              <EmailForm
                id={editData.id}
                value={editData.value}
                contactName={editData.name}
                description={editData.description}
                adminId={adminId}
                userId={userId}
                vendorId={vendorId}
                distributorId={distributorId}
                manufacturerId={manufacturerId}
                onSuccess={() => {
                  setOpen(false);
                  onUpdate();
                  setEditData(null);
                }}
              />
            )}
          </Modal.Content>
        )}
        <Modal.Actions>
          <Button content="Close" basic size="small" onClick={onClose} />
        </Modal.Actions>
      </Modal>
    </div>
  );
}

ContactManagement.defaultProps = {
  adminId: null,
  userId: null,
  vendorId: null,
  contacts: [],
  phone: {},
  title: 'Contacts',
};

const alignStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 20px',
  cursor: 'pointer',
  fontSize: '16px',
  fontWeight: 'bold',
};
