import React, { useState, useEffect } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

import { RemoveButton, IconButton } from '@components/Button';

const RemoveConfirmButton = ({ onClick }) => {
  const [showConfirm, setShowConfirm] = useState(false);

  if (showConfirm) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <p style={{ marginBottom: '0', marginRight: '8px' }}>
          <b>Confirm</b>
        </p>
        <div>
          <IconButton
            onClick={onClick}
            icon={<FaCheckCircle color="var(--primaryColor500)" size={18} />}
            title="Confirm"
          />
          <IconButton
            onClick={() => setShowConfirm(false)}
            title="Cancel"
            icon={<FaTimesCircle color="var(--negative)" size={18} />}
          />
        </div>
      </div>
    );
  }

  return (
    <RemoveButton
      onClick={() => setShowConfirm(true)}
      iconProps={{ color: 'var(--negative)', size: 18 }}
    />
  );
};

export default RemoveConfirmButton;
