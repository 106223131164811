import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ViewError } from '@components/shared';

import ApprovalFlow from '@components/Code/ApprovalFlow';
import PasswordForm from '@components/Code/PasswordForm';

export default function CodeLanding({ match }) {
  const [codeData, setCodeData] = useState(null);
  const [error, setError] = useState(null);
  const { code } = match.params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/remote-codes/${code}/`)
      .then(({ data }) => {
        setCodeData(data.response[0]);
      })
      .catch((err) => {
        setCodeData(null);
        setError(err);
      });
  }

  if (error) {
    return (
      <div style={{ padding: '50px' }}>
        <ViewError text={error} />
      </div>
    );
  }

  if (!codeData) return <div></div>;

  if (codeData.offering_approval_authority_id) {
    return (
      <ApprovalFlow
        authJoinId={codeData.offering_approval_authority_id}
        phoneId={codeData.phone_id}
      />
    );
  }

  if (codeData.admin_id) {
    return <PasswordForm adminId={codeData.admin_id} codeId={codeData.id} />;
  }

  return <div>CodeLanding</div>;
}
