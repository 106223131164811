import React from 'react';
import { Button, Form, Label } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';

export default function CustomInputComponent({
  field,
  form: { touched, errors },
  ...props
}) {
  if (props.select) {
    return (
      <Form.Field>
        <Form.Select
          error={errors[field.name] && touched[field.name]}
          value={field.value}
          label={props.label}
          onBlur={(e, d) =>
            field.onBlur({
              target: {
                name: d.name,
                value: d.value,
              },
            })
          }
          onChange={(e, d) =>
            field.onChange({
              target: {
                name: d.name,
                value: d.value,
              },
            })
          }
          options={props.options}
          placeholder="Select one..."
          name={field.name}
          search
        />
        {touched[field.name] && errors[field.name] && (
          <Label basic style={{ marginTop: '0.25em' }} pointing>
            {errors[field.name]}
          </Label>
        )}
      </Form.Field>
    );
  }

  if (props.datepicker) {
    return (
      <Form.Field>
        <label>{props.label}</label>
        <DatePicker
          minDate={props.minDate ? props.minDate : null}
          onChange={(dateObj, t) =>
            field.onChange({
              target: { name: field.name, value: dateObj },
            })
          }
          // filterDate={props.dateFilter || null}
          name={field.name}
          placeholderText="Select a date..."
          selected={field.value ? new Date(field.value) : null}
          showYearDropdown
          dropdownMode="select"
        />
        {touched[field.name] && errors[field.name] && (
          <Label basic color="red" style={{ marginTop: '0.25em' }}>
            {errors[field.name]}
          </Label>
        )}
      </Form.Field>
    );
  }

  if (props.textarea) {
    return (
      <Form.Field>
        <Form.TextArea
          name={field.name}
          onBlur={field.onBlur}
          onChange={field.onChange}
          label={props.label}
          value={field.value}
          error={errors[field.name] && touched[field.name]}
        />
        {touched[field.name] && errors[field.name] && (
          <Label basic style={{ marginTop: '0.25em' }} pointing>
            {errors[field.name]}
          </Label>
        )}
      </Form.Field>
    );
  }

  return (
    <Form.Field>
      <Form.Input
        name={field.name}
        type={props.password ? 'password' : 'text'}
        onBlur={field.onBlur}
        onChange={field.onChange}
        label={`${props.label}${!props.required ? ' (optional)' : ''}`}
        value={field.value}
        error={errors[field.name] && touched[field.name]}
      />
      {touched[field.name] && errors[field.name] && (
        <Label basic style={{ marginTop: '0.25em' }} pointing>
          {errors[field.name]}
        </Label>
      )}
    </Form.Field>
  );
}
