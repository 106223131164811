export const locations = [
  { text: 'Chest', value: 1 },
  { text: 'Back', value: 2 },
  { text: 'Right Sleeve', value: 3 },
  { text: 'Left Sleeve', value: 4 },
  { text: 'Wrist', value: 5 },
  { text: 'Shoulder', value: 6 },
  { text: 'Front', value: 7 },
  { text: 'Chest Right Pocket', value: 8 },
  { text: 'Chest Left Pocket', value: 9 },
  { text: 'Hood', value: 10 },
  { text: 'Side', value: 11 },
  { text: 'Lid', value: 12 },
  { text: 'Collar', value: 13 },
  { text: 'Yoke', value: 14 },
].sort((a, b) => (a.text > b.text ? 1 : -1));

export const formattedLocations = locations.map((m) => m.text);

export const imprints = [
  { text: 'Embroidery', value: 1 },
  { text: 'Silkscreen', value: 2 },
  { text: 'Digital Transfer', value: 3 },
  { text: 'Digital Print Transfer', value: 4 },
  { text: 'Emboss', value: 5 },
  { text: 'Deboss', value: 6 },
  { text: 'Engrave', value: 7 },
  { text: 'Laser', value: 8 },
  { text: 'Direct To Garment', value: 9 },
  { text: 'Sublimation', value: 10 },
  { text: '3D Raised', value: 11 },
].sort((a, b) => (a.text > b.text ? 1 : -1));

export const formattedImprints = imprints.map((m) => m.text);

export const alignments = [
  { text: 'Upper', value: 1 },
  { text: 'Lower', value: 2 },
  { text: 'Center', value: 3 },
  { text: 'Full', value: 4 },
  { text: 'Left', value: 5 },
  { text: 'Right', value: 6 },
  { text: 'Rear', value: 7 },
  { text: 'Top', value: 8 },
  { text: 'Bottom', value: 8 },
].sort((a, b) => (a.text > b.text ? 1 : -1));

export const formattedAlignments = alignments.map((m) => m.text);
