import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Flex, Card, Group } from '@mantine/core';

import { SearchInput } from '@components/shared';
import { AdminTable } from './';

export default function AdminSearch() {
  const [loading, setLoading] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  useEffect(() => {
    fetchAdmins();
  }, []);

  useEffect(() => {
    fetchAdmins();
  }, [searchValue]);

  function fetchAdmins() {
    setLoading(true);
    axios
      .post(`/retrieve-admins/`, { search_value: searchValue })
      .then(({ data }) => {
        setLoading(false);
        setAdmins(data.response);
      })
      .catch((err) => {
        setLoading(false);
        setAdmins([]);
      });
  }

  return (
    <Flex direction="column">
      <Group w={100} mb="xs">
        <SearchInput onChange={(e) => setSearchValue(e)} value={searchValue} />
      </Group>
      <AdminTable data={admins} loading={loading} />
    </Flex>
  );
}

const SearchField = ({ value, onChange }) => {
  return <SearchInput value={value} onChange={(e) => onChange(e)} />;
};
