import React from 'react';
import { Text, Group, ThemeIcon, Flex, Card } from '@mantine/core';

import { Icon } from '@components/shared';

export default function ItemMeta({ name, description, isOffering }) {
  const formattedDescription = description;

  return (
    <Flex direction="column" p={0} bg="transparent">
      <Text size={55} ff="Open Sans, sans-serif" fw="lighter">
        {name}
      </Text>
      <Group mb="xl">
        <ThemeIcon color={isOffering ? 'teal' : 'orange'}>
          <Icon.Item size={16} />
        </ThemeIcon>
        <Text
          size="lg"
          color={isOffering ? 'teal' : 'orange'}
          ff="Open Sans, sans-serif"
        >
          {isOffering ? 'Offering Item' : 'Item'}
        </Text>
      </Group>
      {description && (
        <Card p={8} bg="#fff" mb="md">
          <Text size={20} ff="Open Sans, sans-serif" fw="normal">
            {description}
          </Text>
        </Card>
      )}
    </Flex>
  );
}

ItemMeta.defaultProps = {
  name: '',
  description: '',
};
