import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  button + button {
    margin-left: 5px;
  }
`;

export default function ButtonBar({ children, style }) {
  return <Wrapper style={style}>{children}</Wrapper>;
}

ButtonBar.defaultProps = {
  style: {},
};
