import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function ImageSlider({ images }) {
  if (!images.length) return null;

  return (
    <Slider {...settings}>
      {images.map((img) => (
        <img key={img.key} src={img.src} />
      ))}
    </Slider>
  );
}

ImageSlider.defaultProps = {
  images: [],
};
