import React from 'react';

import {
  formattedAlignments,
  formattedLocations,
  formattedImprints,
} from './helpers';

export default function PlacementText({ json }) {
  const parsed = JSON.parse(json);
  if (!json || json === '{}') return null;

  return (
    <p
      style={{
        textAlign: 'center',
        padding: '10px 0',
        color: '#222',
      }}
    >
      <div
        style={{
          fontSize: '18px',
          // fontWeight: 'lighter',
        }}
      >
        {formattedImprints[parsed.imprint - 1]}
      </div>
      <br />
      <br />
      <p>{formattedLocations[parsed.location - 1]}</p>
      <br />
      {/* <span>
        {new Intl.ListFormat('en', {
          style: 'short',
          type: 'conjunction',
        }).format(parsed.alignments.map((m) => formattedAlignments[m - 1]))}
      </span> */}
    </p>
  );
}

PlacementText.defaultProps = {
  json: null,
};
