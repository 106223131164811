import React from 'react';
import {
  FaCheckCircle,
  FaShoppingCart,
  FaWarehouse,
  FaTruck,
} from 'react-icons/fa';
import { Group, Text, Badge, Flex, ThemeIcon } from '@mantine/core';

import { ObjectBlock } from '@components/shared';

export default function PartnerTable({ data, loading }) {
  function getIcon(cls) {
    if (cls === 'vendor') {
      return <FaShoppingCart size={16} />;
    }
    if (cls === 'distributor') {
      return <FaTruck size={16} />;
    }
    if (cls === 'manufacturer') {
      return <FaWarehouse size={16} />;
    }
  }

  function getColor(cls) {
    if (cls === 'vendor') {
      return 'indigo';
    }
    if (cls === 'distributor') {
      return 'teal';
    }
    if (cls === 'manufacturer') {
      return 'orange';
    }
  }

  return (
    <Flex direction="column" gap={1}>
      {data.map((d, i) => (
        <ObjectBlock
          key={i}
          title={
            <Group>
              <ThemeIcon color={getColor(d.class_name)}>
                {getIcon(d.class_name)}
              </ThemeIcon>
              <Text ff="Open Sans, sans-serif" fw="lighter">
                {d.name}
              </Text>
              {d.verified && (
                <FaCheckCircle title="verified" style={{ marginLeft: '5px' }} />
              )}
            </Group>
          }
          url={`/${d.class_name}s/${d.id}`}
        />
      ))}
    </Flex>
  );
}

PartnerTable.defaultProps = {
  data: [],
  loading: false,
};
