import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import styled from 'styled-components';
import { Form, Modal, Button, Icon, Header } from 'semantic-ui-react';
import {
  FaRegEye,
  FaHashtag,
  FaDollarSign,
  FaRegTrashAlt,
  FaPlusCircle,
  FaCog,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  ActionIcon,
  Text,
  Group,
  Card,
  ThemeIcon,
  Flex,
  Badge,
} from '@mantine/core';

export default function VendorItemList({ items, onUpdate }) {
  return (
    <div>
      {items.map((m, i) => (
        <Item
          key={i}
          name={m.name}
          status={m.status}
          id={m.id}
          itemNumber={m.item_number}
          fetchData={onUpdate}
          url={m.url}
          activePrice={m.active_price}
          prices={m.vendor_item_prices}
        />
      ))}
    </div>
  );
}

const Item = ({
  name,
  fetchData,
  id,
  url,
  itemNumber,
  status,
  activePrice,
  prices,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [vendorItemNumber, setVendorItemNumber] = useState('');
  const [newPrice, setNewPrice] = useState('');

  console.log(id);

  function onSubmit() {
    if (vendorItemNumber && vendorItemNumber !== itemNumber) {
      updateVendorItem();
    }
    if (newPrice && newPrice !== activePrice) {
      createVendorItemPrice();
    }
  }

  function pennify(p) {
    return p.replace('.', '');
  }

  function createVendorItemPrice() {
    axios
      .post(`/vendor-item-prices/`, {
        vendor_item_id: id,
        price_in_pennies: pennify(newPrice),
      })
      .then(() => {
        fetchData();
        toast.success('Added!');
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function updateVendorItem() {
    axios
      .put(`/vendor-items/${id}/`, { item_number: vendorItemNumber })
      .then(() => {
        setOpen(false);
        fetchData();
        toast.success('Updated!');
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function toggleVendorItem() {
    axios
      .get(`/vendor-items/${id}/toggle-status/`)
      .then(({ data }) => {
        setOpen(false);
        fetchData();
        {
          data.response[0] === 1
            ? toast.success('Reactivated!')
            : toast.success('Deactivated!');
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <div>
      <Card mt="sm" opacity={status === 1 ? 1 : 0.6} w="50%">
        <Group grow>
          <Flex direction="column">
            <Text ff="Open Sans, sans-serif" size={22}>
              <Link to={url}>{name}</Link>
            </Text>
            <Group>
              <Text ff="Open Sans, sans-serif" size="md">
                SKU/Item Number:
              </Text>
              <Text ff="Open Sans, sans-serif" fw="bold" c="blue" size="md">
                {itemNumber ? itemNumber : 'None'}
              </Text>
            </Group>
            {prices && prices.length > 0 && (
              <Flex direction="column">
                {prices
                  .filter((f) => f.status == 1)
                  .map((p) => {
                    return (
                      <Group>
                        <Text
                          ff="Open Sans, sans-serif"
                          opacity={1.45}
                          size="md"
                          color="green"
                          fw="bold"
                        >
                          {p.price_formatted}
                        </Text>
                      </Group>
                    );
                  })}
              </Flex>
            )}
          </Flex>
          <Group style={{ justifyContent: 'end' }} mr="lg" spacing="xl">
            <ActionIcon
              onClick={() => setOpen(true)}
              variant="filled"
              color="gray"
            >
              <FaCog size={16} />
            </ActionIcon>
            <ActionIcon
              onClick={() => toggleVendorItem()}
              variant="filled"
              color={status === 1 ? 'red' : 'teal'}
            >
              {status === 1 && <FaRegTrashAlt size={16} />}
              {status !== 1 && <FaPlusCircle size={16} />}
            </ActionIcon>
          </Group>
        </Group>
      </Card>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={isOpen}
      >
        <Header>Vendor item number</Header>
        <Modal.Content>
          <div style={{ padding: '10px' }}>
            <Form>
              <Form.Input
                label="Item Number"
                placeholder={itemNumber}
                value={vendorItemNumber}
                onChange={(e) => setVendorItemNumber(e.target.value)}
              />
            </Form>
          </div>
          <div style={{ padding: '10px' }}>
            <Form>
              <Form.Input
                label="Add a price (XX.XX format)"
                placeholder={activePrice}
                value={newPrice}
                onChange={(e) => setNewPrice(e.target.value)}
              />
            </Form>
            {prices && prices.length > 0 && (
              <Flex direction="column" mt="xl">
                <Text ff="Open Sans, sans-serif">Price History:</Text>
                {prices
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at),
                  )
                  .map((p) => {
                    return (
                      <Group grow>
                        <Text
                          ff="Open Sans, sans-serif"
                          color={p.status == 1 ? 'green' : 'grey'}
                          size={16}
                        >
                          {p.price_formatted}{' '}
                          {p.status == 1 ? '(current price)' : ''}
                        </Text>
                        <Text
                          ff="Open Sans, sans-serif"
                          align="end"
                          size={13}
                          color={p.status == 1 ? 'green' : 'grey'}
                        >
                          {p.created_at_formatted}
                        </Text>
                      </Group>
                    );
                  })}
              </Flex>
            )}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" inverted onClick={() => onSubmit()}>
            <Icon name="checkmark" /> Submit
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
