import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { ImCog } from 'react-icons/im';

import { BasicButton } from '@components/Button';
import { Text } from '@components/shared';

export default function OfferingSettings({
  authCount,
  approved,
  managerCount,
  offeringId,
  onUpdate,
  status,
  active,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function changePublishedStatus() {
    setError(null);
    axios
      .post(`/offerings/${offeringId}/change-published-status/`)
      .then(() => {
        onUpdate();
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
        setTimeout(() => {
          setError(null);
        }, 4000);
      });
  }

  function readyForApproval() {
    setError(null);
    axios
      .post(`/offerings/${offeringId}/mark-ready-for-approval/`)
      .then(() => {
        onUpdate();
      })
      .catch((err) => {
        setError(err);
        setTimeout(() => {
          setError(null);
        }, 4000);
        setLoading(false);
      });
  }

  function sendApprovalEmail() {
    setError(null);
    axios
      .post(`/send-approval-notification/`, { offering_id: offeringId })
      .then(() => onUpdate())
      .catch((err) => {
        setError(err);
        setTimeout(() => {
          setError(null);
        }, 4000);
      });
  }

  const btnProps = {
    loading,
    disabled: loading || error,
  };

  return (
    <div>
      {!active && (
        <div>
          {!approved && (
            <div>
              {(authCount === 0 || managerCount === 0) && status == 0 && (
                <MessageWrapper>
                  <ApprovalMessage text="Mark this offering as being Ready For Approval" />
                  <div className="offering-settings-button-disabled">
                    You must have one Approval Authority and Offering Manager
                  </div>
                </MessageWrapper>
              )}
              {status == 0 && authCount !== 0 && managerCount !== 0 && (
                <MessageWrapper>
                  <ApprovalMessage text="Mark this offering as being Ready For Approval" />
                  <BasicButton
                    {...btnProps}
                    text="Ready For Approval"
                    onClick={readyForApproval}
                  />
                </MessageWrapper>
              )}
              {status == 1 && (
                <MessageWrapper>
                  <ApprovalMessage
                    text="Send a text to notify approval authorities that
                    this offering is ready to be reviewed"
                  />
                  <BasicButton
                    {...btnProps}
                    text="Send"
                    primary
                    onClick={sendApprovalEmail}
                  />
                </MessageWrapper>
              )}
            </div>
          )}
          {!approved && status > 1 && (
            <MessageWrapper>
              <ApprovalMessage text="Make this offering live and notify the crew members" />
              <div className="offering-settings-button-disabled">
                This offering is not yet approved or it is denied
              </div>
            </MessageWrapper>
          )}
          {/* {approved && (
            <MessageWrapper>
              <ApprovalMessage
                text="Send a text to notify approval authorities that this
                offering is ready to be reviewed"
              />
              <div className="offering-settings-button-disabled">
                Already Approved!
              </div>
              <div className="approval-message">
                <ImCog /> Make this offering live and notify the crew members
              </div>
              <BasicButton
                {...btnProps}
                primary
                text="Publish!"
                onClick={changePublishedStatus}
              />
            </MessageWrapper>
          )} */}
        </div>
      )}
      {active && (
        <MessageWrapper>
          <div className="approval-message">
            <ImCog /> Unpublish this offering
          </div>
          <BasicButton
            {...btnProps}
            primary
            text="Unpublish"
            onClick={changePublishedStatus}
          />
        </MessageWrapper>
      )}
      {error && (
        <div style={{ textAlign: 'center', marginTop: '1.5em' }}>
          <Text.Error text={error} />
        </div>
      )}
    </div>
  );
}

const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  button {
    margin-top: 1em;
  }
`;

const ApprovalMessageStyled = styled.p`
  display: flex;
  align-items: center;
  font-size: 1.35em;
  font-weight: 600;
  margin-bottom: 0;

  svg {
    margin-right: 5px;
  }
`;

const ApprovalMessage = ({ text }) => {
  return (
    <ApprovalMessageStyled>
      <ImCog /> {text}
    </ApprovalMessageStyled>
  );
};

ApprovalMessage.defaultProps = {
  text: 'text',
};
