import axios from 'axios';

import { apiKey, timestamp, secureHash } from '@util/authInfo';

axios.defaults.baseURL = '/api';
axios.interceptors.request.use(
  (request) => {
    request.headers['api-key'] = apiKey;
    request.headers['timestamp'] = timestamp;
    request.headers['secure-hash'] = secureHash;

    if (
      requestWhitelist.includes(request.url) ||
      request.url.indexOf('/code/')
    ) {
      request.headers['internal'] = true;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    // * if response isn't set up with response codes
    if (response.data.response_code === undefined) {
      return response;
    }

    // return to login
    if (
      response.data.response_code === 2 &&
      response.data.response_message &&
      response.data.response_message === 'Back to Login'
    ) {
      return (window.location = `/`);
    }

    // ! throw the response message
    if (response.data.response_code !== 0) {
      throw response.data.response_message;
    }
    return response;
  },
  (error) => {
    let formattedError =
      typeof error !== 'string'
        ? `There was a problem. Try again later.`
        : error;

    return Promise.reject(formattedError);
  },
);

export default axios;

const requestWhitelist = [`/status/`, `/admin-login/`].map((w) => `${w}/`);
