import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Dropdown } from 'semantic-ui-react';

import { BasicForm } from '@components/shared';

export default function VendorForm({
  id,
  name,
  variety,
  websiteUrl,
  onSuccess,
}) {
  const [loading, setLoading] = useState(false);
  const [formVariety, setFormVariety] = useState(null);
  const [error, setError] = useState(null);

  const options = [
    {
      key: 1,
      text: 'Supplier',
      value: 1,
    },
    {
      key: 2,
      text: 'Decorator',
      value: 2,
    },
    {
      key: 3,
      text: 'Business to Business',
      value: 3,
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      setError(null);
    }, 5000);
  }, [error]);

  function onSubmit(formData) {
    setLoading(true);
    setError(null);

    if (formVariety) formData.variety = formVariety;

    if (id) {
      return onUpdate(formData);
    }

    onCreate(formData);
  }

  function onCreate(formData) {
    const req = { ...formData };

    axios
      .post(`/vendors/`, req)
      .then(({ data }) => {
        toast.success('Vendor Created!');
        setLoading(false);
        onSuccess(data.response[0].id);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  function onUpdate(formData) {
    const req = { ...formData };

    axios
      .put(`/vendors/${id}/`, req)
      .then(() => {
        setLoading(false);
        toast.success('Vendor Updated!');
        onSuccess();
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  const fields = [
    {
      name: 'name',
      label: 'Name',
      initialValue: name,
      required: true,
      schema: () => Yup.string().required('Required'),
    },
    {
      name: 'website_url',
      label: 'Website URL',
      initialValue: websiteUrl,
      required: false,
      schema: () => {},
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <Dropdown
          placeholder="Select a variety..."
          fluid
          selection
          options={options}
          onChange={(e, d) => {
            setFormVariety(d.value);
          }}
          value={formVariety ? formVariety : variety}
        />
      </div>
      <BasicForm
        error={error}
        fields={fields}
        loading={loading}
        onSubmit={onSubmit}
      />
    </div>
  );
}

VendorForm.defaultProps = {
  variety: null,
  id: null,
  name: '',
};
