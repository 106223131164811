import React, { useState, useEffect } from 'react';
import { Radio } from 'semantic-ui-react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Card, Group, Text } from '@mantine/core';

import { Message, SearchInput, Title } from '@components/shared';
import { BasicButton } from '@components/Button';
import { TagList } from './';

export default function TagManagement({
  taggings,
  offeringItemId,
  itemId,
  onUpdate,
}) {
  const [tags, setTags] = useState([]);
  const [value, setValue] = useState('');
  const [clone, setClone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setError(null);
    }, 5000);
  }, [error]);

  useEffect(() => {
    setValue('');
  }, [JSON.stringify(taggings)]);

  function fetchTags(value) {
    setValue(value);

    if (value.length > 0) {
      setValue(value);
    }

    if (value.length < 3) return;

    const req = {
      search: value,
      tag_only: true,
      tag_ids: [],
      scope: 'Tag',
    };

    if (itemId) {
      req.item_id = itemId;
    }

    if (offeringItemId) {
      req.offering_item_id = offeringItemId;
    }

    axios
      .post(`/tag-search/`, req)
      .then(({ data }) => setTags(data.response[0].tags))
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  function onCreate() {
    if (!value.length || (!itemId && !offeringItemId)) return;

    const req = {
      name: value,
      variety: 1,
      clone: clone,
    };

    if (itemId) {
      req.item_id = itemId;
    }

    if (offeringItemId) {
      req.offering_item_id = offeringItemId;
    }

    axios
      .post(`/tags/`, req)
      .then(() => toast.success('Created!'))
      .then(() => {
        setClone(false);
        setValue('');
        onUpdate();
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  return (
    <Group mt="xl" mb="xl">
      {error && (
        <div>
          <Message error={error} />
        </div>
      )}
      <Group align="center">
        <Text ff="Open Sans, sans-serif" fw="lighter" size={28}>
          Tags
        </Text>
        <SearchInput
          style={{ marginBottom: 0 }}
          placeholder="Find or create a tag"
          value={value}
          onChange={(e) => fetchTags(e)}
        />
        {value.length > 2 && (
          <Group>
            {!offeringItemId && (
              <Group>
                <Radio
                  label=""
                  slider
                  checked={clone}
                  onChange={() => setClone(!clone)}
                />
                <Text
                  ff="Open Sans, sans-serif"
                  size="lg"
                  c={clone ? 'violet' : 'dimmed'}
                >
                  Attach tags when cloning this item
                </Text>
              </Group>
            )}
            {!taggings.map((m) => m.tag.name).includes(value) && (
              <BasicButton
                text={`Create ${value}`}
                onClick={onCreate}
                color="violet"
              />
            )}
          </Group>
        )}
        {tags.length > 0 && value.length > 2 && (
          <TagList
            tags={tags.filter(
              (f) => !taggings.map((m) => m.tag.name).includes(f.name),
            )}
            associated={false}
            itemId={itemId}
            offeringItemId={offeringItemId}
            onUpdate={onUpdate}
            clone={clone}
          />
        )}
      </Group>
      <TagList taggings={taggings} onUpdate={onUpdate} />
    </Group>
  );
}

TagManagement.defaultProps = {
  createReqData: null,
  taggings: [],
};
