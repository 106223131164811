import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Dropdown } from 'semantic-ui-react';

import { BasicForm } from '@components/shared';

export default function DistributorForm({
  id,
  name,
  variety,
  websiteUrl,
  onSuccess,
}) {
  const [loading, setLoading] = useState(false);
  const [formVariety, setFormVariety] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setError(null);
    }, 5000);
  }, [error]);

  function onSubmit(formData) {
    setLoading(true);
    setError(null);

    if (formVariety) formData.variety = formVariety;

    if (id) {
      return onUpdate(formData);
    }

    onCreate(formData);
  }

  function onCreate(formData) {
    const req = { ...formData };

    axios
      .post(`/distributors/`, req)
      .then(({ data }) => {
        toast.success('Distributor Created!');
        setLoading(false);
        onSuccess(data.response[0].id);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  function onUpdate(formData) {
    const req = { ...formData };

    axios
      .put(`/distributors/${id}/`, req)
      .then(() => {
        setLoading(false);
        toast.success('Distributor Updated!');
        onSuccess();
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  const fields = [
    {
      name: 'name',
      label: 'Name',
      initialValue: name,
      required: true,
      schema: () => Yup.string().required('Required'),
    },
    {
      name: 'website_url',
      label: 'Website URL',
      initialValue: websiteUrl,
      required: false,
      schema: () => {},
    },
  ];

  return (
    <div>
      <BasicForm
        error={error}
        fields={fields}
        loading={loading}
        onSubmit={onSubmit}
      />
    </div>
  );
}

DistributorForm.defaultProps = {
  variety: null,
  id: null,
  name: '',
};
