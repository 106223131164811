import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
  /* color: red; */
`;

export default function ViewError({ text }) {
  return (
    <Wrapper>
      <h1>Oops!</h1>
      {text && <p>{text}</p>}
    </Wrapper>
  );
}

ViewError.defaultProps = {
  text: '',
};
