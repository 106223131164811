import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import './index.scss';
import { BasicForm } from '@components/shared';

export default function AdminUpdateForm({
  onSuccess,
  firstName,
  lastName,
  email,
  id,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setError(null);
    }, 5000);
  }, [error]);

  function onAdminUpdate(formData) {
    setLoading(true);

    let postData = {
      ...formData,
    };
    axios
      .put(`/admins/${id}/`, postData)
      .then(() => setLoading(false))
      .then(() => onSuccess())
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  let fields = [
    {
      name: 'first_name',
      label: 'First Name',
      showLabel: false,
      initialValue: firstName,
      required: true,
      schema: () => Yup.string().required('Required'),
    },
    {
      name: 'last_name',
      label: 'Last Name',
      showLabel: false,
      initialValue: lastName,
      required: true,
      schema: () => Yup.string().required('Required'),
    },
    {
      name: 'email',
      label: 'Email',
      showLabel: false,
      initialValue: email,
      required: true,
      schema: () => Yup.string().required('Required'),
    },
    // {
    //   name: 'phone',
    //   label: 'Phone',
    //   showLabel: false,
    //   initialValue: phone,
    //   required: true,
    //   schema: () => Yup.string().required('Required'),
    // },
  ];

  return (
    <div>
      <BasicForm
        error={error}
        fields={fields}
        loading={loading}
        onSubmit={onAdminUpdate}
      />
    </div>
  );
}

AdminUpdateForm.defaultProps = {
  buttonText: 'Update',
  loading: false,
  firstName: '',
  lastName: '',
  email: '',
  error: '',
  onSubmit: (d) => d,
};
