import React from 'react';
import styled from 'styled-components';
import { FaSadTear } from 'react-icons/fa';

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: var(--black500); */
  flex-direction: column;
  padding: 50px;
  margin: 2em auto;
  border-radius: 2px;
  /* border: 3px solid var(--primaryColor400); */
  /* color: #fff; */

  svg {
    fill: var(--primaryColor400);
  }

  h1 {
    margin: 10px 0 0 0;
  }
`;

export default function EmptyText({ text }) {
  return (
    <Wrapper>
      <FaSadTear size={40} />
      <h1>{text}</h1>
    </Wrapper>
  );
}

EmptyText.defaultProps = {
  text: 'Such emptiness...',
};
