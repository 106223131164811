import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './index.scss';
import Login from './Login';
import UserLogin from './UserLogin';
import CodeLanding from './CodeLanding';
import Code from '@components/Code';
import { Toaster } from 'react-hot-toast';

import logo from '@assets/images/logo-white.png';

export default function Auth(props) {
  return (
    <Router>
      <React.Fragment>
        <Toaster />
        <Switch>
          {/* <Route
            exact
            path="/"
            render={() => <p>Manager Login Coming Soon...</p>}
          /> */}
          <Route
            exact
            path="/"
            component={() => (
              <div
                style={{
                  padding: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#000',
                  height: '100%',
                }}
              >
                <a href="//www.flickstitch.com">
                  <img
                    src={logo}
                    alt="flickstitch logo"
                    style={{
                      width: '450px',
                      margin: '0 auto 2em auto',
                      display: 'block',
                    }}
                  />
                  <p style={{ fontSize: '2em'}}>
                    <strong>CLICK HERE</strong> to find out more about FLiCKSTiTCH and view our
                    gallery.
                  </p>
                </a>
              </div>
            )}
          />
          <Route exact path="/admin" component={Login} />
          <Route exact path="/code/:code" component={CodeLanding} />
          {/* <Route path="*">
            <UserLogin restricted />
          </Route> */}
        </Switch>
      </React.Fragment>
    </Router>
  );
}
