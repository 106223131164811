import React from 'react';
import styled from 'styled-components';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';

import { IconButton } from '@components/Button';

const iconProps = {
  size: 40,
};

export default function Nav({
  onBackClick,
  onNextClick,
  backDisabled,
  nextDisabled,
}) {
  const btns = [
    {
      text: 'Back',
      onClick: () => onBackClick(),
      disabled: backDisabled,
      icon: (
        <FaArrowAltCircleLeft
          {...iconProps}
          color={backDisabled ? '#eee' : 'var(--primaryColor500)'}
        />
      ),
    },
    {
      text: 'Next',
      onClick: () => onNextClick(),
      disabled: nextDisabled,
      icon: (
        <FaArrowAltCircleRight
          {...iconProps}
          color={nextDisabled ? '#eee' : 'var(--primaryColor500)'}
        />
      ),
    },
  ].map((m, i) => ({ ...m, key: i + 1 }));

  return (
    <ButtonWrapper>
      {btns.map((b) => (
        <IconButton
          icon={b.icon}
          onClick={b.onClick}
          key={b.key}
          disabled={b.disabled}
        />
      ))}
    </ButtonWrapper>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 50%;
  }
`;
