import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  FaCalendarAlt,
  FaRegNewspaper,
  FaFilm,
  FaHashtag,
  FaStore,
} from 'react-icons/fa';
import { Group, Text, Card, Flex, Badge, ThemeIcon } from '@mantine/core';

import { ObjectBlock, Icon } from '@components/shared';

export default function OfferingTable({ offerings, fetchData }) {
  const [searchValue, setSearchValue] = useState('');

  const cardStyle = {
    border: '1px solid #ccc',
    borderRadius: '7px',
    background: '#fff',
  };

  useEffect(() => {
    fetchData();
  }, [searchValue]);

  if (!offerings.length) return null;

  const statuses = ['grey', 'yellow', 'teal', 'green', 'red'];

  return (
    <Flex direction="column">
      {offerings.map((offering, i) => (
        <Card style={cardStyle} key={i} mb="sm">
          <Group>
            <Text
              c="#222"
              ff="Open Sans, sans-serif"
              size={24}
              component={Link}
              to={`/offerings/${offering.id}`}
            >
              {offering.name}
            </Text>
            {offering.status_formatted && (
              <Badge
                variant="light"
                size="md"
                color={statuses[offering.status]}
              >
                {offering.status_formatted}
              </Badge>
            )}
          </Group>
          <Group spacing="xl">
            <Group spacing={12} mt={4} c="#222">
              <ThemeIcon color="cyan" variant="light">
                <Icon.Project size={22} />
              </ThemeIcon>

              <Text fw="lighter" ff="Open Sans, sans-serif" size="lg">
                {offering.project_name}
              </Text>
            </Group>
            <Group spacing={12} mt={4} c="#222">
              {offering.selected_vendors &&
                offering.selected_vendors.length > 0 &&
                offering.selected_vendors.map((v, i) => {
                  return (
                    <Group spacing={12} c="#222">
                      <ThemeIcon color="violet" variant="light">
                        <Icon.Partner size={22} />
                      </ThemeIcon>
                      <Text
                        fw="lighter"
                        ff="Open Sans, sans-serif"
                        key={i}
                        size="lg"
                      >
                        {v.vendor_name} ({v.active_price})
                      </Text>
                    </Group>
                  );
                })}
            </Group>
            <Group
              mt="xs"
              c="dimmed"
              align="center"
              style={{ position: 'absolute', right: '1em', bottom: '1em' }}
            >
              <FaCalendarAlt />
              <Text size="sm" ff="Open Sans, sans-serif">
                {offering.created_at_formatted}
              </Text>
            </Group>
          </Group>
        </Card>
      ))}
    </Flex>
  );
}

OfferingTable.defaultProps = {
  offerings: [],
};
