import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Group, ThemeIcon, Text, ActionIcon } from '@mantine/core';
import { BiLogOutCircle } from 'react-icons/bi';

import { Icon } from '@components/shared';
import logo from '@assets/images/logo-white.png';

const WrapperContainer = styled.div`
  background: var(--black100);
  border-bottom: 3px solid var(--primaryColor400);
`;

function Header({ admin }) {
  function onLogout() {
    axios
      .post('/logout/')
      .then(() => (window.location = admin ? '/admin' : '/'));
  }

  return (
    <Group bg="#222" p="md" pl="xl" spacing={6}>
      <img
        src={logo}
        className="logo"
        width={150}
        height={65}
        style={{ marginRight: '1.6rem' }}
      />
      {links.map((l, i) => (
        <NavLink
          key={i}
          to={l.to}
          title={l.text}
          activeClassName="active"
          strict
        >
          <Group ml="xl" pl={20} pr={20} spacing={8} mr="md">
            <ThemeIcon variant="outline" color={l.color}>
              {l.icon}
            </ThemeIcon>
            <Text size="xl" c={l.color} ff="Open Sans, sans-serif" fw="normal">
              {l.text}
            </Text>
          </Group>
        </NavLink>
      ))}
      <ActionIcon
        style={{ position: 'absolute', right: '1.5em' }}
        onClick={onLogout}
        color="gray"
        variant="outline"
        c="#ccc"
        p="md"
      >
        <BiLogOutCircle
          size={22}
          stroke={1.5}
          style={{ overflow: 'visible' }}
        />
      </ActionIcon>
    </Group>
  );
}

Header.defaultProps = {};

function mapStateToProps(state) {
  return {
    admin: state.admin,
  };
}

export default withRouter(connect(mapStateToProps)(Header));

const links = [
  {
    to: '/offerings',
    text: 'Offerings',
    icon: <Icon.Offering size={19} />,
    color: 'teal',
  },
  {
    to: '/projects',
    text: 'Projects',
    icon: <Icon.Project size={19} />,
    color: 'cyan',
  },
  {
    to: '/items',
    text: 'Catalog',
    icon: <Icon.Item size={19} />,
    color: 'orange',
  },
  {
    to: '/partners',
    text: 'Partners',
    icon: <Icon.Partner size={19} />,
    color: 'violet',
  },
  {
    to: '/customers',
    text: 'Customers',
    icon: <Icon.Customer size={19} />,
    color: 'blue',
  },
  {
    to: '/admins',
    text: 'Admins',
    icon: <Icon.Admin size={19} />,
    color: 'red',
  },
  {
    to: '/settings',
    text: 'Profile',
    icon: <Icon.Profile size={19} />,
    color: 'indigo',
  },
];
