import React from 'react';
import styled from 'styled-components';

const Text = styled.p`
  color: var(--negative);
  font-weight: bold;
`;

export default function ErrorText({ style, text }) {
  return (
    <Text ff="Open Sans, sans-serif" style={style}>
      {text}
    </Text>
  );
}

ErrorText.defaultProps = {
  text: 'error',
  style: {},
};
