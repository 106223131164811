import React from 'react';

import styled from 'styled-components';

const Block = styled.div`
  height: ${(props) => `${props.height}`};
  width: ${(props) => `${props.width}`};
  border-radius: 8px;
  background: ${(props) => `${props.value}`};
`;

export default function ColorBlock({ value, height, width }) {
  return <Block value={value} width={width} height={height} />;
}

ColorBlock.defaultProps = {
  height: `100px`,
  width: `100px`,
};
