import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Flex, Title, Group } from '@mantine/core';

import { OfferingTable } from '@components/Offering';
import { SearchInput, ObjectBlock, Icon } from '@components/shared';

export default function Offering() {
  const [loading, setLoading] = useState(false);
  const [offerings, setOfferings] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [searchValue]);

  function fetchData() {
    setLoading(true);

    axios
      .post(`/retrieve-offerings/`, { search_value: searchValue })
      .then(({ data }) => {
        setLoading(false);
        setOfferings(data.response);
      })
      .catch((err) => {
        setLoading(false);
        setOfferings([]);
      });
  }

  return (
    <Flex direction="column">
      <Group mb="md">
        <Title ff="Open Sans, sans-serif" size={35} fw="lighter">
          Offerings
        </Title>
      </Group>
      <Group w={100} mb="xs">
        <SearchInput onChange={(e) => setSearchValue(e)} value={searchValue} />
      </Group>
      <OfferingTable offerings={offerings} fetchData={fetchData} />
    </Flex>
  );
}
