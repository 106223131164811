import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import toast from 'react-hot-toast';

import { BasicForm, Title } from '@components/shared';

export default function EmailForm({
  id,
  adminId,
  userId,
  vendorId,
  value,
  onSuccess,
  description,
  contactName,
  manufacturerId,
  distributorId,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fields = [
    {
      name: 'name',
      label: 'Contact Name',
      initialValue: contactName,
      required: true,
      isEmail: true,
      schema: () => Yup.string().required('Required!'),
    },
    {
      name: 'address',
      label: 'Email Address',
      initialValue: value,
      required: true,
      isEmail: true,
      schema: () => Yup.string().required('Required!'),
    },
    {
      name: 'description',
      label: 'Description',
      initialValue: description,
      required: false,
      schema: () => {},
    },
  ];

  function onSubmit(formData) {
    setError(null);
    setLoading(true);

    const formatted = {
      ...formData,
    };

    if (adminId) {
      formatted.admin_id = adminId;
    }

    if (userId) {
      formatted.user_id = userId;
    }

    if (vendorId) {
      formatted.vendor_id = vendorId;
    }

    if (distributorId) {
      formatted.distributor_id = distributorId;
    }

    if (manufacturerId) {
      formatted.manufacturer_id = manufacturerId;
    }

    if (id) return onUpdate(formatted);

    return onCreate(formatted);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
    };

    axios
      .post(`/email-addresses/`, req)
      .then(() => {
        onSuccess();
        toast.success('Success!');
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      id,
    };

    axios
      .put(`/contact-methods/${id}/`, req)
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div style={{ padding: '3rem 5rem' }}>
      <Title text={id ? 'Update email address' : 'Add a new email address'} />
      <BasicForm
        error={error}
        fields={fields}
        loading={loading}
        onSubmit={onSubmit}
      />
    </div>
  );
}

EmailForm.defaultProps = {
  adminId: null,
  userId: null,
  vendorId: null,
  id: null,
  value: '',
  description: '',
  contactName: '',
};
