import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 850px;
  margin: 0 auto;

  label {
    font-size: 1.15em !important;
    margin-bottom: 4px !important;
  }

  input {
    font-size: 1.15em !important;
  }
`;

export default function FormWrapper({ children }) {
  return <Wrapper>{children}</Wrapper>;
}
