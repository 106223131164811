import React, { useState } from 'react';
import {
  FaStar,
  FaPhoneAlt,
  FaMobileAlt,
  FaEnvelope,
  FaUser,
} from 'react-icons/fa';
import { CgNotes } from 'react-icons/cg';
import { Card, Group, Text, Button, Flex } from '@mantine/core';

import axios from 'axios';
import toast from 'react-hot-toast';

import { utcToLocal } from '@util/formatters';

export default function ContactNameplate({
  id,
  contactString,
  mobile,
  carrierName,
  callerName,
  lastVerified,
  primary,
  onUpdate,
  deletable,
  description,
  phoneId,
  emailId,
  setEditData,
  contactName,
}) {
  const [loading, setLoading] = useState(false);
  const [verifying, setVerifying] = useState(false);

  const details = [
    {
      value: `${contactName ? contactName : 'No Name Available'}`,
      name: true,
      icon: <FaUser />,
    },
    { value: `${description}`, desc: true, icon: <CgNotes /> },
    { value: `${carrierName}`, desc: false },
    { value: `${callerName}`, desc: false },
    {
      value: `${
        lastVerified ? `Last verified ${utcToLocal(lastVerified)}` : ''
      }`,
      desc: false,
    },
  ].filter((f) => f.value && f.value.length > 1);

  function onPrimaryClick() {
    setLoading(true);

    axios
      .get(`/contact-methods/${id}/mark-primary/`)
      .then(() => {
        setLoading(false);
        toast.success(`New primary ${phoneId ? 'phone' : 'email'}!`);
        onUpdate();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function onRemoveClick() {
    setLoading(true);

    axios
      .delete(`/contact-methods/${id}/`)
      .then(() => {
        setLoading(false);
        toast.success('Removed!');
        onUpdate();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function onVerifyClick() {
    if (!phoneId) return;

    setVerifying(true);

    const req = {
      phone_id: phoneId,
    };

    axios
      .post(`/twilio-carrier-lookup/`, req)
      .then(() => {
        onUpdate();
        toast.success('Verified!');
        setVerifying(false);
      })
      .catch((err) => {
        toast.error(err);
        setVerifying(false);
      });
  }

  function truncateString(str, num) {
    if (!str) return '';
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }

  return (
    <div>
      <Card pl={15} pb="xs" radius={0}>
        <Group>
          {phoneId && mobile ? (
            <FaMobileAlt size={16} />
          ) : phoneId && !mobile ? (
            <FaPhoneAlt size={16} />
          ) : (
            <FaEnvelope size={16} />
          )}
          <Text ff="Open Sans, sans-serif" size={21}>
            {truncateString(contactString, 40)}
          </Text>
          {primary && <FaStar size={16} fill="goldenrod" />}
        </Group>
        <Flex direction="column">
          {details.map((m, i) => (
            <Text
              size="md"
              fw={m.name ? 'bold' : 'lighter'}
              key={i}
              mt="xs"
              color={m.name ? 'cyan' : m.desc ? '#333' : 'dimmed'}
            >
              {m.value}
            </Text>
          ))}
        </Flex>
      </Card>
      <Group
        spacing="xs"
        bg="white"
        p="sm"
        mb="xl"
        style={{ borderTop: '1px solid #e6e6e6' }}
      >
        {!primary && (
          <Button
            variant="light"
            loading={loading}
            disabled={loading}
            onClick={() => onPrimaryClick()}
            color="yellow"
          >
            Make Primary
          </Button>
        )}
        {phoneId && (
          <Button
            variant="light"
            loading={verifying}
            onClick={onVerifyClick}
            color="blue"
          >
            Verify
          </Button>
        )}
        <Button
          variant="light"
          color="blue"
          loading={loading}
          onClick={setEditData}
        >
          Edit
        </Button>
        <Button
          variant="light"
          disabled={!deletable || loading || primary}
          loading={loading}
          onClick={() => onRemoveClick()}
          color="red"
        >
          Remove
        </Button>
      </Group>
    </div>
  );
}

ContactNameplate.defaultProps = {
  carrierName: 'No carrier info',
  callerName: 'No caller name available',
};
