import React from 'react';
import axios from 'axios';

import { SizeTagList } from '@components/Tag';
import { ItemImages } from './';

export default function ItemSizeChart({
  assets,
  itemId,
  itemSizes,
  sizeTags,
  taggings,
  onFocusedClick,
  onUpdate,
}) {
  function onSizeClick(sizeId) {
    const req = {
      tag_id: sizeId,
      item_id: itemId,
    };

    axios
      .post(`/items/${itemId}/sizes/`, req)
      .then(() => onUpdate())
      .catch((err) => onUpdate());
  }

  return (
    <React.Fragment>
      <div style={{ marginBottom: '2em' }}>
        <ItemImages.List
          itemId={itemId}
          title="Spec Images"
          fetchData={onUpdate}
          assets={assets}
          isSizeChart
          onFocusedClick={onFocusedClick}
        />
      </div>
      <SizeTagList
        onSizeClick={onSizeClick}
        sizeTags={sizeTags}
        onUpdate={onUpdate}
        itemId={itemId}
        currentSizes={itemSizes}
      />
    </React.Fragment>
  );
}

ItemSizeChart.defaultProps = {
  assets: [],
  sizeTags: [],
  taggings: [],
};
