import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import toast from 'react-hot-toast';

import { BasicForm } from '@components/shared';

export default function ProjectForm({
  id,
  name,
  startDate,
  endDate,
  onSuccess,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setError(null);
    }, 5000);
  }, [error]);

  function onSubmit(formData) {
    setLoading(true);
    setError(null);

    if (id) {
      return onUpdate(formData);
    }

    onCreate(formData);
  }

  function onCreate(formData) {
    const req = { ...formData };

    axios
      .post(`/projects/`, req)
      .then(({ data }) => {
        toast.success('Project Created!');
        setLoading(false);
        onSuccess(data.response[0].id);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  function onUpdate(formData) {
    const req = { ...formData };

    axios
      .put(`/projects/${id}/`, req)
      .then(() => {
        setLoading(false);
        toast.success('Project Updated!');
        onSuccess();
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  const fields = [
    {
      name: 'name',
      label: 'Name',
      initialValue: name,
      required: true,
      schema: () => Yup.string().required('Required'),
    },
    {
      name: 'start_date',
      label: 'Start',
      initialValue: startDate,
      required: false,
      datepicker: true,
      schema: () => null,
    },
    {
      name: 'end_date',
      label: 'End',
      initialValue: endDate,
      required: false,
      datepicker: true,
      schema: () => null,
    },
  ].filter((f) => (!id ? !['start_date', 'end_date'].includes(f.name) : true));

  return (
    <BasicForm
      error={error}
      fields={fields}
      loading={loading}
      onSubmit={onSubmit}
    />
  );
}

ProjectForm.defaultProps = {
  endDate: null,
  id: null,
  name: '',
  startDate: null,
};
