import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Button, Form as SemanticForm } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { BasicButton } from '@components/Button';
import CustomInputComponent from './CustomInputComponent';

const cardStyle = {
  padding: '20px 0',
  margin: '15px 0',
};

export default function BasicForm({
  buttonColor,
  buttonText,
  error,
  fields,
  loading,
  onSubmit,
}) {
  const initialValues = {};
  const schemas = {};

  if (!fields.length) {
    return <p>Hey, no fields supplied!</p>;
  }

  fields.forEach((f) => {
    if (f.grouped) {
      f.fields.forEach((ff) => {
        initialValues[ff.name] = ff.initialValue;
        schemas[ff.name] = ff.schema();
      });
    } else {
      initialValues[f.name] = f.initialValue;
      schemas[f.name] = f.schema();
    }
  });

  const schema = Yup.object().shape(schemas);

  return (
    <div style={cardStyle}>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values) => onSubmit(values)}
        enableReinitialize
      >
        {() => (
          <Form className="ui form">
            {fields.map((f, i) => {
              if (f.grouped) {
                return (
                  <SemanticForm.Group widths="equal" key={i}>
                    {f.fields.map((ff, ii) => (
                      <Field
                        key={ii}
                        component={CustomInputComponent}
                        label={ff.label}
                        name={ff.name}
                        options={ff.options}
                        radio={ff.radio}
                        required={ff.required}
                        select={ff.select}
                        textarea={ff.textarea}
                        datepicker={ff.datepicker}
                      />
                    ))}
                  </SemanticForm.Group>
                );
              }

              return (
                <Field
                  key={i}
                  component={CustomInputComponent}
                  label={f.label}
                  name={f.name}
                  options={f.options}
                  password={f.password}
                  radio={f.radio}
                  required={f.required}
                  select={f.select}
                  textarea={f.textarea}
                  datepicker={f.datepicker}
                />
              );
            })}
            {error && (
              <p style={{ color: 'red', fontWeight: 'bold' }}>{error}</p>
            )}
            <div>
              <BasicButton
                text={buttonText}
                loading={loading}
                type="submit"
                primary
                disabled={loading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

BasicForm.defaultProps = {
  // buttonColor: "black",
  buttonText: 'Submit',
  error: '',
  loading: false,
  fields: [],
  onSubmit: (d) => d,
};

BasicForm.propTypes = {
  buttonColor: PropTypes.string,
  buttonText: PropTypes.string,
  // error: PropTypes.string,
  loading: PropTypes.bool,
  fields: PropTypes.array,
  onSubmit: PropTypes.func,
};
