import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Form, Dropdown, Button, Icon } from 'semantic-ui-react';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { Tabs, Text } from '@mantine/core';

import { BasicButton, ButtonBar } from '@components/Button';
import {
  ItemColors,
  ItemForm,
  ItemImages,
  ItemMeta,
  ItemSizeChart,
  VendorItemList,
} from '@components/Item';

import { OfferingTable } from '@components/Offering';

import { TagManagement } from '@components/Tag';
import { FormWrapper, Title, ViewError, TopBar } from '@components/shared';
import { PlacementConfig } from '@components/Placement';
import Notes from '@components/Notes';
import styled from 'styled-components';

const tabs = [{ text: 'Tags' }, { text: 'Partners' }, { text: 'Offerings' }];

const Layout = styled.div`
  display: flex;
  align-items: flex-start;

  .focused-wrapper {
    min-width: 50%;
    padding: 0 4em 0 0;
  }

  .details {
    min-width: 50%;
    padding: 3em;
    border-radius: 6px;
    border: 2px solid #ddd;
    background-color: #f7f7f7;
  }
`;

export default function ItemDetail({ match, isOfferingItem }) {
  const [focusedImage, setFocusedImage] = useState(null);
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [item, setItem] = useState(populateItem());
  const [sizeTags, setSizeTags] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [showDeleted, setShowDeleted] = useState(false);

  const { id } = match.params;

  useEffect(() => {
    fetchData();
    fetchSizeTags();
    fetchVendors();
  }, []);

  function fetchVendors() {
    axios
      .post(`/retrieve-vendors/`, {})
      .then(({ data }) => {
        setVendors(data.response);
      })
      .catch((err) => {
        setVendors([]);
        setError(err);
      });
  }

  function addVendors() {
    axios
      .post(`/items/${id}/add-vendors/`, { vendor_ids: selectedVendors })
      .then(() => {
        setSelectedVendors([]);
        fetchData();
      })
      .catch((err) => {
        setSelectedVendors([]);
        setError(err);
      });
  }

  function fetchSizeTags() {
    axios
      .get(`/size-tags/`)
      .then(({ data }) => {
        setSizeTags(data.response);
      })
      .catch((err) => {
        setSizeTags([]);
      });
  }

  function fetchData() {
    axios
      .get(`/items/${id}/`)
      .then(({ data }) => {
        setItem(populateItem(data.response[0]));

        if (data.response[0].display_large_url) {
          setFocusedImage({
            large_url: data.response[0].display_large_url,
          });
        }
      })
      .catch((err) => {
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  const vendorIds = item ? item.vendor_items.map((v) => v.vendor_id) : [];

  const filteredVendors =
    item && vendors.length > 0
      ? vendors
          .filter((f) => !vendorIds.includes(f.id) || f.status === 2)
          .map((m) => {
            return { text: m.name, value: m.id, key: m.id };
          })
      : [];

  const filteredVendorItems = showDeleted
    ? item.vendor_items
    : item.vendor_items.filter((f) => f.status === 1);

  return (
    <div>
      <TopBar variety="Item" />
      {!updating && (
        <React.Fragment>
          <Layout>
            <div className="focused-wrapper">
              {focusedImage && (
                <ItemImages.Focused
                  asset={{ large_filename: focusedImage.large_url }}
                />
              )}
            </div>
            <div className="details">
              <ItemMeta name={item.name} description={item.description} />
              <ButtonBar style={{ marginBottom: '4em' }}>
                <BasicButton
                  onClick={() => setUpdating(!updating)}
                  text="Update Item Title/Description"
                />
                {/* <CloneButton
                  itemId={id}
                  onSuccess={(newId) => (window.location = `/items/${newId}`)}
                /> */}
              </ButtonBar>
              <ItemImages.List
                itemId={id}
                fetchData={() => fetchData()}
                assets={item.images}
                isStock
                title="Stock Images"
                onFocusedClick={(e) => setFocusedImage(e)}
              />
              <StyledWrapper>
                <ItemSizeChart
                  itemId={id}
                  assets={item.size_charts}
                  sizeTags={sizeTags}
                  itemSizes={item.item_sizes}
                  taggings={item.taggings}
                  onUpdate={fetchData}
                  onFocusedClick={(e) => setFocusedImage(e)}
                />
              </StyledWrapper>
              <StyledWrapper>
                <ItemColors
                  itemId={id}
                  fetchUrl={`/items/${id}/colors/`}
                  fetchData={() => fetchData()}
                />
              </StyledWrapper>
              <PlacementConfig
                itemId={id}
                fetchData={fetchData}
                placementData={item.placement_data}
              />
            </div>
          </Layout>
          <StyledWrapper>
            <Tabs defaultValue={tabs[0].text} variant="pills" mb="xl">
              <Tabs.List mb="xl">
                {tabs.map((m) => (
                  <Tabs.Tab value={m.text}>
                    <Text ff="Open Sans, sans-serif" size={16}>
                      {m.text}
                    </Text>
                  </Tabs.Tab>
                ))}
              </Tabs.List>
              <Tabs.Panel mih={750} value="Tags">
                <div style={{ marginBottom: '500px' }}>
                  <TagManagement
                    taggings={item.taggings}
                    itemId={id}
                    onUpdate={fetchData}
                    size={false}
                  />
                </div>
              </Tabs.Panel>
              <Tabs.Panel mih={750} value="Partners">
                <StyledWrapper>
                  <Form onSubmit={() => addVendors()}>
                    <Title text="Add Vendor(s)" />
                    <Dropdown
                      fluid
                      selection
                      // onClick={() => fetchVendors()}
                      onChange={(e, d) => setSelectedVendors(d.value)}
                      options={filteredVendors}
                      multiple
                    />
                    <Form.Button
                      style={{ margin: '15px 0' }}
                      color="blue"
                      disabled={selectedVendors.length === 0}
                    >
                      Add Vendor(s)
                    </Form.Button>
                  </Form>
                  <div>
                    <Title text="Vendor(s) with this item" />
                    {item.vendor_items.filter((f) => f.status === 2).length >
                      0 && (
                      <button
                        className={showDeleted ? 'hide' : 'show'}
                        onClick={() => setShowDeleted(!showDeleted)}
                        style={{
                          display: 'flex',
                          border: 'none',
                          backgroundColor: 'transparent',
                          cursor: 'pointer',
                        }}
                      >
                        {showDeleted ? (
                          <FaRegEyeSlash style={{ marginRight: '5px' }} />
                        ) : (
                          <FaRegEye style={{ marginRight: '5px' }} />
                        )}

                        {`${showDeleted ? 'Hide' : 'Show'} deleted vendors`}
                      </button>
                    )}
                    <VendorItemList
                      onUpdate={fetchData}
                      items={filteredVendorItems.map((m) => ({
                        id: m.id,
                        status: m.status,
                        url: `/vendors/${m.vendor_id}`,
                        name: m.vendor_name,
                        item_number: m.item_number,
                        vendor_item_prices: m.vendor_item_prices,
                      }))}
                    />
                  </div>
                </StyledWrapper>
              </Tabs.Panel>
              <Tabs.Panel mih={750} value="Offerings">
                <Title text="Offerings" />
                <OfferingTable
                  offerings={item.offerings.map((m, i) => ({
                    id: m.offering_id,
                    name: m.offering_name,
                    project_name: m.project_name,
                    created_at_formatted: m.created_at_formatted,
                    selected_vendors: m.selected_vendors,
                    key: i,
                  }))}
                  fetchData={fetchData}
                />
              </Tabs.Panel>
            </Tabs>
          </StyledWrapper>
          <Notes
            additionalCreateData={{ item_id: id }}
            additionalFetchData={{ item_id: id }}
          />
        </React.Fragment>
      )}
      {updating && (
        <FormWrapper>
          <ItemForm
            id={id}
            name={item.name}
            description={item.description}
            onSuccess={() => {
              fetchData();
              setUpdating();
            }}
          />
          <BasicButton
            text="Cancel"
            onClick={() => setUpdating(false)}
            style={{ marginTop: '1em' }}
          />
        </FormWrapper>
      )}
    </div>
  );
}

const StyledWrapper = styled.div`
  padding: 2em 0;

  button {
    &.show {
      font-style: italic;
      color: #357b97;
      margin: 20px 0;

      &:hover {
        opacity: 0.65;
      }
    }

    &.hide {
      font-style: italic;
      color: #df7264;
      margin: 20px 0;

      &:hover {
        opacity: 0.65;
      }
    }
  }
`;

function populateItem(d = {}) {
  return {
    display_large_url: d.display_large_url || null,
    assets: d.assets || [],
    description: d.description || '',
    images: d.images || [],
    item_sizes: d.item_sizes || [],
    name: d.name || '',
    offerings: d.offering_items || [],
    placement_data: d.placement_data ? JSON.parse(d.placement_data) : {},
    price: d.price || '',
    sizes: d.item_sizes || [],
    vendor_items: d.vendor_items || [],
    size_charts: d.size_charts || [],
    taggings: d.taggings || [],
    large_filename: d.large_filename || '',
    vendor_item_prices: d.vendor_item_prices || [],
  };
}

ItemDetail.defaultProps = {
  isOfferingItem: false,
};

function CloneButton({ itemId, onSuccess }) {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    axios
      .post(`/items/${itemId}/clone/`)
      .then(({ data }) => {
        onSuccess(data.response[0].id);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }
  return (
    <BasicButton
      onClick={onClick}
      text="Clone This Item"
      primary
      loading={loading}
    />
  );
}
