import React, { useState } from 'react';
import {
  FaShoppingCart,
  FaUser,
  FaBoxOpen,
  FaMoneyBillAlt,
  FaEnvelope,
  FaWarehouse,
  FaTruck,
} from 'react-icons/fa';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Card, Group, Text, Button, Flex, Badge } from '@mantine/core';

import { utcToLocal } from '@util/formatters';

export default function AddressNameplate({
  id,
  onUpdate,
  deletable,
  fullAddress,
  createdAt,
  vendorId,
  userId,
  manufacturerId,
  distributorId,
  variety,
  entityName,
  varietyFormatted,
}) {
  const [loading, setLoading] = useState(false);

  const varieties = [
    { text: 'Shipping', color: 'teal', icon: <FaBoxOpen size={16} /> },
    { text: 'Mailing', color: 'cyan', icon: <FaEnvelope size={16} /> },
    { text: 'Invoice', color: 'green', icon: <FaMoneyBillAlt size={16} /> },
    { text: 'Business', color: 'indigo', icon: <FaShoppingCart size={16} /> },
  ];

  function onToggleStatus() {
    setLoading(true);

    axios
      .get(`/addresses/${id}/toggle-status/`)
      .then(() => {
        setLoading(false);
        toast.success('Removed!');
        onUpdate();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  const details = [
    {
      value: (
        <Group>
          {vendorId ? (
            <FaShoppingCart style={iconProps} />
          ) : manufacturerId ? (
            <FaWarehouse style={iconProps} />
          ) : distributorId ? (
            <FaTruck style={iconProps} />
          ) : (
            <FaUser style={iconProps} />
          )}
          {entityName}
        </Group>
      ),
      desc: false,
    },
    { value: `${utcToLocal(createdAt)}`, desc: false },
  ].filter((f) => f.value && f.value.length > 1);

  const iconProps = { marginRight: '9px', fill: '#12a3dd' };

  return (
    <div>
      <Card pl={15} pb="xs" radius={0}>
        <Group>
          <Text ff="Open Sans, sans-serif" size={18}>
            {fullAddress}
          </Text>
        </Group>
        <Badge size="lg" mt="sm" color={varieties[variety - 1].color}>
          <Group>
            {varieties[variety - 1].icon}
            {varieties[variety - 1].text}
          </Group>
        </Badge>
        <Flex direction="column">
          {details.map((m, i) => (
            <Text
              size="md"
              fw={m.name ? 'bold' : 'lighter'}
              key={i}
              mt="md"
              color={m.name ? 'cyan' : 'dimmed'}
            >
              {m.value}
            </Text>
          ))}
        </Flex>
      </Card>

      <Group
        spacing="xs"
        bg="white"
        p="sm"
        mb="xl"
        style={{ borderTop: '1px solid #e6e6e6' }}
      >
        <Button
          variant="light"
          disabled={!deletable || loading}
          loading={loading}
          onClick={() => onToggleStatus()}
          color="red"
        >
          Remove
        </Button>
      </Group>
    </div>
  );
}

AddressNameplate.defaultProps = {
  carrierName: 'No carrier info',
  callerName: 'No caller name available',
  entityName: '',
};
