import React from 'react';
import { IconButton } from './';
import { Icon } from '@components/shared';

export default function RemoveButton(props) {
  return <IconButton icon={<Icon.Remove {...props.iconProps} />} {...props} />;
}

RemoveButton.defaultProps = {
  disabled: false,
  iconProps: {
    color: 'var(--negative)',
  },
  title: '',
};
