import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Text, Group, ThemeIcon, Flex, Tabs } from '@mantine/core';

import { AdminUpdateForm, UpdatePasswordForm } from '@components/Settings';
import { Icon, ContactManagement, Title } from '@components/shared';

export default function Settings({}) {
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState({});

  const tabs = [
    { text: 'Details' },
    { text: 'Contacts' },
    { text: 'Change Password' },
  ];

  useEffect(() => {
    fetchAdmin();
  }, []);

  function fetchAdmin() {
    axios
      .get(`/current-admin/`)
      .then(({ data }) => {
        setLoading(false);
        setAdmin(data.response[0]);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  if (!admin) return null;

  return (
    <React.Fragment>
      <Flex direction="column">
        <Title
          page
          text={`${admin.full_name} (You)`}
          subtitle={
            <Group mb="xl">
              <ThemeIcon color="red">
                <Icon.Admin size={16} />
              </ThemeIcon>
              <Text
                ff="Open Sans, sans-serif"
                size="lg"
                fw="lighter"
                color="red"
              >
                Admin
              </Text>
            </Group>
          }
        />
        <Tabs defaultValue={tabs[0].text} variant="default">
          <Tabs.List mb="md">
            {tabs.map((m) => (
              <Tabs.Tab value={m.text} pt="xs" pb="xs">
                <Text ff="Open Sans, sans-serif" size="md" fw="lighter" p={0}>
                  {m.text}
                </Text>
              </Tabs.Tab>
            ))}
          </Tabs.List>
          <Tabs.Panel value="Details">
            <AdminUpdateForm
              onSuccess={() => fetchAdmin()}
              id={admin.id}
              firstName={admin.first_name}
              lastName={admin.last_name}
              email={admin.email}
            />
          </Tabs.Panel>
          <Tabs.Panel value="Contacts">
            <ContactManagement
              contacts={admin.contact_methods}
              adminId={admin.id}
              onUpdate={() => fetchAdmin()}
              contactName={admin.full_name}
            />
          </Tabs.Panel>
          <Tabs.Panel value="Change Password">
            <Text ff="Open Sans, sans-serif" size={25} fw="lighter">
              Change your password
            </Text>
            <UpdatePasswordForm onSuccess={() => fetchAdmin()} />
          </Tabs.Panel>
        </Tabs>
      </Flex>
    </React.Fragment>
  );
}
