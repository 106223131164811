import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import reduceReducers from 'reduce-reducers';
import initialState from './initialState';

import loadingReducer from './loading';
import adminReducer from './admin';
import userReducer from './user';

const rootReducer = reduceReducers(
  initialState,
  loadingReducer,
  userReducer,
  adminReducer,
);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(),
  // composeEnhancers(
  //   applyMiddleware(
  //     createSentryMiddleware(Sentry),
  //     thunk
  //   ),
  // )
);
