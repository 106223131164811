import { createHash } from 'crypto';

export const timestamp = +new Date();
export const secret = process.env.APP_SECRET;
export const apiKey = process.env.APP_KEY;
export const secureHash = createHash('sha256', secret)
  .update(`${apiKey}${secret}${timestamp}`)
  .digest('hex');

export const fileParams = `?api-key=${apiKey}&timestamp=${timestamp}&secure-hash=${secureHash}`;
