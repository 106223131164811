import React, { useState } from 'react';
import { Select } from 'semantic-ui-react';
import { Flex, Title, Group, ActionIcon } from '@mantine/core';

import {
  PartnerSearch,
  VendorForm,
  ManufacturerForm,
  DistributorForm,
} from '@components/Partner';
import { FormWrapper, Icon } from '@components/shared';

export default function PartnerIndex() {
  const [isOpen, setOpen] = useState(false);
  const [type, setType] = useState(null);

  return (
    <Flex direction="column">
      <Group mb="md">
        <Title ff="Open Sans, sans-serif" size={35} fw="lighter">
          Partners
        </Title>
        <ActionIcon
          variant="transparent"
          onClick={() => setOpen(!isOpen)}
          isOpen={isOpen}
        >
          <Icon.Create />
        </ActionIcon>
      </Group>
      {isOpen ? (
        <div>
          <Select
            placeholder="Select a type"
            options={[
              { key: 'vendor', text: 'Vendor', value: 'vendor' },
              {
                key: 'manufacturer',
                text: 'Manufacturer',
                value: 'manufacturer',
              },
              {
                key: 'distributor',
                text: 'Distributor',
                value: 'distributor',
              },
            ]}
            onChange={(e, d) => {
              setType(d.value);
            }}
          />
          {type === 'vendor' && (
            <FormWrapper>
              <VendorForm onSuccess={() => setOpen(false)} />
            </FormWrapper>
          )}
          {type === 'manufacturer' && (
            <FormWrapper>
              <ManufacturerForm onSuccess={() => setOpen(false)} />
            </FormWrapper>
          )}
          {type === 'distributor' && (
            <FormWrapper>
              <DistributorForm onSuccess={() => setOpen(false)} />
            </FormWrapper>
          )}
        </div>
      ) : (
        <PartnerSearch />
      )}
    </Flex>
  );
}
