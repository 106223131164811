const screenSizes = {
  small: {
    min: '0',
    max: '767px',
  },
  medium: {
    min: '768px',
    max: '1024px',
  },
  tablet: {
    min_landscape: '100px',
    max_landscape: '200px',
    min_portrait: '768px',
    max_portrait: '968px',
  },
};

export default screenSizes;
