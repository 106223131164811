import React, { useState } from 'react';
import axios from 'axios';

import UserLogin from '@components/Auth/UserLogin';
import CustomerApproval from '@components/CustomerApproval';

export default function ApprovalFlow({ authJoinId, phoneId }) {
  const [verified, setVerified] = useState(false);
  const [items, setItems] = useState([]);

  function fetchData() {
    axios
      .get(`/user-offering-approvals/${authJoinId}/`)
      .then(({ data }) => {
        setItems(data.response);
      })
      .catch((err) => {});
  }

  return (
    <React.Fragment>
      {!verified && (
        <UserLogin
          authJoinId={authJoinId}
          phoneId={phoneId}
          onSuccess={(items) => {
            setVerified(true);
            setItems(items);
          }}
        />
      )}
      {verified && (
        <React.Fragment>
          <CustomerApproval
            approvalItems={items}
            authJoinId={authJoinId}
            fetchData={fetchData}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
