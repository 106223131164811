import React from 'react';
import { FaCheckCircle, FaUserCircle } from 'react-icons/fa';
import { Group, ThemeIcon, Badge, Flex } from '@mantine/core';

import { ObjectBlock } from '@components/shared';

export default function CustomerTable({ data, loading }) {
  return (
    <Flex direction="column" gap={1}>
      {data.map((d, i) => (
        <ObjectBlock
          key={i}
          title={
            <Group>
              <ThemeIcon color="blue" p={2}>
                <FaUserCircle />
              </ThemeIcon>
              {d.full_name}
              {d.verified && (
                <ThemeIcon color="teal" p={2}>
                  <FaCheckCircle title="Verified Customer" />
                </ThemeIcon>
              )}
            </Group>
          }
          url={`/customers/${d.id}`}
        />
      ))}
    </Flex>
  );
}

CustomerTable.defaultProps = {
  data: [],
  loading: false,
};
