import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Switch, Route, Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import {
  ThemeIcon,
  Text,
  Group,
  Button,
  Tabs,
  Flex,
  Modal,
  FileButton,
} from '@mantine/core';
import { FaRegFileImage, FaFileAlt } from 'react-icons/fa';

import {
  EmailOfferingLink,
  OfferingAuthManagement,
  OfferingChecklist,
  OfferingItems,
  OfferingSettings,
  OfferingFormModal,
  OfferingOrderTable,
} from '@components/Offering';
import { BasicButton } from '@components/Button';
import { Title, Icon, ViewError } from '@components/shared';
import Notes from '@components/Notes';

export default function OfferingDetail({ match }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [offering, setOffering] = useState(populateOffering());
  const [sending, setSending] = useState(false);
  const [open, setOpen] = useState(false);
  const [pdfFilenameUrl, setPdfFilenameUrl] = useState(null);
  const [pdfFilename, setPdfFilename] = useState(null);

  const { id } = match.params;

  const tabs = [
    { text: 'Items' },
    { text: 'Presentations/Files' },
    { text: 'Approval Management' },
    { text: 'Checklist' },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/offerings/${id}/`)
      .then(({ data }) => {
        setLoading(false);
        setOffering(populateOffering(data.response[0]));
      })
      .catch((err) => {
        setError(err);
      });
  }

  function onSendClick() {
    setSending(true);

    axios
      .post(`/send-approval-notification/`, { offering_id: id })
      .then(() => {
        toast.success(`Sent!`);
        setSending(false);
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
        setSending(false);
      });
  }

  function onFileUpload(file) {
    const formData = new FormData();
    formData.append('tempfile', file);
    formData.append('variety', 8);
    formData.append('offering_id', id);

    setLoading(true);

    axios
      .post(`/asset-upload/`, formData)
      .then(() => {
        setLoading(false);
        toast.success('Uploaded!');
        fetchData();
      })
      .then(() => fetchData())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function setPdf(asset) {
    setPdfFilename(asset.filename);
    setPdfFilenameUrl(asset.filename_url);
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
    setPdfFilename(null);
    setPdfFilenameUrl(null);
  }

  if (error) {
    return <ViewError text={error} />;
  }

  console.log(pdfFilenameUrl);

  const checklistComplete = offering.checklist
    ? !Object.keys(offering.checklist)
        .map((m) => offering.checklist[m])
        .includes(false)
    : false;

  return (
    <div>
      <Title
        page
        text={
          <Group>
            <Text ff="Open Sans, sans-serif" fw="lighter">
              {offering.name}
            </Text>
            <OfferingFormModal
              id={id}
              name={offering.name}
              onSuccess={fetchData}
            />
          </Group>
        }
        subtitle={
          <Group mb="xl">
            <ThemeIcon color="teal">
              <Icon.Offering size={16} />
            </ThemeIcon>
            <Text ff="Open Sans, sans-serif" size="lg" color="teal">
              Offering for
            </Text>
            {offering.project && (
              <Link to={`/projects/${offering.project.id}/offerings`}>
                <Text ff="Open Sans, sans-serif" color="blue" size="lg">
                  {offering.project_name}
                </Text>
              </Link>
            )}
          </Group>
        }
      />
      <Tabs defaultValue={tabs[0].text} variant="default">
        <Tabs.List mb="md">
          {tabs.map((m) => (
            <Tabs.Tab value={m.text} pt="xs" pb="xs">
              <Text ff="Open Sans, sans-serif" size="md" fw="lighter">
                {m.text}
              </Text>
            </Tabs.Tab>
          ))}
        </Tabs.List>
        <Tabs.Panel value="Items">
          <OfferingItems
            offeringId={id}
            items={offering.items.map((m) => ({
              ...m,
              ...m.item,
              selected_offering_vendor_item: m.selected_offering_vendor_item,
              join_id: m.id,
            }))}
            onUpdate={fetchData}
            editable
          />
        </Tabs.Panel>
        <Tabs.Panel value="Presentations/Files">
          <FileButton onChange={(e) => onFileUpload(e)}>
            {(props) => (
              <Button
                loading={loading}
                disabled={loading}
                {...props}
                variant="light"
                color="green"
                leftIcon={<FaFileAlt size={16} />}
                size="md"
                ff="Open Sans, sans-serif"
                fw="normal"
              >
                Add File
              </Button>
            )}
          </FileButton>
          {offering.assets.map((asset) => {
            return (
              <Group direction="column" mt="sm">
                <Button
                  onClick={() => setPdf(asset)}
                  color="gray"
                  variant="subtle"
                  size="xs"
                  mb="xs"
                  ff="Open Sans, sans-serif"
                  fw="normal"
                >
                  view
                </Button>
                <object
                  width="100"
                  height="75"
                  data={asset.filename_url}
                ></object>
                <Text
                  ff="Open Sans, sans-serif"
                  size="lg"
                  align="center"
                  mb="md"
                  fw="lighter"
                >
                  {asset.filename}
                </Text>
              </Group>
            );
          })}
          <Modal
            onClose={() => closeModal()}
            onOpen={() => setOpen(true)}
            open={open}
            size="large"
          >
            <Modal.Content>
              <Text ff="Open Sans, sans-serif" size="xl" align="center" mb="md">
                {pdfFilename}
              </Text>
              <object width="100%" height="900" data={pdfFilenameUrl}></object>
            </Modal.Content>
          </Modal>
        </Tabs.Panel>
        <Tabs.Panel value="Approval Management">
          <div style={{ margin: '2em 0 3em 0' }}>
            <OfferingChecklist
              path={match.url}
              checklistData={offering.checklist}
            />
          </div>
          <OfferingAuthManagement
            authorities={offering.offering_approval_authorities}
            managers={offering.offering_managers}
            offeringId={id}
            onUpdate={fetchData}
            offeringStatus={offering.status}
          />
        </Tabs.Panel>
        <Tabs.Panel value="Checklist">
          {offering.items.length > 0 && (
            <OfferingItems
              offeringId={id}
              items={offering.items.map((m) => ({
                ...m,
                ...m.item,
                join_id: m.id,
              }))}
              onUpdate={fetchData}
              editable={false}
              isDecision
            >
              {offering.checklist && (
                <div style={{ marginBottom: '2em' }}>
                  <BasicButton
                    text="Send texts for approval"
                    onClick={onSendClick}
                    loading={sending}
                    disabled={!checklistComplete}
                    primary
                  />
                </div>
              )}
            </OfferingItems>
          )}
        </Tabs.Panel>
      </Tabs>
      <Notes
        additionalFetchData={{ offering_id: id }}
        additionalCreateData={{ offering_id: id }}
      />
      {/* <Route path={`${match.path}/orders`}>
          <React.Fragment>
            <Title text="Orders" icon={<Icon.Offering />} />
            <p>Coming soon...</p>
            <OfferingOrderTable data={offering.orders} />
          </React.Fragment>
        </Route>
        <Route path={`${match.path}/status`}>
          <React.Fragment>
            <Title text="Settings" icon={<Icon.Offering />} />
            <OfferingSettings
              active={offering.active}
              approved={offering.approved}
              authCount={offering.offering_approval_authorities.length}
              managerCount={offering.offering_managers.length}
              offeringId={id}
              onUpdate={fetchData}
              status={offering.status}
            />
          </React.Fragment>
        </Route> */}
    </div>
  );
}

function populateOffering(d = {}) {
  return {
    ...d,
    approved: d.approved || false,
    checklist: d.checklist || null,
    items: d.offering_items || [],
    offering_approval_authorities: d.offering_approval_authorities || [],
    offering_managers: d.offering_managers || [],
    offers: d.offers || [],
    name: d.name || '',
    project: d.project || null,
    status: d.status,
    assets: d.assets || [],
  };
}
