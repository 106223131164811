import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ThemeIcon, Text, Group, Card, Tabs, Flex } from '@mantine/core';
import { Icon, TopBar } from '@components/shared';

import { BasicButton } from '@components/Button';
import {
  ItemForm,
  ItemImages,
  ItemMeta,
  ItemColors,
  OfferingVendorItemSelect,
} from '@components/Item';
import { FormWrapper, ViewError } from '@components/shared';
import Notes from '@components/Notes';
import { ApprovalItemManagement } from '@components/Offering';
import { SizeTagList, TagManagement } from '@components/Tag';
import { PlacementConfig } from '@components/Placement';

const Layout = styled.div`
  display: flex;
  align-items: flex-start;

  .focused-wrapper {
    width: 50%;
  }

  .details {
    width: 50%;
    padding: 1em 3em;
  }
`;

export default function OfferingItemDetail({ match }) {
  const [focusedImage, setFocusedImage] = useState(null);
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [item, setItem] = useState(populateItem());
  const [sizeTags, setSizeTags] = useState([]);

  const { id } = match.params;

  useEffect(() => {
    fetchData();
    fetchSizeTags();
    window.scrollTo(0, 0);
  }, []);

  function fetchSizeTags() {
    axios
      .get(`/size-tags/`)
      .then(({ data }) => {
        setSizeTags(data.response);
      })
      .catch((err) => {
        setSizeTags([]);
      });
  }

  function fetchData() {
    axios
      .get(`/offering-items/${id}/`)
      .then(({ data }) => {
        const response = data.response[0];
        setItem(populateItem(response));

        if (response.display_large_url && !focusedImage) {
          setFocusedImage({
            large_url: response.display_large_url,
          });
        }
      })
      .catch((err) => {
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  const placementData = item.original_item
    ? item.original_item.placement_data
      ? JSON.parse(item.original_item.placement_data)
      : {}
    : {};

  return (
    <div>
      <TopBar variety="Offering Item" />
      {!updating && (
        <React.Fragment>
          <Layout>
            <div className="focused-wrapper">
              {focusedImage && (
                <ItemImages.Focused
                  asset={{ large_filename: focusedImage.large_url }}
                />
              )}
            </div>
            <div className="details">
              <ItemMeta
                isOffering
                name={item.name}
                description={
                  <React.Fragment>
                    <Group align="center">
                      <Text>Offering:</Text>
                      <b>
                        <Link to={`/offerings/${item.offering_id}/items`}>
                          <Text
                            ff="Open Sans, sans-serif"
                            c="teal"
                            size="xl"
                            fw="lighter"
                          >
                            {item.offering_name}
                          </Text>
                        </Link>
                      </b>
                    </Group>
                    {item.item && (
                      <Group align="center">
                        <Text>Catalog Item:</Text>
                        <b>
                          <Link to={`/items/${item.item.id}`}>
                            <Text
                              ff="Open Sans, sans-serif"
                              c="orange"
                              size="xl"
                              fw="lighter"
                            >
                              {item.item.name}
                            </Text>
                          </Link>
                        </b>
                      </Group>
                    )}
                  </React.Fragment>
                }
                price={item.price}
              />
              {item.original_item && (
                <React.Fragment>
                  <ItemImages.List
                    itemId={id}
                    fetchData={fetchData}
                    assets={item.original_item.images.map((m) => ({
                      ...m,
                      thumb_filename: m.thumb_url,
                      large_filename: m.large_url,
                    }))}
                    hideUpload
                    viewOnly
                    title="Stock Images"
                    onFocusedClick={(e) => setFocusedImage(e)}
                  />
                  <ItemImages.List
                    itemId={id}
                    fetchData={fetchData}
                    assets={item.original_item.size_charts.map((m) => ({
                      id: m.id,
                      thumb_url: m.thumb_url,
                      large_url: m.large_url,
                    }))}
                    hideUpload
                    viewOnly
                    title="Size Chart"
                    onFocusedClick={(e) => setFocusedImage(e)}
                  />
                  <ItemImages.List
                    isOffering
                    itemId={id}
                    fetchData={fetchData}
                    assets={item.images.map((m) => ({
                      id: m.id,
                      thumb_url: m.thumb_url,
                      large_url: m.large_url,
                    }))}
                    title="Finished Product"
                    onFocusedClick={(e) => setFocusedImage(e)}
                  />
                  <h5>Sizes</h5>
                  <SizeTagList
                    hideTitle
                    sizeTags={sizeTags}
                    currentSizes={item.original_item.item_sizes}
                    offeringItemId={id}
                    onUpdate={fetchData}
                  />
                  <h5>Colors</h5>
                  <ItemColors
                    showCreate={false}
                    viewOnly
                    showDelete={false}
                    itemId={item.original_item.id}
                    fetchUrl={`/items/${item.original_item.id}/colors/`}
                  />
                </React.Fragment>
              )}
            </div>
          </Layout>
          <PlacementConfig
            offeringItemId={id}
            fetchData={fetchData}
            placementData={placementData}
          />
          <OfferingVendorItemSelect
            offeringItemId={id}
            fetchData={fetchData}
            offeringVendorItems={item.offering_vendor_items}
          />
          <TagManagement
            taggings={item.taggings}
            offeringItemId={id}
            onUpdate={fetchData}
            size={false}
          />
          <ApprovalItemManagement
            id={id}
            itemId={item.item_id}
            items={item.approval_items}
            placementData={placementData}
            fetchData={fetchData}
            onFocusClick={(e) => setFocusedImage(e)}
          />
          <Notes
            additionalCreateData={{ item_id: id }}
            additionalFetchData={{ item_id: id }}
          />
        </React.Fragment>
      )}
      {updating && (
        <FormWrapper>
          <ItemForm
            id={id}
            name={item.name}
            description={item.description}
            onSuccess={() => {
              fetchData();
              setUpdating();
            }}
          />
          <BasicButton
            text="Cancel"
            onClick={() => setUpdating(false)}
            style={{ marginTop: '1em' }}
          />
        </FormWrapper>
      )}
    </div>
  );
}

function populateItem(d = {}) {
  return {
    approval_items: d.approval_items || [],
    images: d.images || [],
    item_id: d.item ? d.item.id : null,
    item: d.item || null,
    name: d.item ? d.item.name : '',
    offering_id: d.offering_id || null,
    offering_name: d.offering_name || '',
    original_item: d.item || null,
    taggings: d.taggings || [],
    offering_vendor_items: d.offering_vendor_items || [],
  };
}

OfferingItemDetail.defaultProps = {
  isOfferingItem: false,
};
