import React, { useState } from 'react';
import styled from 'styled-components';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { Text, Group } from '@mantine/core';

import { ColorBlock, Grid } from '@components/shared';
import { OfferingSendButton, EmailOfferingLink, ApprovalIcon } from './';
import { penniesToDollars } from '@util/formatters';
import { BasicButton } from '@components/Button';
import { PlacementText } from '@components/Placement';

import { varieties } from './approvalItemOptions';

export default function OfferingDecisionItems({ itemJoins }) {
  return (
    <div>
      {itemJoins.map((j) => (
        <DecisionItem
          key={j.id}
          item={j.item}
          approvalItems={j.approval_items}
        />
      ))}
    </div>
  );
}

OfferingDecisionItems.defaultProps = {
  itemJoins: [],
};

function DecisionItem({ id, item, approvalItems }) {
  const [activeVariety, setActiveVariety] = useState(null);

  if (!item) return null;

  const varietyOptions = [...approvalItems].reduce((acc, cur) => {
    const foundVariety = acc.find((f) => f.variety === cur.variety);

    if (!foundVariety) {
      acc.push({
        variety: cur.variety,
        variety_formatted: cur.variety_formatted,
      });
    }

    return acc;
  }, []);

  const filteredItems = [...approvalItems].filter((f) =>
    !activeVariety ? true : f.variety === activeVariety,
  );

  return (
    <StyledDecisionItem>
      <ItemMeta>
        <h3>
          {item.name} ({filteredItems.length})
        </h3>
        <ButtonBar>
          {[{ variety_formatted: 'all', variety: null }, ...varietyOptions].map(
            (m) => (
              <BasicButton
                style={{ width: 75 }}
                key={m.variety}
                variant={m.variety == activeVariety ? 'filled' : 'outline'}
                onClick={() => setActiveVariety(m.variety)}
                text={m.variety_formatted}
              />
            ),
          )}
        </ButtonBar>
      </ItemMeta>
      <Grid columns={4} gap={20}>
        {filteredItems.map((m) => (
          <ApprovalItem
            key={m.id}
            varietyFormatted={m.variety_formatted}
            variety={m.variety}
            approvals={m.approvals}
            colorValue={m.color_value}
            colorName={m.color}
            quantity={m.quantity}
            price={m.price_in_pennies}
            description={m.description}
            assets={m.assets}
          />
        ))}
      </Grid>
    </StyledDecisionItem>
  );
}

DecisionItem.defaultProps = {
  approvalItems: [],
};

const StyledDecisionItem = styled.div`
  & + & {
    margin-top: 4em;
  }
`;

const ItemMeta = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2em;

  h3 {
    margin: 0 1em 0 0;
  }
`;

const ButtonBar = styled.div`
  display: flex;

  button {
    font-size: 0.85em;
    padding: 5px;

    & + button {
      margin-left: 5px;
    }
  }
`;

function ApprovalItem({
  approvals,
  quantity,
  price,
  assets,
  description,
  varietyFormatted,
  variety,
  colorValue,
  colorName,
}) {
  const hasRejection = approvals.find(
    (f) => !f.approved && f.rejection_reason && f.answered_at,
  )
    ? true
    : false;

  const allApproved = !approvals
    .map((m) => (m.approved !== undefined ? m.approved : false))
    .includes(false);

  return (
    <StyledItem>
      <Group position="center" mb="xs">
        <ApprovalIcon variety={variety} color="var(--primaryColor400)" />{' '}
        <Text ff="Open Sans, sans-serif" size="lg">
          {varietyFormatted}
        </Text>
      </Group>
      <div className="content">
        {(allApproved || hasRejection) && (
          <div className="approval-status-icon">
            {allApproved && <FaCheckCircle color="var(--positive)" />}
            {hasRejection && <FaExclamationCircle color="var(--negative)" />}
          </div>
        )}
        {[varieties.art, varieties.mockup].includes(variety) && (
          <React.Fragment>
            {assets.map((asset) => (
              <div>
                <img key={asset.id} src={asset.large_url} />
                {variety === varieties.art && (
                  <PlacementText json={asset.placement_data} />
                )}
              </div>
            ))}
          </React.Fragment>
        )}
        {variety === varieties.color && (
          <React.Fragment>
            <ColorBlock height="150px" width="100%" value={colorValue} />
            <p style={{ textAlign: 'center', marginTop: '5px' }}>{colorName}</p>
          </React.Fragment>
        )}
        {[varieties.quantity, varieties.price, varieties.other].includes(
          variety,
        ) && (
          <React.Fragment>
            <p className="text-variety">
              {variety === 2 && `${quantity}`}
              {variety === 3 && `$${penniesToDollars(price)}`}
              {variety === 6 && `${description}`}
            </p>
          </React.Fragment>
        )}
        <div className="approvals">
          <StyledApprovalList>
            {approvals.map((m) => (
              <StyledApproval
                approved={m.approved}
                rejected={!m.approved && m.rejection_reason}
              >
                <React.Fragment>
                  <div className="auth-block">
                    {m.offering_approval_authority && (
                      <b>{m.offering_approval_authority.username}</b>
                    )}
                    {m.answered_at && (
                      <time>{new Date(m.answered_at).toLocaleString()}</time>
                    )}
                    {!m.answered_at && m.offering_approval_authority && (
                      <OfferingSendButton
                        joinId={m.offering_approval_authority.id}
                      />
                    )}
                    <EmailOfferingLink
                      joinId={m.offering_approval_authority.id}
                    />
                  </div>
                  <div>
                    {m.rejection_reason && !m.approved && (
                      <span>{m.rejection_reason}</span>
                    )}
                  </div>
                </React.Fragment>
              </StyledApproval>
            ))}
          </StyledApprovalList>
        </div>
      </div>
    </StyledItem>
  );
}

ApprovalItem.defaultProps = {
  approvals: [],
  assets: [],
};

const StyledItem = styled.div`
  h4 {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      // margin-right: 8px;
    }
  }

  .auth-block {
    display: flex;
    align-items: center;

    time {
      display: block;
      flex-grow: 1;
    }
  }

  .content {
    position: relative;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    min-height: 300px;

    img {
      display: block;
      margin: 0 auto;
      max-height: 300px;
      max-width: 100%;
    }
  }

  .approval-status-icon {
    position: absolute;
    top: -15px;
    right: -15px;
    z-index: 2;

    svg {
      font-size: 48px;
    }
  }

  .text-variety {
    font-size: 1.25em;
    text-align: center;
  }

  .approvals {
    margin-top: 1.5em;
    border-top: 1px solid #eee;
    padding-top: 1em;
  }
`;

const StyledApprovalList = styled.ul`
  margin: 0;
  padding: 0;
`;

const StyledApproval = styled.li`
  display: flex;
  flex-direction: column;
  /* align-items: center; */

  & + & {
    margin-top: 8px;
  }

  b {
    color: ${(props) =>
      props.approved
        ? 'var(--primaryColor500)'
        : props.rejected
        ? 'var(--negative)'
        : 'inherit'};
  }

  svg {
    font-size: 14px;
  }

  time {
    font-size: 0.75em;
    margin-left: 8px;
    /* background: #fffefe; */
    /* padding: 2px; */
    /* border-radius: 5px; */
    /* border: 1px solid #eee; */
  }
`;
