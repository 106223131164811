import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { FaRegFileImage, FaArrowLeft, FaPen, FaTrashAlt } from 'react-icons/fa';
import {
  FileButton,
  ActionIcon,
  Text,
  Group,
  Card,
  Flex,
  Button,
} from '@mantine/core';
import { Modal, Form, Input } from 'semantic-ui-react';

import styled from 'styled-components';

import { EmptyText, Title } from '@components/shared';

import { buildSizes } from '@util/images';

const ImageBlock = styled.div`
  position: relative;
  width: fit-content;
  max-width: 90%;
  padding: 10px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: start;

  @media only screen and (min-width: 2000px) {
    width: 100%;
  }

  img {
    width: 100px !important;
    height: 100px !important;
    object-fit: contain;
    border-radius: 7px !important;
    margin: 0 3em 0 0;
    display: block;
  }

  p {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start;
    font-weight: 300;
    font-size: 1.35em;
    margin: 15px;
    text-align: left;
    min-width: 300px;

    &.type {
      flex-grow: 1;
      justify-self: flex-end;
      font-size: 1.1em;
      color: var(--darkgrey);
      cursor: default;
      min-width: 200px;

      &:hover {
        opacity: 1;
      }
    }

    &:hover {
      opacity: 0.55;
    }

    svg {
      font-size: 0.85em;
      margin-right: 6px;
      fill: var(--primaryColor600);
      margin: 0 0.75em 0 0;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default function ProjectAssets({
  assets,
  fetchData,
  projectId,
  vendorId,
}) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [asset, setAsset] = useState(null);
  const [name, setName] = useState('');

  function onUpload(file) {
    const formData = new FormData();
    formData.append('tempfile', file);
    if (projectId) {
      formData.append('project_id', projectId);
    }
    if (vendorId) {
      formData.append('vendor_id', vendorId);
    }
    formData.append('variety', 7);

    setLoading(true);

    axios
      .post(`/asset-upload/`, formData)
      .then(() => {
        setLoading(false);
        fetchData();
        toast.success('Uploaded!');
      })
      .then(() => fetchData())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onDelete(id) {
    setLoading(true);

    axios
      .post(`/delete-asset/${id}/`)
      .then(() => {
        setLoading(false);
        fetchData();
        toast.success('Deleted!');
      })
      .then(() => fetchData())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onUpdate(id) {
    setLoading(true);

    axios
      .put(`/assets/${id}/`, { name: name })
      .then(() => {
        setLoading(false);
        fetchData();
        toast.success('Updated!');
        setAsset(null);
        setName('');
      })
      .then(() => fetchData())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
        setAsset(null);
        setName('');
      });
  }

  const cardStyle = {
    border: '1px solid #ccc',
    borderRadius: '7px',
    background: '#fff',
  };

  return (
    <div>
      {asset && (
        <AssetModal
          name={name}
          setName={setName}
          onUpdate={onUpdate}
          onDelete={onDelete}
          asset={asset}
          setOpen={setOpen}
          open={open}
          setAsset={setAsset}
        />
      )}
      <Title text="Assets" page />
      <FileButton onChange={(e) => onUpload(e)} mb="xl">
        {(props) => (
          <Button
            loading={loading}
            {...props}
            variant="light"
            color="green"
            leftIcon={<FaRegFileImage size={16} />}
            size="xs"
          >
            Upload
          </Button>
        )}
      </FileButton>
      {assets.length > 0 && (
        <Flex direction="column">
          {assets.map((m) => (
            <Card mb="sm" style={cardStyle}>
              <ImageBlock key={m.id}>
                <Group>
                  <img src={m.medium_url} sizes={buildSizes()} />
                  <Group mr="xl">
                    <ActionIcon
                      color="green"
                      variant="light"
                      onClick={() => setAsset(m)}
                    >
                      <FaPen size={16} />
                    </ActionIcon>
                    <Text ff="Open Sans, sans-serif" fw="lighter" size="xl">
                      {m.name ? m.name : 'No name'}
                    </Text>
                  </Group>
                  <Text
                    ff="Open Sans, sans-serif"
                    size="md"
                    color="dimmed"
                    align="right"
                    ml="xl"
                  >
                    {m.content_type ? m.content_type : ''}
                  </Text>
                </Group>
              </ImageBlock>
            </Card>
          ))}
        </Flex>
      )}
      {assets.length === 0 && <EmptyText />}
    </div>
  );
}

ProjectAssets.defaultProps = {
  assets: [],
  title: 'Images',
};

const AssetModal = ({
  name,
  setName,
  onUpdate,
  onDelete,
  asset,
  setOpen,
  setAsset,
}) => {
  return (
    <Modal
      style={{ border: '5px solid #108fc2', padding: '50px' }}
      onClose={() => {
        setAsset(null), setName('');
      }}
      onOpen={() => ''}
      open={asset}
      size="medium"
    >
      <Flex
        direction="column"
        key={asset.id}
        style={{ margin: '0px auto' }}
        align="center"
      >
        <img src={asset.medium_url} sizes={buildSizes()} />
        <Text ff="Open Sans, sans-serif" size={30} align="center">
          {asset.name ? asset.name : ''}
        </Text>
      </Flex>
      <Form style={{ margin: '40px' }}>
        <Input
          size="small"
          style={{ margin: '10px auto', display: 'flex', alignItems: 'center' }}
          type="text"
          placeholder="Rename your asset"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Group grow>
          <Button
            style={{ width: 'fit-content' }}
            type="submit"
            color="green"
            onClick={() => onUpdate(asset.id)}
            leftIcon={<FaRegFileImage size={16} />}
          >
            Submit
          </Button>
          <Button
            onClick={() => {
              setAsset(null), setName('');
            }}
            color="gray"
            leftIcon={<FaArrowLeft size={16} />}
          >
            Back
          </Button>
        </Group>
      </Form>
      <Button
        onClick={() => {
          onDelete(asset.id);
          setAsset(null);
        }}
        variant="subtle"
        color="red"
        leftIcon={<FaTrashAlt size={16} />}
      >
        Delete this asset
      </Button>
    </Modal>
  );
};
