import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import { Flex, Title, Group } from '@mantine/core';

import { ItemSearch, ItemForm } from '@components/Item';
import { FormWrapper, Icon } from '@components/shared';

export default function ItemIndex({ history }) {
  const [isOpen, setOpen] = useState(false);
  const [itemType, setItemType] = useState('item');

  return (
    <React.Fragment>
      <Group mb="md">
        <Title ff="Open Sans, sans-serif" size={35} fw="lighter">
          Catalog
        </Title>
        <Icon.Create onClick={() => setOpen(!isOpen)} isOpen={isOpen} />
      </Group>
      {!isOpen && (
        <React.Fragment>
          <Form style={{ marginBottom: '1em' }}>
            <Form.Radio
              toggle
              slider
              label={itemType === 'item' ? 'Stock Items' : 'Customer Items'}
              onChange={(e) =>
                setItemType(itemType === 'item' ? 'catalog' : 'item')
              }
            />
          </Form>
          <ItemSearch asLink offeringItemSearch={itemType === 'catalog'} />
        </React.Fragment>
      )}
      {isOpen && (
        <FormWrapper>
          <ItemForm
            onSuccess={(id) => {
              setOpen(false);
              history.push(`/items/${id}`);
            }}
          />
        </FormWrapper>
      )}
    </React.Fragment>
  );
}
