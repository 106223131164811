import React, { useState } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import './index.scss';
import { BasicForm } from '@components/shared';

export default function UserForm({ error, onSubmit }) {
  const [loading, setLoading] = useState(false);

  let fields = [
    {
      name: 'first_name',
      label: 'First Name',
      initialValue: '',
      required: true,
      schema: () => Yup.string().required('Required'),
    },
    {
      name: 'last_name',
      label: 'Last Name',
      initialValue: '',
      required: true,
      schema: () => Yup.string().required('Required'),
    },
    {
      name: 'phone',
      label: 'Phone Number',
      initialValue: '',
      required: true,
      schema: () => Yup.string().required('Required'),
    },
  ];

  return (
    <div>
      <BasicForm
        error={error}
        fields={fields}
        loading={loading}
        onSubmit={onSubmit}
      />
    </div>
  );
}

UserForm.defaultProps = {
  error: null,
  onSubmit: (d) => d,
};
