import React from 'react';
import styled from 'styled-components';

const Timeline = styled.div`
  display: flex;
  margin: 4em auto;
  width: 100%;
  padding: 10px 0 0 0;
`;

const Status = styled.div`
  padding: 0 15px;
  height: 10px;
  margin: 0 4px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 1.07em;
  box-shadow: 1px 1px 2px var(--grey100);
  background: ${(props) =>
    props.active ? 'var(--primaryColor500)' : 'var(--grey100)'};

  p {
    transform: translateY(-110%);
    text-align: center;
    margin: 0 auto;
  }
`;

export default function OfferingTimeline({ status }) {
  return (
    <Timeline>
      {statuses.map((s, i) => (
        <Status key={i} active={status === s.id}>
          <p>{s.status}</p>
        </Status>
      ))}
    </Timeline>
  );
}

const statuses = [
  { id: 0, status: 'In Progress' },
  { id: 1, status: 'Ready For Approval' },
  { id: 2, status: 'Waiting For Approval' },
  { id: 3, status: 'Ready To Be Published' },
  { id: 4, status: 'Published' },
  { id: 5, status: 'Unpublished' },
  { id: 6, status: 'Closed' },
];
