import React from 'react';
import { FaCheckCircle, FaUserLock } from 'react-icons/fa';
import { ThemeIcon, Text, Group } from '@mantine/core';

import { ObjectBlock } from '@components/shared';

export default function AdminTable({ data, loading }) {
  return (
    <div>
      {data.map((d, i) => (
        <ObjectBlock
          key={i}
          title={
            <Group>
              <ThemeIcon color="red" p={2}>
                <FaUserLock />
              </ThemeIcon>
              <Text ff="Open Sans, sans-serif" fw="lighter">
                {d.full_name}
              </Text>
              {d.verified && (
                <FaCheckCircle title="verified" style={{ marginLeft: '5px' }} />
              )}
            </Group>
          }
          url={`/admins/${d.id}`}
        />
      ))}
    </div>
  );
}

AdminTable.defaultProps = {
  data: [],
  loading: false,
};
