import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form } from 'semantic-ui-react';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';

import { BasicButton, ButtonBar } from '@components/Button';
import { ImageSlider, Text } from '@components/shared';
import { fileParams } from '@util/authInfo';
import { utcToLocal } from '@util/formatters';

const StyledItem = styled.div`
  padding: 2em 0;
  text-align: center;

  .photos {
    margin-bottom: 3em;
  }

  h1 {
    margin: 0;

    svg {
      margin-left: 4px;
      position: relative;
      top: 3px;
    }
  }

  h1 + p {
    margin-top: 1em;
  }

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  .actions {
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default function OfferingApprovalItem({
  decision,
  id,
  price,
  item,
  fetchData,
}) {
  const [rejectReason, setRejectReason] = useState('');
  const [rejectFlow, setRejectFlow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function onDecisionClick(approve = false) {
    setError(null);
    setLoading(true);

    const req = {
      offering_item_id: id,
      approve,
    };

    if (!approve) {
      req.rejection_reason = rejectReason;
    }

    axios
      .post(`/offering-item-decision/`, req)
      .then(() => {
        setLoading(false);
        fetchData();
        toast.success('Thank you!');
        setRejectFlow(false);
        setRejectReason('');
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  if (!item) return <>null</>;

  const hasAnswered = [true, false].includes(decision.approved);

  return (
    <StyledItem>
      {item.assets.length > 0 && (
        <div className="photos">
          <ImageSlider
            images={item.assets.map((a) => ({
              key: a.id,
              src: `/api/assets/${a.large_filename}${fileParams}`,
            }))}
          />
        </div>
      )}
      <h1>
        {item.name}{' '}
        {decision && decision.approved && (
          <FaCheckCircle color="var(--positive)" />
        )}
        {decision && hasAnswered && !decision.approved && (
          <FaExclamationCircle color="var(--negative)" />
        )}
      </h1>
      {item.description && <p>{item.description}</p>}
      {decision && hasAnswered && (
        <React.Fragment>
          {decision.approved && (
            <p>
              <b>This item was approved {utcToLocal(decision.approved_at)}</b>
            </p>
          )}
          {!decision.approved && (
            <React.Fragment>
              <p>
                <b>
                  This item was rejected {utcToLocal(decision.answered_at)}{' '}
                  because:
                </b>
              </p>
              <p>{decision.rejection_reason}</p>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {!hasAnswered && (
        <div className="actions">
          {!rejectFlow && (
            <ButtonBar>
              <BasicButton
                text="Approve"
                primary
                onClick={() => onDecisionClick(true)}
                disabled={loading}
              />
              <BasicButton
                text="Reject"
                onClick={() => {
                  setRejectFlow(true);
                }}
                disabled={loading}
              />
            </ButtonBar>
          )}
          {rejectFlow && (
            <div style={{ width: '100%' }}>
              <Form>
                <Form.TextArea
                  placeholder="Give us a reason you are rejecting this item"
                  value={rejectReason}
                  onChange={(e) => setRejectReason(e.target.value)}
                />
                <div className="actions">
                  <ButtonBar>
                    <BasicButton
                      primary
                      text="Confirm Rejection"
                      onClick={() => onDecisionClick(false)}
                      disabled={loading || !rejectReason}
                    />
                    <BasicButton
                      text="Cancel"
                      disabled={loading}
                      onClick={() => {
                        setRejectReason('');
                        setRejectFlow(false);
                      }}
                    />
                  </ButtonBar>
                </div>
              </Form>
            </div>
          )}
        </div>
      )}
      {error && <Text.Error style={{ marginTop: '1em' }} text={error} />}
    </StyledItem>
  );
}

OfferingApprovalItem.defaultProps = {
  decision: null,
  item: null,
  price: '',
};
