import React, { useState } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import toast from 'react-hot-toast';

import { BasicForm } from '@components/shared';

export default function AdminForm({
  id,
  phone,
  firstName,
  lastName,
  onSuccess,
  email,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function onSubmit(formData) {
    setLoading(true);
    setError(null);

    if (id) {
      return onUpdate(formData);
    }

    onCreate(formData);
  }

  function onCreate(formData) {
    const req = { ...formData };

    axios
      .post(`/admins/`, req)
      .then(({ data }) => {
        setLoading(false);
        toast.success('Admin Created!');
        onSuccess(data.response[0].id);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
        setTimeout(() => {
          setError(null);
        }, 5000);
      });
  }

  function onUpdate(formData) {
    const req = { ...formData };

    axios
      .put(`/admins/${id}/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
        toast.success('Admin Updated!');
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
        setTimeout(() => {
          setError(null);
        }, 5000);
      });
  }

  const fields = [
    {
      name: 'first_name',
      label: 'First Name',
      showLabel: false,
      initialValue: firstName,
      required: true,
      schema: () => Yup.string().required('Required'),
    },
    {
      name: 'last_name',
      label: 'Last Name',
      showLabel: false,
      initialValue: lastName,
      required: true,
      schema: () => Yup.string().required('Required'),
    },
    ,
    {
      name: 'phone',
      label: 'Phone',
      showLabel: false,
      initialValue: phone,
      required: true,
      schema: () => Yup.string().required('Required'),
    },
    {
      name: 'email',
      label: 'Email Address',
      showLabel: false,
      initialValue: email,
      required: true,
      schema: () => Yup.string().required('Required'),
    },
  ].filter((f) => (id ? !['phone'].includes(f.name) : true));

  return (
    <div>
      <BasicForm
        error={error}
        fields={fields}
        loading={loading}
        onSubmit={onSubmit}
      />
    </div>
  );
}

AdminForm.defaultProps = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  id: null,
};
