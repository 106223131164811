import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '.7' : '1')};
  outline: none;
  border: none;
  background: none;
  padding: 3px;

  svg {
    margin: 0;
    font-size: 1.25em;
  }
`;

export default function IconButton({ icon, onClick, disabled, title }) {
  return (
    <Button onClick={onClick} disabled={disabled} title={title}>
      {icon}
    </Button>
  );
}

IconButton.defaultProps = {
  disabled: false,
  title: '',
};
