import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Yup from 'yup';

import { BasicForm } from '@components/shared';

import { phoneValidation } from '@util/validation';

export default function PhoneForm({
  id,
  adminId,
  userId,
  phoneValue,
  onSuccess,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fields = [
    {
      name: 'number',
      label: 'Phone Number',
      initialValue: phoneValue,
      required: true,
      isPhone: true,
      schema: () =>
        Yup.string()
          .matches(phoneValidation, 'Not a valid phone')
          .required('Required!'),
    },
    {
      name: 'description',
      label: 'Description',
      initialValue: phoneValue,
      required: false,
      schema: () => Yup.string().required('Required'),
    },
  ];

  function onSubmit(formData) {
    setError(null);
    setLoading(true);

    const formatted = {
      ...formData,
    };

    if (adminId) {
      formatted.admin_id = adminId;
    }

    if (userId) {
      formatted.user_id = userId;
    }

    if (id) return onUpdate(formatted);

    return onCreate(formatted);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
    };

    axios
      .post(`/phones/`, req)
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      id,
    };

    axios
      .put(`/phones/${id}/`, req)
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  return (
    <BasicForm
      error={error}
      fields={fields}
      loading={loading}
      onSubmit={onSubmit}
    />
  );
}

PhoneForm.defaultProps = {
  adminId: null,
  userId: null,
  id: null,
  phoneValue: '',
};
