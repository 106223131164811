import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

import ActiveItem from './ActiveItem';
import Nav from './Nav';

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  background: #fff;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  .offering-name {
    padding: 1em;
    text-align: center;
  }

  .active-item {
    flex-grow: 1;
    padding: 1em;
  }

  .nav {
    padding: 1em;
    background: var(--black100);
  }
`;

export const Context = createContext(null);

export default function Provider({
  approvalItems,
  fetchData,
  authJoinId,
  match,
}) {
  const [providerState, setProviderState] = useState({
    approvals: [],
    activeIndex: 0,
  });

  useEffect(() => {
    setProviderState({
      approvals: approvalItems,
      activeIndex: providerState.activeIndex,
    });
  }, [JSON.stringify(approvalItems)]);

  function fetchApprovalItems() {}

  // useEffect(() => {
  //   fetchApprovalItems();
  // }, []);

  function onUpdate(newState) {
    setProviderState({ ...providerState, ...newState });
  }

  // function fetchApprovalItems(advanceActiveIndex = false) {
  //   let fetchUrl = `/user-offerings/${offeringId}/`;

  //   // look at offering through admin
  //   if (offeringId && id) {
  //     fetchUrl = `/users/${id}/offerings/${offeringId}/`;
  //   }

  //   axios
  //     .get(fetchUrl)
  //     .then(({ data }) => {
  //       const items = data.response;

  //       setProviderState({
  //         ...providerState,
  //         activeIndex:
  //           advanceActiveIndex && items.length - 1 > providerState.activeIndex
  //             ? providerState.activeIndex + 1
  //             : providerState.activeIndex,
  //         approvals: items,
  //       });
  //     })
  //     .catch((err) => {
  //       setProviderState({
  //         ...providerState,
  //         approvals: [],
  //       });
  //     });
  // }

  if (!approvalItems.length) return <div></div>;

  return (
    <Context.Provider value={providerState}>
      <CustomerApproval onUpdate={onUpdate} fetchData={fetchData} />
    </Context.Provider>
  );
}

function CustomerApproval({ fetchData, onUpdate }) {
  const { approvals, activeIndex } = useContext(Context);
  const activeItem = approvals[activeIndex];

  function goForward() {
    onUpdate({ activeIndex: activeIndex + 1 });
  }

  function goBack() {
    onUpdate({ activeIndex: activeIndex - 1 });
  }

  return (
    <Wrapper>
      {/* <OfferingMeta
        indexInfo={`${activeIndex + 1}/${approvals.length}`}
      /> */}
      <div className="active-item">
        {activeItem && (
          <ActiveItem
            approvalId={activeItem.id}
            approvalItem={activeItem.approval_item}
            approved={activeItem.approved}
            fetchData={fetchData}
            itemName={activeItem.item_name}
            rejectionReason={activeItem.rejection_reason}
            answeredAt={activeItem.answered_at}
            required={activeItem.required}
            onDecisionSuccess={() => {
              if (activeIndex !== approvals.length - 1) {
                goForward();
              }
              fetchData(true);
            }}
          />
        )}
      </div>
      <div className="nav" style={{ marginTop: '2em' }}>
        <Nav
          backDisabled={activeIndex === 0}
          nextDisabled={activeIndex === approvals.length - 1}
          onBackClick={goBack}
          onNextClick={goForward}
        />
      </div>
    </Wrapper>
  );
}

function OfferingMeta({ offeringId, indexInfo }) {
  const [offering, setOffering] = useState(null);

  useEffect(() => {
    fetchOffering();
  }, []);

  function fetchOffering() {
    axios
      .get(`/offerings/${offeringId}/`)
      .then(({ data }) => {
        setOffering(data.response[0]);
      })
      .catch((err) => {});
  }

  if (!offering) return null;

  return (
    <div className="offering-name">
      <h3>{offering.name}</h3>
      <p>{indexInfo}</p>
    </div>
  );
}
