import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Text, createTheme, MantineProvider } from '@mantine/core';

import AdminApp from './containers/AdminApp';
import UserApp from './containers/UserApp';
import Auth from './components/Auth';

import { SET_ADMIN } from '@duck/admin';
import { SET_USER } from '@duck/user';

const theme = {
  components: {
    Text: {
      defaultProps: {
        fw: 'lighter',
        ff: 'Open Sans, sans-serif !important',
      },
    },

    Button: {
      defaultProps: {
        ff: 'Open Sans, sans-serif',
        fw: 'lighter',
      },
    },
  },
};

class App extends Component {
  state = {
    loading: true,
  };

  componentDidMount = () => {
    this.checkStatus();
  };

  checkStatus = () => {
    axios
      .get(`/status/`)
      .then(({ data }) => {
        if (data.response && data.response.length && data.response[0].admin) {
          return this.props.dispatch({
            type: SET_ADMIN,
            payload: data.response[0].admin,
          });
        }

        if (data.response && data.response.length && data.response[0].user) {
          return this.props.dispatch({
            type: SET_USER,
            payload: data.response[0].user,
          });
        }

        this.props.history.push(`/`);
      })
      .then(() => {
        this.setState({ loading: false });
        window.location.reload;
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    // if (this.state.loading) {
    //   return <div>Loading...</div>;
    // }

    if (window.location.pathname.includes(`/code/`)) {
      return <Auth />;
    }

    if (this.props.admin) {
      return <AdminApp admin={this.props.admin} />;
    }

    if (this.props.user) {
      return <UserApp admin={this.props.user} />;
    }

    return (
      <MantineProvider theme={theme}>
        <div
          style={{
            height: '100%',
          }}
        >
          <Auth />
        </div>
      </MantineProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    admin: state.admin,
    user: state.user,
  };
}

export default connect(mapStateToProps)(App);
