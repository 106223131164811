import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  margin-bottom: 1.5em;
`;

const LinkWrapper = styled(Link)`
  font-weight: bold;
  font-size: 1.15em;
  margin-right: 10px;
  color: ${(props) =>
    props.active ? 'var(--primaryColor500)' : 'var(--black100)'};
  border-bottom: ${(props) =>
    props.active
      ? '4px solid var(--primaryColor500)'
      : '4px solid transparent'};
  padding-bottom: 5px;

  & + & {
    margin-left: 1em;
  }

  &:hover {
    color: var(--primaryColor500);
    /* opacity: ${(props) => (props.active ? '1' : '.5')};
    background-color: ${(props) =>
      props.active ? '' : 'var(--primaryColorLight)'};
    color: white; */
  }

  // &:first-of-type {
  //   padding-left: 0;
  // }
`;

export default function Subnav({ links }) {
  if (!links.length) return null;

  return (
    <Wrapper>
      {links.map((l, i) => (
        <LinkWrapper
          key={i}
          to={l.to}
          active={l.to === window.location.pathname}
        >
          {l.text}
        </LinkWrapper>
      ))}
    </Wrapper>
  );
}

Subnav.defaultProps = {
  links: [],
};
