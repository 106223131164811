import React, { Component } from 'react';
import axios from 'axios';
import { Form } from 'semantic-ui-react';
import { toast } from 'react-hot-toast';

import { ViewError } from '@components/shared';
import { BasicButton } from '@components/Button';

class Code extends Component {
  state = {
    adminId: null,
    error: null,
    loading: true,
    variety: null,
    verified: false,
    submitting: false,
    password: '',
    confirmedPassword: '',
  };

  componentDidMount = () => {
    this.verifyCode();
  };

  verifyCode = () => {
    const code = this.props.match.params.code;

    axios
      .get(`/remote-codes/${code}/`)
      .then(({ data }) => {
        let remoteCode = data.response[0];

        this.setState({
          adminId: remoteCode.admin_id,
          remote_code_id: remoteCode.id,
          loading: false,
          variety: remoteCode.variety,
          verified: true,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
          loading: false,
          verified: false,
        });
      });
  };

  onPasswordSubmit = () => {
    const req = {
      admin_id: this.state.adminId,
      confirmed_password: this.state.confirmedPassword,
      password: this.state.password,
      remote_code_id: this.state.remote_code_id,
    };

    this.setState({ submitting: true });

    axios
      .post(`/admin-new-password/`, req)
      .then(({ data }) => {
        window.location = '/admin';
      })
      .catch((err) => {
        toast.error(err);
        this.setState({
          submitting: false,
        });
      });
  };

  render() {
    const { error, loading, password, confirmedPassword } = this.state;

    if (loading) {
      return <div>loading....</div>;
    }

    if (error) {
      return <ViewError text={error} />;
    }

    return (
      <div style={{ width: '500px', margin: '0px auto' }}>
        <Form style={{ padding: '10px', marginTop: '1px' }}>
          <Form.Input
            type="password"
            label="Password"
            placeholder="Password"
            value={password}
            onChange={(e) => this.setState({ password: e.target.value })}
          />
          <Form.Input
            type="password"
            label="Confirmed Password"
            placeholder="Password Confirmation"
            value={confirmedPassword}
            onChange={(e) =>
              this.setState({ confirmedPassword: e.target.value })
            }
          />
          <BasicButton
            text="Submit"
            onClick={this.onPasswordSubmit}
            loading={this.state.submitting}
            disabled={
              !password || !confirmedPassword || password !== confirmedPassword
            }
            primary
          />
        </Form>
      </div>
    );
  }
}

export default Code;
