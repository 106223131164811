import React from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import styled from 'styled-components';
import { ActionIcon } from '@mantine/core';

export default function CreateIcon({ isOpen, onClick, fontSize, color }) {
  return (
    <ActionIcon
      variant="filled"
      radius="xl"
      open={isOpen}
      fontSize={fontSize}
      color={isOpen ? 'red' : color ? color : 'teal'}
    >
      {isOpen ? <FaMinus onClick={onClick} /> : <FaPlus onClick={onClick} />}
    </ActionIcon>
  );
}
