import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { BasicButton } from '@components/Button';
import { Modal, Header } from 'semantic-ui-react';
import {
  Card,
  Group,
  Text,
  Flex,
  ThemeIcon,
  ActionIcon,
  FileButton,
  Button,
} from '@mantine/core';

import {
  FaCaretUp,
  FaCircle,
  FaCaretDown,
  FaTrashAlt,
  FaLock,
  FaExpandAlt,
} from 'react-icons/fa';
import styled from 'styled-components';

import useInterval from '@util/useInterval';

const NotesWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 4px;
  width: 400px;

  button.title {
    width: 90%;
    text-align: left;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px 0px 0 0;
    background: var(--primaryColor500);
    color: #fff;
    font-weight: bold;
    font-family: inherit;
    outline: none;
    border: none;

    svg {
      margin-left: 5px;
    }
  }

  button.expand {
    width: 10%;
    text-align: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 0px 5px 0 0;
    background: var(--primaryColor600);
    color: #fff;
    font-weight: bold;
    font-family: inherit;
    outline: none;
    border: none;
  }

  .content {
    min-height: 350px;
    display: flex;
    flex-direction: column;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px;
    background: var(--black200);
    flex-grow: 1;
  }

  textarea {
    display: block;
    padding: 4px;
    font-size: 14px;
    font-family: inherit;
    border: 2px solid transparent;
    outline: none;
    background: #fff;
    border-radius: 3px;
    min-height: 65px;
    height: 65px;
    max-height: 120px;
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;

    &:focus {
      border: 2px solid #eee;
    }
  }

  .actions {
    margin-top: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    background: #222;
  }

  .list-wrapper {
    flex-grow: 2;
    background: #fff;
    overflow: auto;
    max-height: 350px;
    min-height: 350px;
    position: relative;
  }

  .empty {
    text-align: center;
    height: 350px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    text-align: center;
    align-items: center;

    svg {
      fill: #eee;
      font-size: 40px;
      margin-bottom: 15px;
    }

    p {
      font-size: 14px;
    }
  }
`;

const AdminOnlyButton = styled.button`
  opacity: ${(props) => (props.active ? 1 : 0.25)};
  background: none;
  cursor: pointer;
  font-weight: bold;
  color: var(--primaryColor400);
  outline: none;
  border: none;
  margin: 0 6px;
`;
const ModalNotesWrapper = styled.div`


  .ui.header {
    padding: 15px !important; 
    text-align: center;
    border-bottom: 2px solid #ddd;
    border-radius: 3px 3px 0 0 !important;
  }


  .content {
    textarea {
      display: block;
      padding: 4px;
      font-size: 14px;
      font-family: inherit;
      border: 2px solid #eee;
      outline: none;
      background: #fff;
      border-radius: 3px;
      min-height: 65px;
      height: 65px;
      max-height: 120px;
      margin: 3px auto;
      min-width: 80%;

      &:focus {
        border: 2px solid #eee;
      }
    }

    .actions {
      margin-top: 10px;
      display: flex;
      align-items: center;
      width: 100%;
      background: #222;
      padding: 10px 20px;
    }

    .list-wrapper {
      flex-grow: 2;
      background: #fff;
      overflow: auto;
      max-height: 450px;
      min-height: 450px;
      position: relative;
    }
  
    .empty {
      text-align: center;
      height: 350px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 15px;
      text-align: center;
      align-items: center;
  
      svg {
        fill: #eee;
        font-size: 40px;
        margin-bottom: 15px;
      }
  
      p {
        font-size: 14px;
      }
  }

`;

function Notes({
  additionalCreateData,
  additionalFetchData,
  admin,
  buttonTitle,
  fetchUrl,
  title,
  user,
}) {
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // form values
  const [adminOnly, setAdminOnly] = useState(true);
  const [noteValue, setNoteValue] = useState('');
  const [subjectValue, setSubjectValue] = useState('');
  // the notes
  const [items, setItems] = useState([]);
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [notesLoading, setNotesLoading] = useState(false);

  useEffect(() => {
    fetchNotes();
  }, []);

  useEffect(() => {
    if (open) {
      const el = document.getElementById('noteInput');

      if (el) {
        el.focus();
      }
    }
  }, [open]);

  useInterval(() => {
    fetchNotes();
  }, 30000);

  function fetchNotes() {
    if (!additionalFetchData) return;

    setNotesLoading(true);

    const req = {
      ...additionalFetchData,
      // admin_only: false,
    };

    axios
      .post(fetchUrl, req)
      .then(({ data }) => {
        setItems(
          data.response.sort((a, b) =>
            new Date(a.created_at) < new Date(b.created_at) ? 1 : -1,
          ),
        );
        setNotesLoading(false);
      })
      .catch((err) => {
        setNotesLoading(false);
        setItems([]);
      });
  }

  function onSubmit() {
    const req = {
      ...additionalCreateData,
      body: noteValue,
      admin_only: user ? false : adminOnly,
    };

    if (user) {
      req.user_id = user.id;
    }

    if (admin) {
      req.admin_id = admin.id;
    }

    setError(null);
    setLoading(true);

    axios
      .post(`/notes/`, req)
      .then(() => {
        setLoading(false);
        resetForm();
        fetchNotes();
      })
      .catch((err) => {
        setLoading(false);
        setError(err);

        setTimeout(() => {
          setError(null);
        }, 4000);
      });
  }

  function resetForm() {
    setNoteValue('');
    setSubjectValue('');
    setAdminOnly(true);
  }

  if (modalOpen)
    return (
      <Modal
        onClose={() => setModalOpen(false)}
        onOpen={() => setModalOpen(true)}
        open={modalOpen}
        size="large"
      >
        <ModalNotesWrapper>
          <Header>Notes</Header>
          <Modal.Content>
            <div className="content">
              <NoteList notes={items} fetchNotes={fetchNotes} />
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
              >
                <div className="actions">
                  <textarea
                    value={noteValue}
                    id="noteInput"
                    placeholder="Your note here..."
                    onChange={(e) => setNoteValue(e.target.value)}
                  />
                  {admin && (
                    <AdminOnlyButton
                      active={adminOnly}
                      type="button"
                      onClick={() => setAdminOnly(!adminOnly)}
                    >
                      Admin Only
                    </AdminOnlyButton>
                  )}
                  <div
                    style={{
                      flexGrow: '1',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <BasicButton
                      primary
                      text="Submit"
                      onClick={onSubmit}
                      loading={loading}
                      disabled={!noteValue}
                    />
                  </div>
                </div>
              </form>
            </div>
          </Modal.Content>
        </ModalNotesWrapper>
      </Modal>
    );

  if (!modalOpen)
    return (
      <NotesWrapper>
        <button className="title" onClick={() => setOpen(!open)}>
          {buttonTitle}{' '}
          {items.length > 0 && <FaCircle color="#ff5f5f" size={12} />}{' '}
          {open ? <FaCaretDown /> : <FaCaretUp />}
        </button>
        <button className="expand" onClick={() => setModalOpen(true)}>
          <FaExpandAlt />
        </button>
        {open && (
          <div className="content">
            <NoteList notes={items} fetchNotes={fetchNotes} />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <textarea
                value={noteValue}
                id="noteInput"
                placeholder="Your note here..."
                onChange={(e) => setNoteValue(e.target.value)}
              />
              <div className="actions">
                {admin && (
                  <AdminOnlyButton
                    active={adminOnly}
                    type="button"
                    onClick={() => setAdminOnly(!adminOnly)}
                  >
                    Admin Only
                  </AdminOnlyButton>
                )}
                <div
                  style={{
                    flexGrow: '1',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <BasicButton
                    primary
                    text="Submit"
                    onClick={onSubmit}
                    loading={loading}
                    disabled={!noteValue}
                  />
                </div>
              </div>
            </form>
          </div>
        )}
      </NotesWrapper>
    );
}

Notes.defaultProps = {
  // data that needs to be sent along with a note create
  additionalCreateData: null,
  // data that needs to be sent along with a fetch req
  additionalFetchData: null,
  // title of the button that opens the modal
  buttonTitle: 'Notes',
  // where to fetch notes
  fetchUrl: '/retrieve-notes/',
  // title of modal
  title: '',
};

function mapStateToProps(state) {
  return {
    admin: state.admin,
    user: state.user,
  };
}
export default connect(mapStateToProps)(Notes);

const List = styled.div`
  overflow: auto;
`;

function NoteList({ notes, fetchNotes }) {
  if (!notes.length) return null;

  function onDelete(noteId) {
    axios
      .get(`notes/${noteId}/delete/`)
      .then(() => {
        fetchNotes();
      })
      .catch((err) => {});
  }

  return (
    <List>
      {notes.map(
        ({ id, subject, body, entity_name, created_at, admin_only }) => (
          <Card key={id} radius={0} p="sm" pt="xs">
            <Group mb="xs" spacing="xs">
              {admin_only && (
                <ThemeIcon color="gray">
                  <FaLock />
                </ThemeIcon>
              )}
              <Text size="lg" ff="Open Sans, sans-serif">
                {entity_name}
              </Text>
              <Text c="#333" fw="lighter" ff="Open Sans, sans-serif">
                {new Date(created_at).toLocaleString()}
              </Text>
              <ActionIcon
                color="red"
                variant="light"
                onClick={() => onDelete(id)}
              >
                <FaTrashAlt />
              </ActionIcon>
            </Group>
            <Text fw="lighter" ff="Open Sans, sans-serif">
              {body}
            </Text>
          </Card>
        ),
      )}
    </List>
  );
}

NoteList.defaultProps = {
  notes: [],
};

const cardStyle = {
  borderBottom: '1px solid #ccc',
  background: '#fff',
};
