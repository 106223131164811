import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { toast } from 'react-hot-toast';

import CodeInput from './CodeInput';
import logo from '@assets/images/logo-black.png';

const Wrapper = styled.div`
  width: 800px;
  margin: 0 auto;
  padding: 100px 0;

  img {
    display: block;
    margin: 0 auto;
    width: 400px;
  }
`;

export default function UserLogin({ authJoinId, phoneId, onSuccess }) {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (code.length === 5) {
      onCodeSubmit();
    }
  }, [code]);

  function onCodeSubmit() {
    setLoading(true);
    // setSent(true);

    const req = {
      code,
      phone_id: phoneId,
      offering_approval_authority_id: authJoinId,
    };

    axios
      .post(`/approval-auth-verify/`, req)
      .then(({ data }) => {
        onSuccess(data.response);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
        setCode('');
      });
  }

  return (
    <Wrapper>
      <React.Fragment>
        <img src={logo} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '2em',
          }}
        >
          <p>
            <b>Enter the 5 digit code sent to your phone</b>
          </p>
          <CodeInput value={code} onChange={(e) => setCode(e)} />
        </div>
      </React.Fragment>
    </Wrapper>
  );
}
