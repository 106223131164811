import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Form } from 'semantic-ui-react';
import { SET_ADMIN } from '@duck/admin';
import { toast } from 'react-hot-toast';
import { Button, Text, Group, Alert } from '@mantine/core';

import { FaArrowAltCircleLeft, FaExclamationCircle } from 'react-icons/fa';

import logo from '@assets/images/logo-white.png';
import { BasicButton } from '@components/Button';

import './index.scss';

function Login({ dispatch }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState(true);

  function onSubmit() {
    if (!email || !password) {
      return alert('please fill all the fields');
    }
    const req = {
      email,
      password,
    };

    setLoading(true);

    axios
      .post('/admin-login/', req)
      .then(({ data }) => {
        dispatch({ type: SET_ADMIN, payload: data.response[0] });
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function onForgot() {
    if (!email) {
      return alert('please fill all the fields');
    }
    const req = {
      email,
    };

    axios
      .post('/admin-forgot/', req)
      .then(() => {
        setLoading(false);
        toast.success('Forgot password link sent!');
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }
  const fpIcon = <FaExclamationCircle size={16} />;

  return (
    <div className="login-page">
      <div className="auth-left"></div>
      {login && (
        <div className="login">
          <img src={logo} />
          <div className="login-form">
            <Form>
              <Form.Input
                className="input"
                label="Email"
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Input
                className="input"
                label="Password"
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <BasicButton
                text="Submit"
                onClick={onSubmit}
                loading={loading}
                disabled={loading}
                color="teal"
                variant="outline"
                size="lg"
                primary
              />
            </Form>
          </div>
          <BasicButton
            onClick={() => setLogin(false)}
            loading={loading}
            disabled={loading}
            color="red"
            variant="subtle"
            text="Forgot password?"
            size="md"
            leftIcon={<FaExclamationCircle size={16} />}
          />
        </div>
      )}

      {login === false && (
        <div className="login">
          <img src={logo} />
          <Alert
            radius={10}
            variant="outline"
            color="red"
            mb="lg"
            mt="lg"
            title="Forgot Password"
            icon={fpIcon}
            bg="white"
          >
            <Text c="red" size="lg" ff="Open Sans, sans-serif">
              Type in your email and we'll send you a link in order to reset
              your password.
            </Text>
          </Alert>
          <div className="login-form">
            <Form>
              <Form.Input
                className="input"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <BasicButton
                variant="outline"
                color="teal"
                text="Send me a code"
                size="md"
                onClick={onForgot}
                loading={loading}
                primary
              />
            </Form>
          </div>
          <BasicButton
            onClick={() => setLogin(true)}
            loading={loading}
            disabled={loading}
            color="teal"
            variant="outline"
            text="Back To Login"
            size="md"
            leftIcon={<FaArrowAltCircleLeft size={16} />}
          />
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(Login);
