import React, { useState } from 'react';
import { Flex, Title, Group, ActionIcon } from '@mantine/core';

import { AdminForm, AdminSearch } from '@components/Admin';
import { FormWrapper, Icon } from '@components/shared';

export default function AdminIndex() {
  const [isOpen, setOpen] = useState(false);

  return (
    <Flex direction="column">
      <Group mb="md">
        <Title ff="Open Sans, sans-serif" size={35} fw="lighter">
          Admins
        </Title>
        <ActionIcon
          variant="transparent"
          onClick={() => setOpen(!isOpen)}
          isOpen={isOpen}
        >
          <Icon.Create />
        </ActionIcon>
      </Group>
      {isOpen ? (
        <FormWrapper>
          <AdminForm onSuccess={() => setOpen(false)} />
        </FormWrapper>
      ) : (
        <AdminSearch />
      )}
    </Flex>
  );
}
