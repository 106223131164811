import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ItemList } from './';
import { BasicButton } from '@components/Button';
import { EmptyText, SearchInput } from '@components/shared';

export default function ItemSearch({
  asLink,
  offeringItemSearch,
  isOffering,
  select,
  onSelectClick,
}) {
  const [loading, setLoading] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [results, setResults] = useState([]);
  const [tags, setTags] = useState([]);

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  useEffect(() => {
    setAllItems([]);
    setResults([]);
  }, [offeringItemSearch]);

  const tagIds = tags.map((t) => t.id);

  useEffect(() => {
    if (!searchValue) {
      setAllItems([]);
    }

    fetchData();
  }, [searchValue, JSON.stringify(tags)]);

  useEffect(() => {
    fetchData();
  }, [offeringItemSearch]);

  function fetchData() {
    if (!searchValue) {
      return setAllItems([]);
    }
    setAllItems([]);
    setLoading(true);

    const cancel = cancelRef.current;
    if (cancel) {
      cancel();
    }

    const req = {
      tag_only: false,
      tag_ids: tagIds,
      variety: [0, 1, 2, 3, 4, 5, 9],
      search: searchValue,
    };

    const url = offeringItemSearch
      ? '/offering-item-name-search/'
      : isOffering
      ? `/offering-item-search/`
      : '/item-search/';

    axios
      .post(url, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setLoading(false);

        if (isOffering) {
          setResults(data.response[0].tags);
          setAllItems([
            ...data.response[0].items.map((m) => ({
              ...m,
              is_offering_item: false,
            })),
            ...data.response[0].offering_items.map((m) => ({
              ...m,
              is_offering_item: true,
            })),
          ]);
        } else if (offeringItemSearch) {
          setAllItems([
            ...data.response.map((m) => ({
              ...m,
              is_offering_item: false,
              offering_name: m.offering_name,
              url: `/offering-items/${m.offering_item_id}`,
            })),
          ]);
        } else {
          setResults(data.response[0].tags);
          setAllItems(data.response[0].results);
        }
      })
      .catch((err) => {
        setLoading(false);
        setAllItems([]);
        setResults([]);
      });
  }

  return (
    <React.Fragment>
      <SearchInput onChange={(e) => setSearchValue(e)} value={searchValue} />
      {allItems.length > 0 && (
        <div style={{ margin: '2em 0' }}>
          <ItemList
            asLink={asLink}
            items={allItems.map((m) => ({
              ...m,
              context: select ? (
                <AddButton onClick={() => onSelectClick(m.id)} />
              ) : null,
            }))}
            select={select}
          />
        </div>
      )}
      {allItems.length === 0 && searchValue.length > 0 && !loading && (
        <EmptyText text="No items found..." />
      )}
    </React.Fragment>
  );
}

ItemSearch.defaultProps = {
  asLink: false,
  offeringItemSearch: false,
  select: false,
};

const AddButton = ({ onClick }) => {
  return (
    <div style={{ padding: '10px' }}>
      <BasicButton text="Add" onClick={onClick} />
    </div>
  );
};
