import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'semantic-ui-react';

import { BasicButton } from '@components/Button';

export default function DecisionButtons({ onConfirm, onReject }) {
  const [isOpen, setOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState('');

  function onClose() {
    setOpen(false);
    setRejectReason('');
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <BasicButton
        onClick={() => setOpen(true)}
        text="Reject"
        style={{ marginRight: '5px' }}
      />
      <BasicButton onClick={onConfirm} text="Accept" primary />
      <Modal size="small" onClose={onClose} open={isOpen}>
        <Modal.Content>
          <Form>
            <Form.TextArea
              placeholder="Why are you rejecting this?"
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
            />
            <BasicButton
              primary
              onClick={() => {
                onClose();
                onReject(rejectReason);
              }}
              text="Confirm Rejection"
              disabled={!rejectReason}
              style={{ marginTop: '1em' }}
            />
          </Form>
        </Modal.Content>
      </Modal>
    </div>
  );
}
