import React, { useState } from 'react';
import axios from 'axios';
import { Form, Modal } from 'semantic-ui-react';

import { BasicButton, IconButton } from '@components/Button';
import { Icon } from '@components/shared';

export default function EmailOfferingLink({ joinId }) {
  const [isOpen, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  function onSubmit() {
    setLoading(true);

    const req = {
      join_id: joinId,
      email,
    };
  }

  function onClose() {
    setEmail('');
    setOpen(false);
    setLoading(false);
  }

  return (
    <React.Fragment>
      <IconButton
        onClick={() => setOpen(true)}
        title="Email Offering Link"
        icon={
          <Icon.Send size={14} style={{ fill: 'var(--primaryColor500)' }} />
        }
      />
      <Modal open={isOpen} onClose={onClose} size="small">
        <Modal.Content>
          <Form>
            <Form.Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
            />
            <BasicButton
              onClick={onSubmit}
              loading={loading}
              text="Send"
              disabled={!email || loading}
              primary
            />
          </Form>
        </Modal.Content>
      </Modal>
    </React.Fragment>
  );
}
