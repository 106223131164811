import React from 'react';
import styled from 'styled-components';

import screenSizes from '@util/screenSizes';

const Grid = styled.div`
  display: grid;
  gap: ${(props) => `${props.gap}px`};
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
  margin: ${(props) => props.margin};

  @media screen and (max-width: ${screenSizes.small.max}) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media screen and (min-width: ${screenSizes.tablet
      .min_portrait}) and (max-width: ${screenSizes.tablet.max_portrait}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export default function index({ children, columns, gap, margin }) {
  return (
    <Grid gap={gap} columns={columns} margin={margin}>
      {children}
    </Grid>
  );
}

Grid.defaultProps = {
  columns: 3,
  gap: 10,
  items: [],
  margin: '0 auto',
};
