import React, { useState } from 'react';
import { FaStar, FaPhoneAlt, FaMobileAlt } from 'react-icons/fa';
import axios from 'axios';
import toast from 'react-hot-toast';

import { BasicButton, ButtonBar } from '@components/Button';
import { ObjectBlock } from '@components/shared';
import { utcToLocal } from '@util/formatters';

export default function PhoneNameplate({
  id,
  numberFormatted,
  mobile,
  carrierName,
  callerName,
  lastVerified,
  primary,
  onUpdate,
  deletable,
  description,
}) {
  const [loading, setLoading] = useState(false);
  const [verifying, setVerifying] = useState(false);

  function onPrimaryClick() {
    setLoading(true);

    const req = {
      phone_number_id: id,
    };

    axios
      .post(`/phones/${id}/mark-primary/`, req)
      .then(() => {
        setLoading(false);
        toast.success('New primary phone!');
        onUpdate();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onRemoveClick() {
    setLoading(true);

    axios
      .delete(`/phones/${id}/`)
      .then(() => {
        setLoading(false);
        toast.success('Removed!');
        onUpdate();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onVerifyClick() {
    const req = {
      id: id,
    };
    setVerifying(true);
    axios
      .post(`/twilio-carrier-lookup/`, req)
      .then(() => {
        onUpdate();
        toast.success('Verified!');
        setVerifying(false);
      })
      .catch((err) => {
        toast.error(err);
        setVerifying(false);
      });
  }

  return (
    <ObjectBlock
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {mobile ? (
            <FaMobileAlt style={{ marginRight: '5px' }} />
          ) : (
            <FaPhoneAlt style={{ marginRight: '5px' }} />
          )}
          {numberFormatted}
          {primary && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexGrow: 1,
                alignSelf: 'flex-end',
                marginLeft: '15px',
                color: 'goldenrod',
              }}
            >
              <FaStar style={{ marginRight: '5px' }} />
              Primary
            </div>
          )}
        </div>
      }
      asLink={false}
      data={[
        { value: `${carrierName}`, bold: false },
        { value: `${callerName}`, bold: false },
        {
          value: `${
            lastVerified
              ? `Last verified ${utcToLocal(lastVerified)}`
              : 'Never verified'
          }`,
          bold: false,
        },
        { value: `${description}`, bold: true },
      ]}
      context={
        <ButtonBar>
          {!primary && (
            <BasicButton
              text="Make Primary"
              loading={loading}
              disabled={loading}
              onClick={() => onPrimaryClick()}
              primary
            />
          )}
          <BasicButton
            loading={verifying}
            text="Verify"
            onClick={onVerifyClick}
          />
          <BasicButton
            negative
            disabled={!deletable || loading || primary}
            text="Remove"
            loading={loading}
            onClick={() => onRemoveClick()}
          />
        </ButtonBar>
      }
    />
  );
}

PhoneNameplate.defaultProps = {
  carrierName: 'No carrier info',
  callerName: 'No caller info',
};
