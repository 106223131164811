import React from 'react';
import { ThemeIcon } from '@mantine/core';

import {
  FaDollarSign,
  FaRegImages,
  FaBoxes,
  FaPalette,
  FaEllipsisH,
  FaMap,
} from 'react-icons/fa';

export default function ApprovalIcon({ variety, color }) {
  switch (variety) {
    case 1:
      return (
        <ThemeIcon color={color}>
          <FaPalette title="Color" />
        </ThemeIcon>
      );
    case 2:
      return (
        <ThemeIcon color={color}>
          <FaBoxes title="Quantity" />
        </ThemeIcon>
      );
    case 3:
      return <div></div>;
    case 4:
      return (
        <ThemeIcon color={color}>
          <FaRegImages title="Art" />
        </ThemeIcon>
      );
    case 5:
      return (
        <ThemeIcon color={color}>
          <FaMap title="Mockup" />
        </ThemeIcon>
      );
    case 6:
      return (
        <ThemeIcon color={color}>
          <FaEllipsisH title="Other" />
        </ThemeIcon>
      );
    case 7:
      return (
        <ThemeIcon color={color}>
          <FaPalette title="Color" />
        </ThemeIcon>
      );
    default:
      return null;
  }
}
