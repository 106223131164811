import React from 'react';
import styled from 'styled-components';
import { Group, Button, Text, Tabs } from '@mantine/core';

export default function Title({
  subtitle,
  text,
  icon,
  centered,
  children,
  links,
  page,
}) {
  return (
    <div>
      <Text size={26} ff="Open Sans, san-serif" fw="lighter">
        {text}
        {children && <div>{children}</div>}
      </Text>
      {subtitle && <Group>{subtitle}</Group>}
    </div>
  );
}

Title.defaultProps = {
  // center the content
  centered: false,
  // add a border to the bottom
  dividing: false,
  links: [],
  subtitle: '',
  text: 'Title',
  icon: null,
};
