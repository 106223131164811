import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { Button, Text, ThemeIcon, Card, Badge, Group } from '@mantine/core';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';

import { Icon, Grid, ItemSlider } from '@components/shared';
import { RemoveConfirmButton } from '@components/Button';
import { ApprovalItem } from './ApprovalItemManagement';
import { penniesToDollars } from '@util/formatters';

export default function OfferingItemList({ offeringId, items, fetchData }) {
  return (
    <Grid columns={4} gap={25}>
      {items.map((item) => (
        <Item
          offeringVendorItem={item.selected_offering_vendor_item}
          key={item.join_id}
          id={item.join_id}
          itemId={item.item.id}
          approvalItems={item.approval_items}
          filename={
            item.display_large_url ||
            item.item.featured_image ||
            item.item.large_filename
          }
          offeringId={offeringId}
          name={item.name}
          fetchData={fetchData}
        />
      ))}
    </Grid>
  );
}

const Item = ({
  id,
  approvalItems,
  offeringId,
  itemId,
  filename,
  name,
  fetchData,
  offeringVendorItem,
}) => {
  const [show, setShow] = useState(false);

  return (
    <StyledItem>
      <img src={filename} />
      <div className="content">
        <div className="meta">
          <Text ff="Open Sans, sans-serif" size={20} fw="bold">
            {name}
          </Text>
          <div className="actions">
            <Link to={`/offering-items/${id}`}>
              <Icon.Edit color="var(--primaryColor500)" size={20} />
            </Link>
            <RemoveItem
              offeringId={offeringId}
              itemId={itemId}
              onSuccess={fetchData}
            />
          </div>
        </div>
        <Card radius={0} p="md">
          {!offeringVendorItem && (
            <Text ff="Open Sans, sans-serif" c="red" size="xl">
              No Vendor Selected!
            </Text>
          )}
          {offeringVendorItem && (
            <Group align="center" spacing={5}>
              <ThemeIcon color="gray" variant="light">
                <Icon.Partner color="gray" size={20} />
              </ThemeIcon>
              <Text ff="Open Sans, sans-serif" size={18} c="#333">
                {offeringVendorItem.vendor_name}
              </Text>
              <Badge
                c="white"
                size="lg"
                variant="gradient"
                radius="md"
                pl="lg"
                pr="lg"
                ml="xl"
                gradient={{ from: 'lime', to: 'green', deg: 90 }}
              >
                {offeringVendorItem.active_price}
              </Badge>
            </Group>
          )}
        </Card>
        <Button
          variant="light"
          w="100%"
          color="blue"
          onClick={() => setShow(!show)}
          radius={0}
        >
          {show ? (
            <FaArrowUp size={16} style={{ marginRight: '8px' }} />
          ) : (
            <FaArrowDown size={16} style={{ marginRight: '8px' }} />
          )}{' '}
          Approval Summary
        </Button>
        {show && <ApprovalItemSummary items={approvalItems} />}
      </div>
    </StyledItem>
  );
};

Item.defaultProps = {
  approvalItems: [],
};

const StyledItem = styled.div`
  display: flex;
  /* align-items: flex-start; */
  flex-direction: column;
  width: 100%;
  min-width: 350px;
  /* padding: 40px 0; */
  padding: 0;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 2px;
  overflow: hidden;

  .content,
  .meta {
    max-width: 100%;
  }

  .slick-slider {
    height: 80px;
    overflow: hidden;
    max-width: 100%;
  }

  .slick-dots {
    bottom: 20px;
  }

  &:first-of-type {
    padding-top: 0;
  }

  img {
    padding: 20px;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #eee;
  }

  .meta {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding: 1em;
  }

  h3 {
    margin-bottom: 0;
  }

  .actions {
    display: flex;
    margin-left: 12px;

    a {
      margin-right: 8px;
      padding: 4px;
    }
  }

  li {
    border: none;
  }

  .slick-slide,
  .slick-track {
    outline: none !important;
  }

  /* ul {
    padding: 0;
    margin: 0;
    width: 100%;
  } */
`;

const RemoveItem = ({ itemId, offeringId, onSuccess }) => {
  function onClick() {
    const req = {
      item_id: itemId,
      offering_id: offeringId,
    };

    axios
      .post(`/delete-offering-item/`, req)
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <RemoveConfirmButton
      icon={<Icon.Remove color="var(--negative)" size={20} />}
      title="Remove Item"
      onClick={onClick}
    />
  );
};

const ApprovalItemSummary = ({ items }) => {
  if (!items || !items.length) return null;

  const sortedItems = items.map((m, i) => ({
    isColor:
      m.color && m.color.length > 1 && m.color_value !== undefined ? 1 : 0,
    ...m,
  }));

  return (
    <StyledSummary>
      {sortedItems
        .sort((a, b) => b.variety - a.variety)
        .sort((a, b) => b.isColor - a.isColor)
        .map((item) => (
          <ApprovalItem
            key={item.id}
            assets={item.assets}
            id={item.id}
            description={item.description}
            variety={item.variety}
            color={item.color}
            colorValue={item.color_value}
            size={item.size}
            quantity={item.quantity}
            showActions={false}
            approvals={item.approvals.map((a, i) => ({
              key: i,
              required: a.required,
              name: a.offering_approval_authority.username,
              approved: a.approved,
            }))}
          />
        ))}
    </StyledSummary>
  );
};

const StyledSummary = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  padding-bottom: 2em;
  margin: 0px;
  background: #eee;
`;

const StyledApprovalList = styled.ul`
  margin: 0;
  padding: 0 0 20px 0 !important;
  list-style-type: none;

  li {
    background: none;
    border: none;
  }
`;

// return (
//   <StyledApprovalList>
//     {items
//       .sort((a, b) => (a.variety > b.variety ? 1 : -1))
//       // .filter((f) => f.approvals.length)
//       .map((item) => (
//         <ApprovalItem
//           key={item.id}
//           assets={item.assets}
//           id={item.id}
//           description={item.description}
//           variety={item.variety}
//           color={item.color}
//           price={
//             item.price_in_pennies
//               ? penniesToDollars(item.price_in_pennies)
//               : ''
//           }
//           quantity={item.quantity}
//           showActions={false}
//         />
//       ))}
//   </StyledApprovalList>
// );
