import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Card, Group, Text, Title, Flex } from '@mantine/core';

const ContextWrapper = styled.div`
  padding: 0;
`;

const Description = styled.div`
  padding: 0px 10px;
  margin: 0 auto;
  font-size: 1.3em;
  max-width: 60%;
`;

export default function ObjectBlock({
  asLink,
  status,
  description,
  offering,
  context,
  title,
  item,
  offeringItem,
  data,
  filename,
  url,
  subtitle,
}) {
  const cardStyle = {
    border: '1px solid #ccc',
    borderRadius: '7px',
    background: '#fff',
  };

  const Content = ({ children }) => (
    <Card style={cardStyle} mb="xs">
      {item && (
        <Card.Section p="xs" mih={450}>
          <React.Fragment>{<img src={filename} />}</React.Fragment>
        </Card.Section>
      )}
      <Title ff="Open Sans, sans-serif" size={22} fw="lighter">
        {title}
        {offeringItem && <React.Fragment>(offering item)</React.Fragment>}
      </Title>
      {subtitle && <Text>{subtitle}</Text>}
      <Flex direction="column" justify="flex-end">
        {data.length > 0 &&
          data.map((d, i) => (
            <Group key={i + 1} mt="xs">
              {d.icon && (
                <Group size="lg" c="dimmed">
                  {d.icon}
                </Group>
              )}
              {d.value && (
                <Text ff="Open Sans, sans-serif" size="md" fw="bold" c="dimmed">
                  {d.value}
                </Text>
              )}
            </Group>
          ))}
      </Flex>
      {description && <Description>{description}</Description>}
      {children && <React.Fragment>{children}</React.Fragment>}
      {context && <ContextWrapper>{context}</ContextWrapper>}
    </Card>
  );

  if (asLink) {
    return (
      <Link to={url}>
        <Content />
      </Link>
    );
  }

  return <Content />;
}

ObjectBlock.defaultProps = {
  asLink: true,
  canSelect: false,
  item: null,
  offeringItem: false,
  data: [],
  project: null,
  offering: null,
  subtitleColor: null,
};
