import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import { Modal, Form } from 'semantic-ui-react';
import styled from 'styled-components';
// EyeDropper
import {
  BasicButton,
  IconButton,
  RemoveConfirmButton,
} from '@components/Button';
import { ColorBlock, Grid, Title, Icon } from '@components/shared';

export default function ItemColors({
  itemId,
  postUrl,
  showCreate,
  showDelete,
  onColorClick,
  viewOnly,
  variety,
  fetchData,
}) {
  const [isOpen, setOpen] = useState(false);
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchColors();
  }, [postUrl]);

  function fetchColors() {
    if (!postUrl) return;

    setLoading(true);

    axios
      .post(postUrl, { variety: variety })
      .then(({ data }) => {
        setColors(data.response);
        setLoading(false);
      })
      .catch((err) => {
        setColors([]);
        setLoading(false);
      });
  }

  function onClose() {
    setOpen(false);
  }

  if (loading) return null;

  return (
    <div>
      {showCreate && (
        <React.Fragment>
          <Title text="Colors">
            <IconButton
              onClick={() => setOpen(!isOpen)}
              icon={<Icon.Create isOpen={isOpen} color="orange" />}
            />
          </Title>
          <Modal open={isOpen} onClose={onClose} size="medium">
            <Modal.Content size="medium">
              <ColorForm
                fetchData={fetchData}
                onSuccess={() => {
                  onClose();
                  fetchColors();
                }}
                variety={variety}
                itemId={itemId}
              />
            </Modal.Content>
          </Modal>
        </React.Fragment>
      )}
      <ColorList
        items={colors}
        onUpdate={fetchColors}
        showDelete={showDelete}
        onColorClick={onColorClick}
      />
    </div>
  );
}

ItemColors.defaultProps = {
  showCreate: true,
  showDelete: true,
  onColorClick: () => true,
  viewOnly: false,
};

export const ColorForm = ({
  name,
  description,
  color,
  variety,
  onSubmitClick,
  onSuccess,
  itemId,
  fetchData,
}) => {
  const [nameValue, setName] = useState('');
  const [descriptionValue, setDescription] = useState('');
  const [colorValue, setColor] = useState('');
  const [loading, setLoading] = useState(false);

  function onSubmit() {
    if (onSubmitClick) {
      return onSubmitClick({
        name: nameValue,
        color_name: colorValue,
      });
    }

    setLoading(true);

    const req = {
      // description: descriptionValue,
      name: nameValue,
      color_value: colorValue,
      item_id: itemId,
      variety: variety,
    };

    axios
      .post(`/tags/`, req)
      .then(() => {
        toast.success('Color Added!');
        onSuccess();
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Form>
      <Form.Input
        label="Name"
        value={nameValue}
        onChange={(e) => setName(e.target.value)}
      />
      {/* <Form.TextArea
        label="Description (optional)"
        value={descriptionValue}
        onChange={(e) => setDescription(e.target.value)}
      /> */}
      <Form.Field style={{ marginBottom: '1.5em' }}>
        {/* <label>Color</label> */}
        <StyledColorPicker>
          <HexColorPicker color={colorValue} onChange={(e) => setColor(e)} />
          <HexColorInput
            color={colorValue}
            onChange={(e) => setColor(e)}
            placeholder="Hex Color (without the #)"
          />
        </StyledColorPicker>
      </Form.Field>
      <BasicButton
        primary
        text="Save"
        loading={loading}
        disabled={loading || !nameValue || !colorValue}
        onClick={onSubmit}
      />
    </Form>
  );
};

ColorForm.defaultProps = {
  color: '',
  description: '',
  name: '',
};

const StyledColorPicker = styled.div`
  .react-colorful {
    width: 100%;
  }
`;

export const ColorList = ({
  onColorClick,
  items,
  showDelete,
  onDelete,
  onUpdate,
}) => {
  if (!items.length) return null;

  return (
    <Grid columns={4}>
      {items.map((item) => (
        <Swatch
          key={item.id}
          showDelete={showDelete}
          name={item.color_name}
          value={item.color_value}
          id={item.id}
          onColorClick={() => onColorClick(item)}
          onDelete={onDelete ? () => onDelete(item) : null}
          onUpdate={onUpdate}
        />
      ))}
    </Grid>
  );
};

ColorList.defaultProps = {
  items: [],
  showDelete: true,
  onColorClick: () => true,
};

const Swatch = ({
  onUpdate,
  onDelete,
  name,
  value,
  id,
  showDelete,
  onColorClick,
}) => {
  function onRemoveClick() {
    if (onDelete) {
      return onDelete();
    }

    const req = {
      tagging_id: id,
    };

    axios
      .post(`/delete-tagging/`, req)
      .then(() => {
        toast.success('Deleted!');
        onUpdate();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <StyledSwatch onClick={onColorClick}>
      <div className="block">
        <ColorBlock value={value} />
      </div>
      {/* <div className="block" style={{ background: value }}></div> */}
      <span>
        {name}
        <br />
        {value}
      </span>
      {showDelete && (
        <div className="actions">
          <RemoveConfirmButton onClick={onRemoveClick} />
        </div>
      )}
    </StyledSwatch>
  );
};

const StyledSwatch = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  /* width: 125px; */
  overflow: hidden;
  /* max-width: 125px; */
  background: #fff;
  padding: 10px;
  border-radius: 4px;

  & + & {
  }

  .actions {
    display: flex;
    margin-top: 1em;
    justify-content: center;
  }

  .block {
    margin: 0 auto 5px auto;
    display: block;
  }

  span {
    max-width: 80%;
    margin: 0 auto;
  }
`;
