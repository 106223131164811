import React from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './index.scss';

import Header from '@components/Header';
import Code from '@components/Code';

import {
  CustomerDetail,
  CustomerIndex,
  ItemDetail,
  ItemIndex,
  OfferingDetail,
  OfferingIndex,
  OfferingItemDetail,
  ProjectDetail,
  ProjectIndex,
  AdminDetail,
  AdminIndex,
  Settings,
  PartnerIndex,
  VendorDetail,
  DistributorDetail,
  ManufacturerDetail,
} from '@pages';

export default function AdminApp(admin) {
  return (
    <Router>
      <React.Fragment>
        <Toaster />
        <div className="app-and-header">
          <Header props={admin} />
          <div className="site-wrapper">
            <Switch>
              <Route exact path="/offerings" component={OfferingIndex} />
              <Route path="/offerings/:id" component={OfferingDetail} />
              <Route exact path="/customers" component={CustomerIndex} />
              <Route path="/customers/:id" component={CustomerDetail} />
              <Route path="/code/:code" component={Code} />
              <Route exact path="/settings" component={Settings} />
              <Route exact path="/projects" component={ProjectIndex} />
              <Route path="/projects/:id" component={ProjectDetail} />
              <Route exact path="/items/" component={ItemIndex} />
              <Route path="/items/:id" component={ItemDetail} />
              <Route
                path="/offering-items/:id"
                component={OfferingItemDetail}
              />
              <Route exact path="/admins/" component={AdminIndex} />
              <Route path="/admins/:id" component={AdminDetail} />
              <Route exact path="/partners/" component={PartnerIndex} />
              <Route path="/vendors/:id" component={VendorDetail} />
              <Route path="/distributors/:id" component={DistributorDetail} />
              <Route path="/manufacturers/:id" component={ManufacturerDetail} />
            </Switch>
          </div>
        </div>
      </React.Fragment>
    </Router>
  );
}
