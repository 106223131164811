import React from 'react';
import { ThemeIcon, Text, Group, Card, Tabs, Flex } from '@mantine/core';
import { Icon } from '@components/shared';

export default function TopBar({ variety }) {
  if (variety === 'Offering Item') {
    return (
      <Card bg="teal" w="100%" p="xs" m={0} mb="xs">
        <Group c="white" align="center">
          <Icon.Item size={22} />
          <Text ff="Open Sans, sans-serif" c="white" size="xl" fw="bold">
            Offering Item
          </Text>
        </Group>
      </Card>
    );
  } else if (variety === 'Item') {
    return (
      <Card bg="orange" w="100%" p="xs" m={0} mb="xs">
        <Group c="white" align="center">
          <Icon.Item size={22} />
          <Text ff="Open Sans, sans-serif" c="white" size="xl" fw="bold">
            Catalog Item
          </Text>
        </Group>
      </Card>
    );
  } else {
    return <div></div>;
  }
}
