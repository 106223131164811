import React from 'react';
import { FaCog, FaMinus } from 'react-icons/fa';
import styled from 'styled-components';

const Icon = styled.div`
  svg {
    font-size: 0.8em;
    margin-left: 15px;
    fill: #fff;
    background: ${(props) =>
      props.open ? 'var(--negative)' : 'var(--primaryColor500)'};
    padding: 4px;
    border-radius: 100%;

    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }
  }
`;

export default function UpdateIcon({ isOpen, onClick }) {
  return (
    <Icon open={isOpen}>
      {isOpen ? <FaMinus onClick={onClick} /> : <FaCog onClick={onClick} />}
    </Icon>
  );
}
