import React from 'react';
import { RichTextEditor } from '@mantine/rte';

export default function Rte({ controls, value, onChange }) {
  return (
    <RichTextEditor value={value} onChange={onChange} controls={controls} />
  );
}

Rte.defaultProps = {
  controls: [
    ['bold', 'italic', 'underline', 'link'],
    ['unorderedList', 'orderedList', 'h1', 'h2', 'h3', 'h4'],
  ],
  value: '',
};
