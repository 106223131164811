import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { FaPencilAlt } from 'react-icons/fa';
import { ThemeIcon, Text, Group, Card, Tabs, Flex } from '@mantine/core';

import { Icon, Title } from '@components/shared';
import { IconButton, RemoveButton } from '@components/Button';
import { ApprovalIcon, ApprovalItemForm } from './';
import { ItemImages, ItemColors } from '@components/Item';

import { penniesToDollars } from '@util/formatters';
import { varieties } from './approvalItemOptions';
import { ColorList, ColorForm } from '@components/Item/ItemColors';

export default function ApprovalItemManagement({
  id,
  itemId,
  items,
  placementData,
  fetchData,
  onFocusClick,
}) {
  const tabs = [
    { text: 'Art' },
    { text: 'Imprint Color(s)' },
    { text: 'Item Color(s)' },
    { text: 'Mockup' },
    { text: 'Quantity' },
    { text: 'Other' },
  ];

  const formattedItems = [
    { title: 'Art', variety: 4 },
    { title: 'Imprint Color(s)', variety: 7 },
    { title: 'Item Color(s)', variety: 1 },
    { title: 'Mockup', variety: 5 },
    { title: 'Quantity', variety: 2 },
    { title: 'Other', variety: 6 },
  ].map((m, i) => ({
    ...m,
    key: i,
    items: items.filter((f) => f.variety === m.variety),
  }));

  return (
    <Flex
      pb={200}
      direction="column"
      bg="#f0f0f0"
      p="lg"
      style={{ borderRadius: '7px' }}
      mih={700}
    >
      <Tabs defaultValue={tabs[0].text} variant="default">
        <Tabs.List mb="md">
          {tabs.map((m) => (
            <Tabs.Tab value={m.text} pt="xs" pb="xs">
              <Text ff="Open Sans, sans-serif" size="md" fw="bold" p={0}>
                {m.text}
              </Text>
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {formattedItems.map((m) => (
          <Tabs.Panel value={m.title}>
            <ItemContainer
              key={m.key}
              fetchData={fetchData}
              title={m.title}
              items={m.items}
              itemId={itemId}
              placementData={placementData}
              variety={m.variety}
              offeringItemId={id}
              onFocusClick={onFocusClick}
            />
          </Tabs.Panel>
        ))}
      </Tabs>
    </Flex>
  );
}

ApprovalItemManagement.defaultProps = {
  placementData: {
    items: [],
  },
};

const StyledItemContainer = styled.div`
  ul {
    margin: 0;
    padding: 0;
  }
`;

const StyledItem = styled.div`
  padding: 2em 0;

  & + & {
    border-top: 1px solid #eee;
  }
`;

const ItemContainer = ({
  title,
  variety,
  offeringItemId,
  items,
  itemId,
  placementData,
  fetchData,
  onFocusClick,
}) => {
  const [isOpen, setOpen] = useState(false);

  function onItemDelete(approvalItemId) {
    axios
      .delete(`/approval-items/${approvalItemId}/`)
      .then(() => {
        toast.success('Deleted!');
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <StyledItem>
      <Group mb="lg">
        <Text ff="Open Sans, sans-serif" size="xl">
          {title}
        </Text>
        {![varieties.quantity].includes(variety) && (
          <IconButton
            icon={<Icon.Create isOpen={isOpen} />}
            onClick={() => setOpen(!isOpen)}
          />
        )}
      </Group>
      {isOpen && (
        <React.Fragment>
          {![varieties.item_color, varieties.imprint_color].includes(
            variety,
          ) && (
            <ApprovalItemForm
              offeringItemId={offeringItemId}
              placementData={placementData}
              variety={variety}
              onSuccess={() => {
                fetchData();
                setOpen(false);
              }}
            />
          )}
          {variety === varieties.item_color && (
            <React.Fragment>
              <ColorSelect
                itemId={itemId}
                offeringItemId={offeringItemId}
                onSuccess={() => {
                  fetchData();
                  setOpen(false);
                }}
                variety={1}
              />
            </React.Fragment>
          )}
          {variety === varieties.imprint_color && (
            <React.Fragment>
              <ColorSelect
                itemId={itemId}
                offeringItemId={offeringItemId}
                onSuccess={() => {
                  fetchData();
                  setOpen(false);
                }}
                variety={7}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {!isOpen && (
        <StyledItemContainer>
          {[varieties.quantity, varieties.other].includes(variety) && (
            <React.Fragment>
              {items.map((m) => (
                <ApprovalItem
                  key={m.id}
                  description={m.description}
                  color={m.color}
                  variety={variety}
                  quantity={m.quantity}
                  size={m.size}
                  price={
                    m.price_in_pennies
                      ? penniesToDollars(m.price_in_pennies)
                      : ''
                  }
                  id={m.id}
                  assets={m.assets}
                  fetchData={fetchData}
                />
              ))}
            </React.Fragment>
          )}
          {[varieties.mockup, varieties.art].includes(variety) && (
            <ItemImages.List
              hideUpload
              assets={[].concat.apply(
                [],
                items.map((m) => m.assets.map((mm) => ({ ...mm, id: m.id }))),
              )}
              onFocusedClick={(e) => onFocusClick(e)}
              onDelete={onItemDelete}
              mockup={varieties.mockup === variety}
              art={varieties.art === variety}
            />
          )}
          {varieties.item_color === variety && (
            <React.Fragment>
              <ColorList
                items={items.map((m) => ({
                  ...m,
                  color_name: m.color,
                }))}
                onUpdate={fetchData}
                onDelete={(e) => {
                  onItemDelete(e.id);
                }}
              />
            </React.Fragment>
          )}
          {varieties.imprint_color === variety && (
            <React.Fragment>
              <ColorList
                items={items.map((m) => ({
                  ...m,
                  color_name: m.color,
                }))}
                onUpdate={fetchData}
                onDelete={(e) => {
                  onItemDelete(e.id);
                }}
              />
            </React.Fragment>
          )}
        </StyledItemContainer>
      )}
    </StyledItem>
  );
};

export const ApprovalItem = ({
  id,
  assets,
  description,
  color,
  colorValue,
  variety,
  price,
  quantity,
  size,
  showActions,
  fetchData,
  approvals,
}) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
    fetchData();
  }

  function capitalize(str) {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }

  return (
    <Card mt={5} p="7px 0 0 0" w="100%" bg="transparent">
      <Group spacing={4} w="100%" p={showActions ? 10 : 0}>
        {showActions && (
          <IconButton
            icon={
              <FaPencilAlt
                color="var(--primaryColor500)"
                size={16}
                style={{ marginRight: '5px' }}
              />
            }
            onClick={() => setOpen(true)}
          />
        )}
        {!showActions && (
          <div className="variety-icon">
            <ApprovalIcon variety={variety} color="var(--primaryColor500)" />
          </div>
        )}
        {color && (
          <Group spacing={5} ml={5}>
            {colorValue && (
              <div
                style={{
                  height: '20px',
                  width: '20px',
                  borderRadius: '30%',
                  backgroundColor: colorValue,
                }}
              ></div>
            )}
            <Text
              ff="Open Sans, sans-serif"
              c={colorValue ? colorValue : '#555'}
              size="sm"
            >
              {capitalize(color)}
            </Text>
            <Text
              ff="Open Sans, sans-serif"
              c={colorValue ? colorValue : '#555'}
              size="sm"
            >
              {variety === 7 ? '(Imprint)' : ''}
            </Text>
          </Group>
        )}
        {quantity && size && (
          <Group spacing={5} ml={5}>
            <ThemeIcon variant="light" color="grape" size="md">
              {size}
            </ThemeIcon>
            <Text ff="Open Sans, sans-serif" c="#555" size="sm">
              Quantity: {quantity}
            </Text>
          </Group>
        )}
        {quantity && !size && (
          <Group spacing={5} ml={5}>
            <Text ff="Open Sans, sans-serif" c="#555" size="sm">
              Quantity: {quantity}
            </Text>
          </Group>
        )}
        {assets.length > 0 &&
          assets.map((asset, i) => (
            <Group spacing={5} ml={5} key={i}>
              <a href={asset.large_url} target="_blank">
                <Text ff="Open Sans, sans-serif" c="blue">
                  click to view{' '}
                  <b>{asset.variety === 4 ? 'Artwork ' : 'Mockup '}</b>
                  image
                </Text>
              </a>
            </Group>
          ))}
        {description && (
          <Group spacing={5} ml={5}>
            <Text ff="Open Sans, sans-serif" c="#555" size="sm">
              {description}
            </Text>
          </Group>
        )}
        {approvals.length > 0 && (
          <Group
            mr="xs"
            spacing={3}
            style={{ display: 'flex', flexGrow: 1, justifyContent: 'end' }}
          >
            {approvals
              .sort((a, b) => b.approved - a.approved)
              .map((approval, i) => (
                <ThemeIcon
                  variant="light"
                  key={i}
                  opacity={approval.approved === undefined ? 0.85 : 1}
                  color={
                    approval.approved !== undefined
                      ? approval.approved
                        ? 'green'
                        : 'red'
                      : 'gray'
                  }
                >
                  {approval.name[0]}
                </ThemeIcon>
              ))}
          </Group>
        )}
        {showActions && (
          <div className="actions">
            <RemoveItem id={id} onSuccess={fetchData} />
          </div>
        )}
      </Group>
      <Modal open={isOpen} onClose={onClose} size="small">
        <Modal.Content>
          <ApprovalItemForm
            id={id}
            variety={variety}
            description={description}
            quantity={quantity}
            color={color}
            price={price}
            onSuccess={onClose}
          />
        </Modal.Content>
      </Modal>
    </Card>
  );
};

ApprovalItem.defaultProps = {
  assets: [],
  description: '',
  quantity: '',
  color: '',
  price: '',
  showActions: true,
  approvals: [],
};

const RemoveItem = ({ id, onSuccess }) => {
  function onClick() {
    axios
      .delete(`/approval-items/${id}/`)
      .then(() => {
        toast.success('Deleted!');
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <RemoveButton
      onClick={onClick}
      iconProps={{
        size: 16,
        color: 'var(--negative)',
      }}
    />
  );
};

const ColorSelect = ({ itemId, offeringItemId, onSuccess, variety }) => {
  const [creating, setCreating] = useState(false);

  function onColorClick(color) {
    const req = {
      color_value: color.color_value,
      color: color.color_name,
      offering_item_id: offeringItemId,
      variety: variety,
    };

    axios
      .post(`/approval-items/`, req)
      .then(() => {
        toast.success('Added');
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onColorCreate(e) {
    const req = {
      color_value: e.color_name,
      color: e.name,
      offering_item_id: offeringItemId,
      variety: variety,
    };

    axios
      .post(`/approval-items/`, req)
      .then(() => {
        toast.success('Added');
        onSuccess();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <React.Fragment>
      <button
        onClick={() => setCreating(!creating)}
        style={{
          border: 'none',
          outline: 'none',
          fontFamily: 'inherit',
          background: 'none',
          cursor: 'pointer',
          padding: '0',
          marginBottom: '.5em',
          fontWeight: 'bold',
          color: 'var(--primaryColor500)',
          marginBottom: '1em',
        }}
      >
        {creating ? 'Select colors' : 'Create new color'}
      </button>
      {!creating ? (
        <React.Fragment>
          <p>
            <b>Choose from this item's colors:</b>
          </p>
          <ItemColors
            postUrl={`/items/${itemId}/retrieve-colors/`}
            showCreate={false}
            showDelete={false}
            onColorClick={onColorClick}
            variety={variety}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>
            <b>Create a custom color for this item</b>
          </p>
          <ColorForm onSubmitClick={(e) => onColorCreate(e)} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
