import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Group, Text } from '@mantine/core';

import { ItemSearch } from '@components/Item';
import { IconButton } from '@components/Button';
import { OfferingDecisionItems, OfferingItemList } from './';
import { Title, Icon } from '@components/shared';

export default function OfferingItems({
  children,
  editable,
  items,
  offeringId,
  onUpdate,
  isDecision,
}) {
  const [isOpen, setOpen] = useState(false);

  function createOfferingItem(selectedItem) {
    const req = {
      item_id: selectedItem,
      offering_id: offeringId,
      // cost_in_pennies: dollarsToPennies(price),
    };

    axios
      .post(`/offerings/${offeringId}/items/`, req)
      .then(() => onUpdate())
      .then(() => setOpen(false))
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <div>
      <Group mb="sm">
        <Text size={30} ff="Open Sans, sans-serif" fw="lighter">
          {' '}
          {isDecision ? 'Item Checklist' : 'Items'}
        </Text>
        {editable && (
          <IconButton
            onClick={() => setOpen(!isOpen)}
            icon={<Icon.Create isOpen={isOpen} />}
          />
          // <Icon.Create onClick={() => setOpen(!isOpen)} isOpen={isOpen} />
        )}
      </Group>
      {children && <div>{children}</div>}
      {isOpen && (
        <ItemSearch
          onSelectClick={(e) => {
            createOfferingItem(e);
          }}
          select
          isOffering
        />
      )}
      {!isOpen && (
        <React.Fragment>
          {editable && (
            <OfferingItemList
              offeringId={offeringId}
              fetchData={onUpdate}
              items={items}
            />
          )}
          {!editable && (
            <OfferingDecisionItems
              itemJoins={items.filter((f) => f.item)}
              fetchData={onUpdate}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
}

OfferingItems.defaultProps = {
  items: [],
};
