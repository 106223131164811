import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaUser, FaMobileAlt, FaEnvelope, FaMapPin } from 'react-icons/fa';
import { ThemeIcon, Text, Group, Card, Tabs, Flex } from '@mantine/core';
import { toast } from 'react-hot-toast';
import { OfferingTable } from '@components/Offering';

import { BasicButton } from '@components/Button';

import { CustomerForm } from '@components/Customer';
import {
  Icon,
  ContactManagement,
  Title,
  ViewError,
  AddressManagement,
} from '@components/shared';

export default function CustomerDetail({ match }) {
  const [customer, setCustomer] = useState(populateCustomer());
  const [error, setError] = useState(null);

  const tabs = [
    { text: 'Details' },
    { text: 'Contacts' },
    { text: 'Street Address' },
    { text: 'Offerings' },
  ];

  const { id } = match.params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/users/${id}/`)
      .then(({ data }) => {
        setCustomer(populateCustomer(data.response[0]));
      })
      .catch((err) => {
        setError(err);
      });
  }

  function onDelete() {
    axios
      .get(`/users/${id}/delete/`)
      .then(({ data }) => {
        toast.success('Deleted!');
        window.location = '/customers';
      })
      .catch((err) => {
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  return (
    <div>
      <Title
        page
        text={customer.full_name}
        subtitle={
          <Group mb="xl">
            <ThemeIcon color="blue">
              <FaUser size={16} />
            </ThemeIcon>
            <Text ff="Open Sans, sans-serif" size="lg">
              Customer
            </Text>
          </Group>
        }
        icon={<Icon.User />}
      />
      <Card mb="lg" bg="blue" p="lg">
        <Flex direction="column">
          <Group>
            <FaMobileAlt color="white" size={16} />
            <Text ff="Open Sans, sans-serif" color="white" size="lg">
              {customer.primary_phone_number}
            </Text>
          </Group>
          <Group>
            <FaEnvelope color="white" size={16} />
            <Text ff="Open Sans, sans-serif" color="white" size="lg">
              {customer.primary_email}
            </Text>
          </Group>
          <Group>
            <FaMapPin color="white" size={16} />
            <Text ff="Open Sans, sans-serif" color="white" size="lg">
              {customer.primary_address}
            </Text>
          </Group>
        </Flex>
      </Card>
      <Tabs defaultValue={tabs[0].text} variant="default">
        <Tabs.List mb="md">
          {tabs.map((m) => (
            <Tabs.Tab value={m.text} pt="xs" pb="xs">
              <Text ff="Open Sans, sans-serif" size="md" fw="bold" p={0}>
                {m.text}
              </Text>
            </Tabs.Tab>
          ))}
        </Tabs.List>
        <Tabs.Panel value="Details">
          <CustomerForm
            id={id}
            firstName={customer.first_name}
            lastName={customer.last_name}
            onSuccess={fetchData}
          />
          <BasicButton
            onClick={() => onDelete()}
            color="red"
            text="Delete this Customer"
          />
        </Tabs.Panel>
        <Tabs.Panel value="Contacts">
          <ContactManagement
            contactName={customer.full_name}
            contacts={customer.contact_methods}
            userId={customer.id}
            onUpdate={fetchData}
          />
        </Tabs.Panel>
        <Tabs.Panel value="Street Address">
          <AddressManagement
            addresses={customer.addresses}
            userId={customer.id}
            onUpdate={fetchData}
          />
        </Tabs.Panel>
        <Tabs.Panel value="Offerings">
          <React.Fragment>
            <OfferingTable
              offerings={customer.offerings}
              fetchData={() => fetchData()}
            />
          </React.Fragment>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}

function populateCustomer(d = {}) {
  return {
    id: d.id || null,
    first_name: d.first_name || '',
    full_name: d.full_name || '',
    last_name: d.last_name || '',
    email: d.email || '',
    offerings: d.offerings ? d.offerings : [],
    phone: d.phone || '',
    contact_methods: d.contact_methods || [],
    addresses: d.addresses || [],
    primary_address: d.primary_address || '',
    primary_email: d.primary_email || '',
    primary_phone_number: d.primary_phone_number || '',
  };
}
