import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Switch, Route } from 'react-router-dom';
import { FaTruck } from 'react-icons/fa';
import { ThemeIcon, Text, Group, Card } from '@mantine/core';
import toast from 'react-hot-toast';

import { BasicButton } from '@components/Button';
import { ItemList } from '@components/Item';
import { DistributorForm } from '@components/Partner';
import { Icon, ContactManagement, Title, ViewError } from '@components/shared';

export default function DistributorDetail({ match }) {
  const [distributor, setDistributor] = useState(populateDistributor());
  const [error, setError] = useState(null);

  const { id } = match.params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/distributors/${id}/`)
      .then(({ data }) => {
        setDistributor(populateDistributor(data.response[0]));
      })
      .catch((err) => {
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  function onDelete() {
    axios
      .get(`/distributors/${id}/toggle-status/`)
      .then(() => {
        toast.success('Deleted!');
        window.location = '/partners';
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <div p="lg">
      <Title
        page
        text={distributor.name}
        subtitle={
          <Group mb="xl">
            <ThemeIcon color="teal">
              <FaTruck size={16} />
            </ThemeIcon>
            <Text ff="Open Sans, sans-serif" size="lg" color="teal">
              Distributor
            </Text>
          </Group>
        }
        icon={<Icon.Distributor />}
      />
      <Switch>
        <Route path={match.path} exact>
          <React.Fragment>
            <DistributorForm
              id={id}
              name={distributor.name}
              variety={distributor.variety}
              varietyFormatted={distributor.variety_formatted}
              websiteUrl={distributor.website_url}
              onSuccess={fetchData}
            />
            <ContactManagement
              contacts={distributor.contact_methods}
              distributorId={id}
              onUpdate={() => fetchData()}
            />
            {distributor.items.length > 0 && (
              <div style={{ margin: '25px 0' }}>
                <Title text="Distributor Items" icon={<Icon.Item />} />
                <ItemList items={distributor.items} />
              </div>
            )}
            <BasicButton
              color="red"
              text="Delete this distributor"
              onClick={() => onDelete()}
            />
          </React.Fragment>
        </Route>
      </Switch>
    </div>
  );
}

function populateDistributor(d = {}) {
  return {
    id: d.id || null,
    name: d.name || '',
    items: d.items ? d.items : [],
    status: d.status || null,
    variety: d.variety || null,
    variety_formatted: d.variety_formatted || null,
    contact_methods: d.contact_methods || [],
    website_url: d.website_url || '',
  };
}
