import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaMoneyBill, FaCalendarAlt } from 'react-icons/fa';
import { Flex, Group } from '@mantine/core';

import { SearchInput, ObjectBlock, EmptyText } from '@components/shared';

export default function ProjectSearch() {
  const [loading, setLoading] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [results, setResults] = useState([]);
  const [tags, setTags] = useState([]);

  const tagIds = tags.map((t) => t.id);

  useEffect(() => {
    if (!searchValue) {
      setAllItems([]);
    }

    fetchData();
  }, [searchValue, JSON.stringify(tags)]);

  function fetchData() {
    setLoading(true);

    const req = {
      tag_only: false,
      tag_ids: tagIds,
      variety: [0, 1, 2, 3],
      search: searchValue,
    };

    axios
      .post(`/projects-by-name/`, req)
      .then(({ data }) => {
        setLoading(false);
        setAllItems(data.response);
      })
      .catch((err) => {
        setLoading(false);
        setAllItems([]);
        setResults([]);
      });
  }

  return (
    <Flex direction="column">
      <Group w={100} mb="xs">
        <SearchInput onChange={(e) => setSearchValue(e)} value={searchValue} />
      </Group>
      {allItems.length > 0 ? (
        <Flex direction="column" gap={1}>
          {allItems.map((item) => (
            <ObjectBlock
              key={item.id}
              data={[
                {
                  value: item.orders.length,
                  icon: <FaMoneyBill />,
                },
                {
                  value:
                    item.start_date_formatted && item.end_date_formatted
                      ? `${item.start_date_formatted} - ${item.end_date_formatted}`
                      : null,
                  icon: <FaCalendarAlt />,
                },
              ].filter((f) => f.value)}
              title={item.name}
              url={`/projects/${item.id}`}
            />
          ))}
        </Flex>
      ) : (
        <EmptyText text="No projects..." />
      )}
    </Flex>
  );
}
